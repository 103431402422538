/* eslint-disable */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Divider from '../../../components/decorations/Divider';
import Button from '../../../components/form/Button';
import { ProfileContext } from '../../../context/Profile';
import Loader from '../../../components/loaders/Loader';
import axiosInstance from '../../../utils/axios';
import { useTranslation } from 'react-i18next';

const Users = ({ title }) => {
  const {
    profile: { userList },
    dispatch,
  } = useContext(ProfileContext);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (userList === undefined) {
      axiosInstance
        .get('/users/employees')
        .then(resp =>
          dispatch({
            type: 'PROFILE_USERLIST_INIT',
            data: resp.data,
          })
        )
        .catch(err => console.warn('Remove user', err));
    }
  }, []);

  return (
    <div className="profile-body">
      <h4>
        {title}
        <Link to="#addUser" className="add-user-link" data-testid="add-user">
          + {t('button.addUser')}
        </Link>
      </h4>
      <Divider />
      <div className="profile-user">
        {userList ? (
          userList.length === 0 ? (
            <div>{t('msg.userListEmpty')}</div>
          ) : (
            <ol className="user-list">
              <TransitionGroup>
                {userList.map(({ firstName, lastName, email }) => (
                  <CSSTransition key={email} className="user-list_item user-list_item-transition" timeout={500}>
                    <li>
                      <p className="user-list_item-text">
                        {`${firstName + ' ' + lastName || 'Anonim'},`}
                        <span>{email}</span>
                      </p>
                      <Button
                        option="link"
                        className="user-list_item-nav"
                        text="delete"
                        testid={`delete-${email}`}
                        onClick={() => dispatch({ type: 'PROFILE_USERLIST_REMOVE', email })}
                      />
                    </li>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </ol>
          )
        ) : (
          <div className="my-2">
            <Loader color="secondary" />
          </div>
        )}
      </div>
    </div>
  );
};

Users.propTypes = {
  title: PropTypes.string,
  //   children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default Users;
