/* eslint-disable */
import React from 'react';
import MapPopupChart from '../../containers/Charts/map/MapPopupChart';
import Chart from '../../containers/Charts/index';

const MapNav = ({ params }) => (
  <Chart
    maps={{
      from: (params || {}).start,
      to: (params || {}).end,
      type: (params || {}).type,
    }}
    navigationsFields={['date', 'tunnel', 'ftl', 'weekends', 'chartTypes']}
  />
);

export default MapNav;
