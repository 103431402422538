/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { QueryRenderer } from 'react-relay';
import environment from 'relay-env';
import { Row, Card, CardBody, Col } from 'reactstrap';
import get from 'lodash.get';
import IndicatorBox, { IndicatorBoxHeader } from '../Charts/components/IndicatorBox';
import ChartIndicators from '../Charts/components/ChartIndicators';
import prepareChartValues from '../Charts/utils/prepareChartValues';
import DrawChart from './DrawChart';
import Loader from '../../components/loaders/Loader';
import { getGlobal, getCountry } from '../../relay/queries/Fuel';
import NoData from '../Charts/utils/NoData';

const Chart = ({ isGlobal, startDate, endDate, country, fuelType, oilSource }) => {
  const { t } = useTranslation('common');
  const [min, setMin] = useState(false);
  const [max, setMax] = useState(false);
  const [avg, setAvg] = useState(false);
  const [med, setMed] = useState(false);

  const onSwitchChange = ename => {
    if (ename === 'min') {
      setMin(!min);
    }
    if (ename === 'max') {
      setMax(!max);
    }
    if (ename === 'avg') {
      setAvg(!avg);
    }
    if (ename === 'med') {
      setMed(!med);
    }
  };

  return (
    <QueryRenderer
      environment={environment}
      query={isGlobal ? getGlobal : getCountry}
      variables={{
        gteDate: startDate,
        lteDate: endDate,
        country: country,
      }}
      render={({ error, props }) => {
        if (error) {
          return <div>{error.message}</div>;
        } else if (props) {
          <Loader />;
        }

        if (!props) {
          return (
            <div className="py-5">
              <Loader />
            </div>
          );
        }

        const fetchedData = isGlobal ? (props || {}).getGlobalPrices : (props || {}).getCountryPrices;
        const { minY, maxY, median, average } = prepareChartValues(
          fetchedData,
          isGlobal ? oilSource : fuelType,
          //chartOptions[0].chartName,
          '', //dateConverter(date[1]),
          '4'
        );
        const currency = get(fetchedData, '[0].currency', 'PLN');
        const lpgDataExist = !!get(fetchedData, '[0].lpg', null);
        return (
          <div className="chart-header p-0">
            <Row className="m-0 align-items-end mb-2 main_filters_group">
              <ChartIndicators activeChart="">
                <IndicatorBox
                  title={t('label.minimum')}
                  data={[
                    {
                      switchName: 'min',
                      value: minY.toFixed(2),
                      status: min,
                    },
                  ]}
                  switchHandle={e => onSwitchChange('min')}
                  currency={currency}
                >
                  <IndicatorBoxHeader title={t('label.minimum')} />
                </IndicatorBox>
                <IndicatorBox
                  title={t('label.maximum')}
                  data={[
                    {
                      switchName: 'max',
                      value: maxY.toFixed(2),
                      status: max,
                    },
                  ]}
                  switchHandle={e => onSwitchChange('max')}
                  currency={currency}
                >
                  <IndicatorBoxHeader title={t('label.maximum')} />
                </IndicatorBox>
                <IndicatorBox
                  title={t('label.median')}
                  data={[
                    {
                      switchName: 'med',
                      value: median.toFixed(2),
                      status: med,
                    },
                  ]}
                  switchHandle={e => onSwitchChange('med')}
                  currency={currency}
                >
                  <IndicatorBoxHeader title={t('label.median')} />
                </IndicatorBox>
                <IndicatorBox
                  title={t('label.average')}
                  data={[
                    {
                      switchName: 'avg',
                      value: average.toFixed(2),
                      status: avg,
                    },
                  ]}
                  switchHandle={e => onSwitchChange('avg')}
                  currency={currency}
                >
                  <IndicatorBoxHeader title={t('label.average')} />
                </IndicatorBox>
              </ChartIndicators>
            </Row>

            <Row className="m-0 justify-content-between align-items-end mb-2 main_filters_group">
              <Col className="chart">
                <Card>
                  <CardBody>
                    {fuelType === 'lpg' && !lpgDataExist && !isGlobal && <NoData />}
                    <DrawChart
                      data={fetchedData}
                      oilSource={oilSource}
                      fuelType={fuelType}
                      min={min}
                      minY={minY}
                      max={max}
                      maxY={maxY}
                      med={med}
                      median={median}
                      avg={avg}
                      average={average}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <ReactTooltip />
          </div>
        );
      }}
    />
  );
};

Chart.propTypes = {
  setGlobal: PropTypes.func,
  isGlobal: PropTypes.bool,
  setCountry: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
  startDate: PropTypes.func,
  endDate: PropTypes.func,
  onDateChange: PropTypes.func,
  // onSwitchChange: PropTypes.func,
  search: PropTypes.string,
  setSearch: PropTypes.func,
};

export default Chart;
