/* eslint-disable */
import React from 'react';
import LogInForm from './components/LogInForm';
import MainWrapper from './components/MainWrapper';
import { withTranslation } from 'react-i18next';
// import RegisterForm from './components/RegisterForm';

const LogIn = ({ t }) => (
  <MainWrapper title={t('dialogs.signInto')}>
    <LogInForm />
  </MainWrapper>
);

export default withTranslation('common')(LogIn);

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
