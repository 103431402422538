/* eslint-disable */
import React, { useContext, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Joi from 'joi-browser';
import { useTranslation } from 'react-i18next';
// import Dropdown from '../../../components/form/Dropdown';
import Button from '../../../components/form/Button';
import withNotificationHOC from '../../../components/notification/NotificationsHOC';
import { BasicNotification } from '../../../components/notification/Notifications';
import getFormInputsData from '../../../utils/form';
import Input from '../../../components/form/Input';
// import findIndex from 'lodash.findindex';
import JoiTranslateMsg from '../../../utils/joiValidatorMsg';
import { ProfileContext } from '../../../context/Profile';
import CountrySelector from '../../Layout/components/countrySelector';

const CompanyData = ({ handleShowNotification }) => {
  const {
    profile: { NIP, city, postCode, address, country, firstName, lastName, companyName, phoneNumber, jobTitle },
    profileUpdate,
  } = useContext(ProfileContext);
  const [activeCountry, setCountry] = useState(country);
  const { t } = useTranslation('common');

  const onFormSubmit = e => {
    e.preventDefault();
    const formData = getFormInputsData(e, false);
    const valid = Joi.validate({ ...formData, country: activeCountry }, schema);

    valid
      .then(resp => profileUpdate(resp))
      .catch(err => {
        handleShowNotification({
          notification: <BasicNotification color="danger" title={t('msg.failed')} message={<JoiTranslateMsg err={err} />} />,
        });
      });
  };

  return (
    <form action="" className="form-group form-grid input-big" onSubmit={e => onFormSubmit(e)}>
      <Container>
        <Row noGutters>
          <Input label={t('user.firstName')} name="firstName" className="w-100" value={firstName} />
        </Row>
        <Row noGutters>
          <Input label={t('user.lastName')} name="lastName" className="w-100" value={lastName} />
        </Row>
        <Row noGutters>
          <Input label={t('user.phoneNumber')} name="phoneNumber" type="number" className="w-100" value={phoneNumber} />
        </Row>
        <Row noGutters>
          <Input label={t('user.companyName')} name="companyName" className="w-100" value={companyName} />
        </Row>
        <Row noGutters>
          <Input label={t('user.NIP')} name="NIP" type="number" className="w-100" value={NIP} />
        </Row>
        <Row noGutters>
          <Input label={t('user.jobTitle')} name="jobTitle" className="w-100" value={jobTitle} />
        </Row>
        <Row noGutters>
          <CountrySelector label={t('user.country')} defaultCountry={country} handleActiveCountry={(name, iso2) => setCountry(iso2)} />
        </Row>
        <Row>
          <Col xs={5}>
            <Input label={t('user.postCode')} name="postCode" value={postCode} />
          </Col>
          <Col xs={7}>
            <Input label={t('user.city')} name="city" value={city} />
          </Col>
        </Row>
        <Row noGutters>
          <Input label={t('label.street')} name="address" value={address} className="w-100" />
        </Row>
        <Row noGutters className="d-flex justify-content-start mt-1">
          <Button option="primary" testid="submit" text="save" isUpperCase />
        </Row>
      </Container>
    </form>
  );
};

const schema = Joi.object()
  .keys({
    firstName: Joi.string(),
    lastName: Joi.string(),
    jobTitle: Joi.string(),
    companyName: Joi.string(),
    phoneNumber: Joi.string().min(4),
    address: Joi.string(),
    postCode: Joi.string(),
    city: Joi.string(),
    NIP: Joi.string(),
    country: Joi.string(),
  })
  .options({ abortEarly: false });

CompanyData.propTypes = {
  btnText: PropTypes.string,
  handleShowNotification: PropTypes.func,
};

export default withNotificationHOC(CompanyData);
