import { Environment, Network, RecordSource, Store, QueryResponseCache } from 'relay-runtime';
import Cookies from 'js-cookie';
import get from 'lodash.get';
import { refreshAccessToken } from '../utils/axios';

const cache = new QueryResponseCache({ size: 250, ttl: 1 }); // 60 * 50 * 1000
const getToken = () => {
  // console.warn('GET TOKEN FROM COOKIE', Cookies.get('auth'));
  return Cookies.get('auth') ? JSON.parse(Cookies.get('auth')).accessToken : null;
};

const fetchQuery = async (operation, variables, cacheConfig) => {
  // console.warn('fetchQuery', operation, variables);
  const queryID = operation.name;
  const cachedData = cache.get(queryID, variables);
  const isMutation = operation.operationKind === 'mutation';
  const isQuery = operation.operationKind === 'query';
  const forceFetch = cacheConfig && cacheConfig.force;

  if (isQuery && cachedData !== null && !forceFetch) {
    return cachedData;
  }

  const graphQLurl = process.env.REACT_APP_ENV === 'development' ? 'http://localhost:4000/graphql' : '/graphql';
  // const { token } = Cookies.get('auth') ? JSON.parse(Cookies.get('auth')) : null;
  // console.warn("token XXXXX", token === getToken())
  const fetchObj = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
    rejectUnauthorized: false,
  };

  const fetchResponse = await fetch(graphQLurl, fetchObj)
    .then(response => {
      // console.warn('FETCH RESPONSE ::', response);
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .then(async data => {
      // console.warn('FETCH DATA: ', data, '||', data.errors, '||', get(data, 'errors[0].message', '1'));
      if (get(data, 'errors[0].message', '1') === '401') {
        // need refresh token
        //Token will be refresh and query will be retry with new token
        const refreshed = await refreshAccessToken();
        // console.warn('1 Refresh graphQL', refreshed);
        fetchObj.headers.Authorization = `Bearer ${(refreshed || {}).accessToken}`;
        // console.warn('1 fetchObj REFRESHED', fetchObj, 'o|0,0|o', refreshed.accessToken);
        const retry = await fetch(graphQLurl, fetchObj)
          .then(response => {
            if (response.ok) {
              return response.json();
            }
            return Promise.reject(response);
          })
          .then(retryData => {
            if (operation.operationKind !== 'mutation') {
              cache.set(queryID, variables, retryData);
            }
            return retryData;
          })
          .catch(error => {
            console.error('Error after refresh', error);
            return error;
          });
        // console.error('Error retry', retry);
        return retry;
      }

      if (operation.operationKind !== 'mutation') {
        cache.set(queryID, variables, data);
      }
      if (isMutation) {
        cache.clear();
      }

      return data;
    })
    .catch(async err => {
      if (err.status === 401) {
        //Token will be refresh and query will be retry with new token
        const refreshed = await refreshAccessToken();
        // console.warn('2 Refresh graphQL', refreshed);
        fetchObj.headers.Authorization = `Bearer ${(refreshed || {}).accessToken}`;
        // console.warn('2 fetchObj REFRESHED', fetchObj, 'o|0,0|o', refreshed.accessToken);
        const retry = await fetch(graphQLurl, fetchObj)
          .then(response => {
            if (response.ok) {
              return response.json();
            }
            return Promise.reject(response);
          })
          .then(data => {
            if (operation.operationKind !== 'mutation') {
              cache.set(queryID, variables, data);
            }
            return data;
          })
          .catch(error => {
            console.error('Error after refresh', error);
            return error;
          });
        console.error('Error retry', retry);
        return retry;
      }
      console.error('Catch err', err);
      return err;
    });

  return fetchResponse;
};

const environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource()),
});

export default environment;
