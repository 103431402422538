/*eslint-disable*/
import countries from './json/countries.json';
import get from 'lodash.get';
import nuts from '../containers/Maps/data/nuts1_3m.json';

const getRegionName = (code = '', lang = 'PL') => {
  if (code === null) return;
  const country = countries.find(country => country.iso2 === code);
  const nut = nuts.features.find(nut => nut.id === code);
  if (code.length === 2) {
    return get(country, `name${lang.toUpperCase()}`, code);
  }
  if (nut) {
    return get(nut, 'properties.NAME_LATN', code);
  }

  return code;
};

export default getRegionName;
