export const bodyTypes = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Bulk',
    value: 'Bulk',
  },
  {
    label: 'Cooler',
    value: 'Cooler',
  },
  {
    label: 'Curtain',
    value: 'Curtain',
  },
  {
    label: 'Van',
    value: 'Van',
  },
];

// (nowe) do 1,5t.  / up to 1,5t.
// (dawne LTL) od 1,5t. do 16t. / 1,5t - 16t.
// (dawne FTL) ponad 16t. / over 16 t.

export const fillTypes = [
  {
    label: 'label.<1,5t',
    value: 'Bus',
  },
  {
    label: 'label.1,5t - 16t',
    value: 'Ltl',
  },
  {
    label: 'label.16t<',
    value: 'Ftl',
  },
];

export const cohorts = [
  {
    label: 'Daily',
    value: 'day',
  },
  {
    label: 'Weekly',
    value: 'week',
  },
  {
    label: 'Monthly',
    value: 'month',
  },
  {
    label: 'Quartly',
    value: 'quarter',
  },
];

export const timePrecision = [
  {
    label: 'label.days',
    value: 'days',
  },
  {
    label: 'label.weeks',
    value: 'weeks',
  },
  {
    label: 'label.months',
    value: 'months',
  },
];

export const chartTypes = [
  {
    label: 'label.price',
    value: 'price',
  },
  {
    label: 'label.supply',
    value: 'supply',
  },
  {
    label: 'label.demand',
    value: 'demand',
  },
];
