/* eslint-disable */
import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import Cookies from 'js-cookie';
import Select from 'react-dropdown-select';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import SelectBtnGroup, { ButtonGroupItem } from '../../components/form/SelectBtnGroup';
import { fuelTypes, oilSources } from '../../utils/config';
import DateModal from '../../components/modals/containers/DateModal';
import countries from './fuelCountryList.json';
import { withTranslation } from 'react-i18next';
import { BlockedWrapper } from '../../components/form/Blocked';
import Chart from './chart';

const FuelPrice = props => {
  const { t } = props;
  const lang = i18next.language.slice(0, 2) || 'pl';
  const [startDateOil, setstartDateOil] = useState('2018-01-01');
  const [startDateFuel, setstartDateFuel] = useState('2018-01-01');
  const [endDateOil, setendDateOil] = useState(new Date());
  const [endDateFuel, setendDateFuel] = useState(new Date());
  const [fuelType, setFuelType] = useState('diesel');
  const [oilSource, setOilSource] = useState('brent');
  const [country, setCountry] = useState(Cookies.get('fuelCountry') ? JSON.parse(Cookies.get('fuelCountry')).iso2 : 'DE');

  const [isGlobal, setGlobal] = useState(false);

  const activeTab = isGlobal ? '2' : '1';

  const onChangeOptions = (name, value) => {
    if (name === 'fuelType') {
      setFuelType(value);
    }
    if (name === 'oilSource') {
      setOilSource(value);
    }
  };

  const onDateChange = (start, end, type = 'fuel') => {
    // fuel or oil
    const dateStart = `${start.getUTCFullYear()}-${`0${start.getUTCMonth() + 1}`.slice(-2)}-${`0${start.getUTCDate()}`.slice(-2)}`;
    const dateEnd = `${end.getUTCFullYear()}-${`0${end.getUTCMonth() + 1}`.slice(-2)}-${`0${end.getUTCDate()}`.slice(-2)}`;

    if (type === 'fuel') {
      setstartDateFuel(dateStart);
      setendDateFuel(dateEnd);
    }
    if (type === 'oil') {
      setstartDateOil(dateStart);
      setendDateOil(dateEnd);
    }
  };

  const fuelCountry = Cookies.get('fuelCountry')
    ? lang === 'pl'
      ? JSON.parse(Cookies.get('fuelCountry')).namePL
      : JSON.parse(Cookies.get('fuelCountry')).nameEN
    : t('label.germany');
  // const fuelLang = Cookies.get('fuelCountry') ? JSON.parse(Cookies.get('fuelCountry')).iso2 : 'PL';

  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={isGlobal ? '' : 'active'}
            onClick={() => {
              setGlobal(false);
            }}
          >
            {t('label.retailFuelPrices')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={isGlobal ? 'active' : ''}
            onClick={() => {
              setGlobal(true);
            }}
          >
            {t('label.crudeOilPrices')}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <div className="fuel-header d-flex justify-content-start align-items-end flex-wrap">
            <BlockedWrapper className="px-2" linkClass="mr-4">
              <div className="btn-group-select px-2">
                <p className="btn-group-select__label">{t('label.date')}:</p>
                <DateModal
                  initStartProp={new Date(startDateFuel)}
                  initEndProp={new Date(endDateFuel)}
                  onDateChange={(start, end) => onDateChange(start, end, 'fuel')}
                  lang={lang}
                  type={'fuel'}
                />
              </div>
            </BlockedWrapper>
            <div className="mx-md-4 mx-0 my-1 my-md-0">
              <BlockedWrapper className="px-2">
                <div className="btn-group-select px-2 ">
                  <p className="btn-group-select__label">{t('label.retailPrices')}:</p>
                  <Select
                    className="dropdown-select small mb-1"
                    options={countries}
                    values={[
                      {
                        namePL: fuelCountry,
                        nameEN: fuelCountry,
                      },
                    ]}
                    placeholder={t('label.search')}
                    labelField={`name${lang.toUpperCase()}`}
                    valueField={`iso2`}
                    searchBy={`name${lang.toUpperCase()}`}
                    dropdownHandleRenderer={({ state }) =>
                      state.dropdown ? <span className="lnr lnr-chevron-up"></span> : <span className="lnr lnr-chevron-down"></span>
                    }
                    onChange={country => {
                      if (country.length === 0) return;
                      setCountry(country[0].iso2);
                      Cookies.set('fuelCountry', country[0], { expires: 30 });
                    }}
                  />
                </div>
              </BlockedWrapper>
            </div>
            <div className="d-flex align-items-center mx-4 my-md-0 my-2">
              <SelectBtnGroup wrapperClass="px-2 d-flex align-items-end ">
                {fuelTypes.map(option => (
                  <ButtonGroupItem
                    option={option}
                    isActive={option.value === fuelType}
                    key={option.value}
                    onHandleClick={() => {
                      onChangeOptions('fuelType', option.value);
                    }}
                  />
                ))}
              </SelectBtnGroup>
            </div>
            <div className="d-flex align-items-center ml-md-auto ml-2">
              <p data-tip={t('tooltips.fuel')} data-class="tooltips_info" className="tooltip-fuel ">
                {t('tooltips.info')}
              </p>
            </div>
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div className="fuel-header d-flex justify-content-start align-items-end flex-wrap">
            <BlockedWrapper className="px-2" linkClass="mr-4">
              <div className="btn-group-select px-2">
                <p className="btn-group-select__label">{t('label.date')}:</p>
                <DateModal
                  initStartProp={new Date(startDateOil)}
                  initEndProp={new Date(endDateOil)}
                  onDateChange={(start, end) => onDateChange(start, end, 'oil')}
                  lang={lang}
                  type={'fuel'}
                />
              </div>
            </BlockedWrapper>
            <SelectBtnGroup wrapperClass="px-2 d-flex align-items-end my-md-0 my-2">
              {oilSources.map(option => (
                <ButtonGroupItem
                  option={option}
                  isActive={option.value === oilSource}
                  key={option.value}
                  onHandleClick={() => {
                    onChangeOptions('oilSource', option.value);
                  }}
                />
              ))}
            </SelectBtnGroup>
            <p data-tip={t('tooltips.fuel')} data-class="tooltips_info " className="tooltip-fuel ml-md-auto ml-2">
              {t('tooltips.info')}
            </p>
          </div>
        </TabPane>
      </TabContent>
      <Chart
        isGlobal={isGlobal}
        fuelType={fuelType}
        oilSource={oilSource}
        startDate={isGlobal ? startDateOil : startDateFuel}
        endDate={isGlobal ? endDateOil : endDateFuel}
        country={country}
        onDateChange={onDateChange}
      />
    </>
  );
};

export default withTranslation('common')(FuelPrice);
