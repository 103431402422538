import { graphql } from 'react-relay';

const getPriceCountry = graphql`
  query PriceCountryQuery($departure: String, $destination: String, $gteDate: DateTime, $lteDate: DateTime, $includeWeekends: Boolean, $periods: String) {
    getActivityCountry(
      departure: $departure
      destination: $destination
      gteDate: $gteDate
      lteDate: $lteDate
      includeWeekends: $includeWeekends
      periods: $periods
    ) {
      departure
      destination
      createDate
      priceFtl
      priceLtl
      priceBus
      priceLtlLag1
      priceFtlLag1
      priceBusLag1
      priceFtlLag7
      priceBusLag7
      priceLtlLag7
      priceLtlLag30
      priceFtlLag30
      priceBusLag30
    }
  }
`;

export const getPriceNuts = graphql`
  query PriceNutQuery($departure: String, $destination: String, $gteDate: DateTime, $lteDate: DateTime, $includeWeekends: Boolean, $periods: String) {
    getActivityNuts(
      departure: $departure
      destination: $destination
      gteDate: $gteDate
      lteDate: $lteDate
      includeWeekends: $includeWeekends
      periods: $periods
    ) {
      departure
      destination
      createDate
      priceFtl
      priceLtl
      priceBus
      priceLtlLag1
      priceFtlLag1
      priceBusLag1
      priceFtlLag7
      priceBusLag7
      priceLtlLag7
      priceLtlLag30
      priceFtlLag30
      priceBusLag30
    }
  }
`;
export default getPriceCountry;
