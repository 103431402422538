/* eslint-disable */
import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import convertDateFormat from '../../../utils/converters';
import NoData from '../../Charts/utils/NoData';

const MapChart = ({ title, expandLink = '', data = [], dataSetName }) => {
  const { t } = useTranslation('common');
  const chartData = get(data, 'data', data).reduce((acc, oneTickData) => {
    return acc.concat({ name: moment(oneTickData.createDate).format('DD.MM'), value: oneTickData[dataSetName] });
  }, []);

  return (
    <div className="map-chart">
      <h3 className="map-chart-title">
        {title}
        {expandLink !== '' && chartData.length !== 0 && (
          <Link to={expandLink} className="map-chart-title__expand">
            <span className="lnr lnr-frame-expand" />
            {t('label.expand')}
          </Link>
        )}
      </h3>
      {chartData.length === 0 ? (
        <NoData customClass="map-chart" />
      ) : (
        <LineChart
          width={290}
          height={170}
          data={chartData}
          margin={{
            top: 2,
            right: 20,
            left: 0,
            bottom: 2,
          }}
        >
          <CartesianGrid />
          <XAxis dataKey="name" tickSize={0} axisLine={false} padding={{ left: 0, right: 5 }} reversed />
          <YAxis orientation="right" width={30} tickSize={0} axisLine={false} padding={{ bottom: 10, top: 10 }} />
          <Line type="monotone" dataKey="value" stroke="url(#gradient-opacity)" strokeWidth={3} dot={false} />
          <defs>
            <linearGradient id="gradient-opacity" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="rgba(255,255,255,0)" />
              <stop offset="10%" stopColor="#fff" />
              <stop offset="90%" stopColor="#fff" />
              <stop offset="100%" stopColor="rgba(255,255,255,0)" />
            </linearGradient>
          </defs>
        </LineChart>
      )}
    </div>
  );
};

MapChart.propTypes = {
  title: PropTypes.string,
  expandLink: PropTypes.string,
};

export default MapChart;
