import React from 'react';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

const Loader = props => (
  <div className="loader loader_container">
    <Spinner {...props} />
  </div>
);

Loader.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark']),
  type: PropTypes.oneOf(['border', 'grow']),
};

Loader.defaultProps = {
  color: 'primary',
  type: 'border',
};

export default Loader;
