import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import LockIcon from 'mdi-react/LockIcon';
import Blocked from './Blocked';
import { ProfileContext } from '../../context/Profile';

const SwitchBtn = ({ onClick, checked, className, label = '', testString = 'switch', leftSide = 'on', rightSide = 'off', isBlocked = false }) => {
  const SwitchClass = classNames(className, {
    switch: true,
    isChecked: checked,
  });
  const { profile } = useContext(ProfileContext);

  return (
    <div className={SwitchClass} data-testid={testString}>
      {isBlocked && profile.isBlocked && <Blocked />}
      <p className="switch-label__main">{label}</p>
      <button type="button" className="switch-row" onClick={isBlocked ? () => {} : onClick}>
        <p className="switch-label__left">{leftSide}</p>
        <div className="switch-track">
          <div className="switch-ball" />
        </div>
        <p className="switch-label__right">{rightSide}</p>
      </button>
    </div>
  );
};

SwitchBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  testString: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  rightSide: PropTypes.string,
  leftSide: PropTypes.string,
  isBlocked: PropTypes.bool,
};

export default SwitchBtn;

// onChange={() => {}} checked={false} className="switch"
