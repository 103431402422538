import React, { useContext } from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import intersection from 'lodash.intersection';
import { ProfileContext } from '../../../context/Profile';
import { ReactComponent as NaviLeft1 } from '../../../shared/img/nav/NaviLeft1.svg';
import { ReactComponent as NaviLeft4 } from '../../../shared/img/nav/NaviLeft4.svg';
import { ReactComponent as NaviLeft6 } from '../../../shared/img/nav/NaviLeft6.svg';
import { ReactComponent as NaviLeft7 } from '../../../shared/img/nav/NaviLeft7.svg';
import { ReactComponent as NaviLeft3 } from '../../../shared/img/nav/NaviLeft3.svg';
import { ReactComponent as NaviLeftCurrency } from '../../../shared/img/nav/NaviLeftCurrency.svg';
import { ReactComponent as NaviLeftUnlock } from '../../../shared/img/nav/NaviLeftUnlock.svg';

const icons = [NaviLeft4, NaviLeft6, NaviLeft7, NaviLeft1, NaviLeft3, NaviLeftCurrency, NaviLeftUnlock, NaviLeft6, NaviLeft7];

const BottomBar = ({ sideList }) => {
  const { t } = useTranslation('common');
  const {
    profile: { role },
  } = useContext(ProfileContext);

  let Icon = icons[0];
  return (
    <React.Fragment>
      <div className="bottom-bar">
        <Scrollbar className="bottom-bar__scroll scroll">
          <div className="bottom-bar__scroll--trail">
            {sideList.map((menuItem, id) => {
              Icon = icons[id];
              return intersection([role], menuItem.visibleFor).length > 0 ? (
                <NavLink
                  to={menuItem.link}
                  activeClassName="active"
                  id={id}
                  className="bottom-bar__tab"
                  key={menuItem.link}
                  type="button"
                  data-testid={`bottom-bar__tab.${id}`}
                >
                  <Icon />
                  <span>{t(menuItem.shortlabel)}</span>
                </NavLink>
              ) : null;
            })}
          </div>
        </Scrollbar>
      </div>
    </React.Fragment>
  );
};

BottomBar.propTypes = {
  sideList: PropTypes.arrayOf(PropTypes.object),
};

export default BottomBar;
