/* eslint-disable  */
// /jsx-a11y/label-has-for
import React, { PureComponent } from 'react';
// import CheckIcon from 'mdi-react/CheckIcon';
import CheckBoxIcon from 'mdi-react/CheckBoxIcon';
// import CloseIcon from 'mdi-react/CloseIcon';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export class CheckBoxField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    className: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {
    label: '',
    disabled: false,
    className: '',
    color: '',
    required: false,
  };

  render() {
    const { disabled, className, name, value, onChange, label, required } = this.props;
    const CheckboxClass = classNames(className, {
      'checkbox-btn': true,
      active: value,
      disabled,
      required,
    });

    return (
      <label className={CheckboxClass} htmlFor={name}>
        <input
          className="checkbox-btn__input"
          type="checkbox"
          id={name}
          name={name}
          onChange={onChange}
          checked={value}
          data-val="true"
          value="true"
          disabled={disabled}
          required={required}
        />
        <span className="checkbox-btn__icon">
          <CheckBoxIcon className="checkbox-btn__icon-svg" size="24" />
        </span>
        <span className="checkbox-btn__label">{label}</span>
      </label>
    );
  }
}
