import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BlockedWrapper } from '../../../components/form/Blocked';
import SelectBtnGroup, { ButtonGroupItem } from '../../../components/form/SelectBtnGroup';
import { bodyTypes, fillTypes, timePrecision, chartTypes } from '../../Charts/utils/config';

const ClutersFilterGroup = ({
  handler,
  fillType = true,
  bodyType = true,
  children,
  bodyTypeActive,
  fillTypeActive,
  timeActive,
  chartTypeActive,
  showTimeGranulation = true,
  className = '',
}) => {
  const { t } = useTranslation('common');
  return (
    <div className={`d-flex flex-direction-row flex-wrap justify-content-start ${className}`}>
      {fillType && (
        <BlockedWrapper linkClass="ml-1 mr-3 mb-1" className="d-flex align-items-end">
          <SelectBtnGroup wrapperClass="bar mr-2 ml-2 ml-md-0 my-1 my-md-0" label={t('label.fillType')}>
            {fillTypes.map(option => (
              <ButtonGroupItem
                option={option}
                isActive={option.value === fillTypeActive}
                key={option.value}
                onHandleClick={() => {
                  handler({ fillType: option.value });
                }}
              />
            ))}
          </SelectBtnGroup>
        </BlockedWrapper>
      )}
      {bodyType && (
        <BlockedWrapper linkClass="ml-1 mr-3 mb-1" className="d-flex align-items-end">
          <SelectBtnGroup label={t('label.bodyType')} wrapperClass="bar mr-2 my-2 my-md-0">
            {bodyTypes.map(option => (
              <ButtonGroupItem
                option={option}
                isActive={option.value === bodyTypeActive}
                key={option.value}
                onHandleClick={() => {
                  handler({ bodyType: option.value });
                }}
              />
            ))}
          </SelectBtnGroup>
        </BlockedWrapper>
      )}
      {/* <BlockedWrapper linkClass="ml-1 mr-3 mb-1"> */}
      {showTimeGranulation && (
        <BlockedWrapper linkClass="ml-1 mr-3 mb-1" className="d-flex align-items-end">
          <SelectBtnGroup label={t('label.timePeriods')} wrapperClass="bar mr-2">
            {timePrecision.map(option => (
              <ButtonGroupItem
                option={option}
                isActive={option.value === timeActive}
                key={option.value}
                onHandleClick={() => {
                  handler({ timeType: option.value });
                }}
              />
            ))}
          </SelectBtnGroup>
        </BlockedWrapper>
      )}
      <BlockedWrapper linkClass="ml-1 mr-3 mb-1 " className="d-flex align-items-end">
        <SelectBtnGroup wrapperClass="px-2  d-flex align-items-end my-2 my-md-0">
          {chartTypes.slice(1).map(option => (
            <ButtonGroupItem
              option={option}
              isActive={option.value === chartTypeActive}
              key={option.value}
              onHandleClick={() => {
                handler({ chartType: option.value });
              }}
            />
          ))}
        </SelectBtnGroup>
      </BlockedWrapper>
      {children}
    </div>
  );
};

ClutersFilterGroup.propTypes = {
  handler: PropTypes.func,
  fillType: PropTypes.bool,
  showTimeGranulation: PropTypes.bool,
  bodyType: PropTypes.bool,
  className: PropTypes.string,
  bodyTypeActive: PropTypes.string,
  fillTypeActive: PropTypes.string,
  timeActive: PropTypes.string,
  chartTypeActive: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default ClutersFilterGroup;
