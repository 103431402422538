/* eslint-disable */
import axios from 'axios';
import Cookies from 'js-cookie';
import get from 'lodash.get';

// process.env.REACT_APP_ENV === 'development' ? '/users/edit' : '/api/users/edit'
export const getAuthObj = () => (Cookies.get('auth') ? JSON.parse(Cookies.get('auth')) : {});

const URL = process.env.REACT_APP_ENV === 'development' ? process.env.REACT_APP_REST_API : `${window.location.protocol}//${window.location.hostname}/api/`;
let isAlreadyFetchingAccessToken = false;
// let subscribers = []

// function onAccessTokenFetched(access_token) {
//   subscribers = subscribers.filter(callback => callback(access_token))
// }

// function addSubscriber(callback) {
//   subscribers.push(callback)
// }
// const axiosObject = () =>
//   Cookies.get('auth')
//     ? {
//         baseURL: URL,
//         headers: {
//           Authorization: `Bearer ${get(getAuthObj(), 'accessToken', null)}`,
//         },
//         params: {},
//       }
//     : {
//         baseURL: URL,
//         params: {},
//       };

const axiosInstance = axios.create({
  baseURL: URL,
  params: {},
});
axiosInstance.defaults.headers.Authorization = Cookies.get('auth') ? `Bearer ${get(getAuthObj(), 'accessToken', '')}` : '';

export const refreshAccessToken = async () => {
  try {
    const profile = get(getAuthObj(), 'profile', null);
    const token = await axios.post(
      `${URL}users/refresh`,
      {},
      {
        headers: {
          Authorization: `Bearer ${get(getAuthObj(), 'refreshToken', null)}`,
        },
      }
    );

    Cookies.set('auth', { ...token.data, profile }, { expires: 30 });
    return token.data;
  } catch (err) {
    Cookies.remove('auth');
    window.location.href = '/log_in';
    return null;
  }
};

const errorHandler = async error => {
  if (
    get(error, 'request.status', false) === 401 &&
    !error.config.url.includes('users/refresh') &&
    !error.config.url.includes('users/signin') &&
    !error.config.url.includes('users/delete')
  ) {
    const originalRequest = error.config;
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const refreshed = await refreshAccessToken();
      if (refreshed) {
        originalRequest.headers.Authorization = `Bearer ${refreshed.accessToken}`;
        axiosInstance.defaults.headers.Authorization = `Bearer ${refreshed.accessToken}`;
        isAlreadyFetchingAccessToken = false;
        return axiosInstance.request(originalRequest);
      }
    } else {
      setTimeout(() => {
        originalRequest.headers.Authorization = Cookies.get('auth') ? `Bearer ${get(getAuthObj(), 'accessToken', '')}` : '';
        axiosInstance.request(originalRequest);
      }, 250);
    }

    throw error;
  }

  // if (!isAlreadyFetchingAccessToken) {
  //   isAlreadyFetchingAccessToken = true
  //   store.dispatch(fetchAccessToken()).then((access_token) => {
  //     isAlreadyFetchingAccessToken = false
  //     onAccessTokenFetched(access_token)
  //   })
  // }

  // const retryOriginalRequest = new Promise((resolve) => {
  //   addSubscriber(access_token => {
  //     originalRequest.headers.Authorization = 'Bearer ' + access_token
  //     resolve(axios(originalRequest))
  //   })
  // })
  // return retryOriginalRequest

  throw error;
};

const successHandler = response => response;

axiosInstance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);

export default axiosInstance;
