/**
 * @flow
 * @relayHash 5c64c32c275cea15333f7e8aecf0db83
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FuelPriceQueryVariables = {|
  gteDate: any,
  lteDate: any,
|};
export type FuelPriceQueryResponse = {|
  +getGlobalPrices: $ReadOnlyArray<{|
    +date: any,
    +currency: ?string,
    +wti: ?number,
    +opec: ?number,
    +brent: ?number,
    +eurRate: ?number,
  |}>
|};
export type FuelPriceQuery = {|
  variables: FuelPriceQueryVariables,
  response: FuelPriceQueryResponse,
|};
*/


/*
query FuelPriceQuery(
  $gteDate: DateTime!
  $lteDate: DateTime!
) {
  getGlobalPrices(gteDate: $gteDate, lteDate: $lteDate) {
    date
    currency
    wti
    opec
    brent
    eurRate
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "gteDate",
    "type": "DateTime!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "lteDate",
    "type": "DateTime!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getGlobalPrices",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "gteDate",
        "variableName": "gteDate"
      },
      {
        "kind": "Variable",
        "name": "lteDate",
        "variableName": "lteDate"
      }
    ],
    "concreteType": "GlobalPetrolPrice",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "date",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "currency",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "wti",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "opec",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "brent",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "eurRate",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FuelPriceQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "FuelPriceQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "FuelPriceQuery",
    "id": null,
    "text": "query FuelPriceQuery(\n  $gteDate: DateTime!\n  $lteDate: DateTime!\n) {\n  getGlobalPrices(gteDate: $gteDate, lteDate: $lteDate) {\n    date\n    currency\n    wti\n    opec\n    brent\n    eurRate\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2eb7e61615bf830c15cdaa07303ca1e9';
module.exports = node;
