/**
 * @flow
 * @relayHash 2dec52b601702a047c1e19ee827cc9b2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type nutsAllDynamicsQueryVariables = {|
  direction: string,
  date?: ?any,
|};
export type nutsAllDynamicsQueryResponse = {|
  +getNutsActivity: $ReadOnlyArray<{|
    +departure: string,
    +destination: string,
    +createDate: any,
    +supplyFtl: ?number,
    +supplyLtl: ?number,
    +supplyBus: ?number,
    +priceFtl: ?number,
    +priceLtl: ?number,
    +priceBus: ?number,
    +demandLtl: ?number,
    +demandFtl: ?number,
    +demandBus: ?number,
    +nuts: {|
      +nuts: string,
      +latinName: ?string,
      +localName: ?string,
      +namePL: ?string,
      +nameEN: ?string,
    |},
  |}>
|};
export type nutsAllDynamicsQuery = {|
  variables: nutsAllDynamicsQueryVariables,
  response: nutsAllDynamicsQueryResponse,
|};
*/


/*
query nutsAllDynamicsQuery(
  $direction: String!
  $date: DateTime
) {
  getNutsActivity(direction: $direction, date: $date) {
    departure
    destination
    createDate
    supplyFtl
    supplyLtl
    supplyBus
    priceFtl
    priceLtl
    priceBus
    demandLtl
    demandFtl
    demandBus
    nuts {
      nuts
      latinName
      localName
      namePL
      nameEN
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "direction",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "date",
    "type": "DateTime",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getNutsActivity",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "date",
        "variableName": "date"
      },
      {
        "kind": "Variable",
        "name": "direction",
        "variableName": "direction"
      }
    ],
    "concreteType": "NutsActivityMonthly",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "departure",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "destination",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "createDate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyFtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyLtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyBus",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "priceFtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "priceLtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "priceBus",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandLtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandFtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandBus",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "nuts",
        "storageKey": null,
        "args": null,
        "concreteType": "Nuts",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "nuts",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "latinName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "localName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "namePL",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "nameEN",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "nutsAllDynamicsQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "nutsAllDynamicsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "nutsAllDynamicsQuery",
    "id": null,
    "text": "query nutsAllDynamicsQuery(\n  $direction: String!\n  $date: DateTime\n) {\n  getNutsActivity(direction: $direction, date: $date) {\n    departure\n    destination\n    createDate\n    supplyFtl\n    supplyLtl\n    supplyBus\n    priceFtl\n    priceLtl\n    priceBus\n    demandLtl\n    demandFtl\n    demandBus\n    nuts {\n      nuts\n      latinName\n      localName\n      namePL\n      nameEN\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '47e1388cbfa980c930d47f50082d25cc';
module.exports = node;
