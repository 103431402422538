/*eslint-disable */
import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Cookies from 'js-cookie';
import useInterval from '@use-it/interval';
import axiosInstance from '../../../utils/axios';
import Loader from '../../loaders/Loader';
import Button from '../../form/Button';
import { ProfileContext } from '../../../context/Profile';

// eslint-disable-next-line no-unused-vars
const AfterOrder = ({ params }) => {
  const { dispatch } = useContext(ProfileContext);
  const { t } = useTranslation('common');
  const history = useHistory();
  const [count, setCount] = useState(20);
  const [status, setStatus] = useState('paymentConfirmation'); //paymentConfirmation, paymentError, paymentSuccess

  useInterval(
    () => {
      fetchProfile();
      setCount(currentCount => currentCount - 1);
      count <= 1 ? setStatus('paymentError') : null;
    },
    count === 0 ? null : 5000
  );

  const fetchProfile = async () => {
    const profile = await axiosInstance.get('users/profile');
    const role = profile.data.role;
    role !== 'FREE'
      ? (setCount(0),
        setStatus('paymentSuccess'),
        Cookies.set(
          'auth',
          {
            ...JSON.parse(Cookies.get('auth')),
            profile: profile.data,
          },
          { expires: 30 }
        ),
        dispatch({ type: 'LOGIN', data: profile.data }))
      : null;
  };

  useEffect(() => {
    setTimeout(() => fetchProfile(), 1010);
  }, []);

  return (
    <div className="modal_payments modal_after-order">
      <Trans i18nKey={`common:msg.${status}`}>
        <p className="modal_payments__title">head</p>
        <p className="modal_after-order__description mt-3">body</p>
      </Trans>
      <div className="my-5">
        {status === 'paymentConfirmation' ? (
          <Loader />
        ) : (
          <Button
            text={status === 'paymentError' ? 'close' : 'goToMetrix'}
            // disabled={count !== 0}
            // option="link"
            type="button"
            className="mx-3"
            onClick={() => history.replace(history.location.pathname)}
          />
        )}
      </div>
    </div>
  );
};

AfterOrder.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.any,
};

export default AfterOrder;
