import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import svgGradient from '../components/svgGradinet';
// import PropTypes from 'prop-types';
// import TopbarSidebarButton from './TopbarSidebarButton';
// import TopbarProfile from './TopbarProfile';
import TransINFO from '../../../shared/img/transinfo';
import TransEU from '../../../shared/img/transeu.png';

class Topbar extends PureComponent {
  // static propTypes = {
  //   changeMobileSidebarVisibility: PropTypes.func, // .isRequired
  //   changeSidebarVisibility: PropTypes.func, // .isRequired
  // };

  render() {
    // const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;

    return (
      <div className="topbar">
        <Link className="topbar__logo" to="/dashboard" />
        <div className="topbar__right">
          <span>Powered by: </span>
          <a href="https://trans.info" title="TransINFO" target="_blank" rel="noopener noreferrer">
            <TransINFO color="color" />
          </a>
          <a href="https://trans.eu" title="trans.eu" target="_blank" rel="noopener noreferrer">
            <img src={TransEU} alt="trans.eu" />
          </a>
          {svgGradient()}
        </div>
      </div>
    );
  }
}

export default Topbar;
