import React from 'react';

export default () => (
  <svg viewBox="0 0 1 1" width="1px" height="1px">
    <defs>
      <linearGradient id="svg-gradient">
        <stop offset="0%" stopColor="var(--color-stop-1)" />
        <stop offset="100%" stopColor="var(--color-stop-2)" />
      </linearGradient>
    </defs>
  </svg>
);
