import React, { Component } from 'react';
import { Map, TileLayer } from 'react-leaflet';
import PropTypes from 'prop-types';

// import TileJson from './TileJson';
// import Routing from './Routing';

const mapNameList = {
  normal: {
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attr: `&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors`,
  },
  night: {
    url: 'https://{s}.tile.thunderforest.com/transport-dark/{z}/{x}/{y}.png', // ?apikey={apikey}
    attr: `attribution: '&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'`,
  },
  rock: {
    url: 'https://{s}.tile.thunderforest.com/spinal-map/{z}/{x}/{y}.png',
    attr: `attribution: '&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'`,
  },
  topo: {
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    attr: `&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors`,
  },
  tile: {
    url: 'https://postcodes-tiles.system.trans.eu/data/v3/{z}/{x}/{y}.pbf',
    attr:
      '<a href="http://www.openmaptiles.org/" target="_blank">&copy; OpenMapTiles</a> <a href="http://www.openstreetmap.org/about/" target="_blank">&copy; OpenStreetMap contributors</a>',
  },
  esri: {
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
    attr: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
  },
};

export default class MapWrapper extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    mapName: PropTypes.string,
    position: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    zoom: PropTypes.number,
  };

  static defaultProps = {
    mapName: 'normal',
    position: [48.3993025, 21.5],
    zoom: 5,
  };

  render() {
    const { children, mapName, position, zoom } = this.props;
    // const { isMapInit } = this.state;

    return (
      <Map
        center={position}
        zoom={zoom}
        {...this.props}
        maxBounds={[
          [69.305355, -26.245201],
          [30.749713, 49.142421],
        ]}
        minZoom={4}
        ref={this.saveMap}
        // maxBounds={[
        //   [65.5, -10.6], 69.305355, -26.245201
        //   [36.05, 70],
        // ]}
        // preferCanvas 55.635684,-10.6822387,17 36.05 35.06
      >
        <TileLayer
          attribution={mapNameList[mapName].attr}
          url={mapNameList[mapName].url}
          styles="https://postcodes-tiles.system.trans.eu/styles/postcodes.json"
        />
        {/* {isMapInit && <TileJson map={this.map} />} */}
        {/* {isMapInit && <Routing map={this.map} pointsList={pointsList} />} */}
        {children}
      </Map>
    );
  }
}
