const UserRole = {
  FREE: 'FREE',
  PRO: 'PRO',
  PRO_HEAD: 'PRO_HEAD',
  ADMIN: 'ADMIN',
  SUPER_ADMIN: 'SUPER_ADMIN',
};

export const JobTitle = {
  FORWARDER: 'forwarder',
  SHIPPER: 'shipper',
  CARRIER: 'carrier',
  CONSULTING: 'consulting',
  MEDIA: 'media',
  OTHER: 'other',
};

export const CompanySize = {
  TINY: '1-10',
  SMALL: '11-50',
  MEDIUM: '51-100',
  BIG: '101-500',
  HUGE: '500+',
};

export const CompanySpecialization = {
  FORWARDER: 'forwarder',
  SHIPPER: 'shipper',
  CARRIER: 'carrier',
  CONSULTING: 'consulting',
  MEDIA: 'media',
  OTHER: 'other',
};

export default UserRole;
