import { graphql } from 'react-relay';

const getCountryDemand = graphql`
  query DemandCountryQuery($departure: String, $destination: String, $gteDate: DateTime, $lteDate: DateTime, $includeWeekends: Boolean, $periods: String) {
    getActivityCountry(
      departure: $departure
      destination: $destination
      gteDate: $gteDate
      lteDate: $lteDate
      includeWeekends: $includeWeekends
      periods: $periods
    ) {
      departure
      destination
      createDate
      demandFtl
      demandLtl
      demandBus
      demandLtlLag1
      demandFtlLag1
      demandBusLag1
      demandLtlLag7
      demandFtlLag7
      demandBusLag7
      demandLtlLag30
      demandFtlLag30
      demandBusLag30
    }
  }
`;

export const getNutDemand = graphql`
  query DemandNutsQuery($departure: String, $destination: String, $gteDate: DateTime, $lteDate: DateTime, $includeWeekends: Boolean, $periods: String) {
    getActivityNuts(
      departure: $departure
      destination: $destination
      gteDate: $gteDate
      lteDate: $lteDate
      includeWeekends: $includeWeekends
      periods: $periods
    ) {
      departure
      destination
      createDate
      demandFtl
      demandLtl
      demandBus
      demandLtlLag1
      demandFtlLag1
      demandBusLag1
      demandLtlLag7
      demandFtlLag7
      demandBusLag7
      demandLtlLag30
      demandFtlLag30
      demandBusLag30
    }
  }
`;

export default getCountryDemand;
