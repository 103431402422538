/* eslint-disable */
import React, { useContext } from 'react';
import { Route, Switch, NavLink, useLocation, Link } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import intersection from 'lodash.intersection';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import Divider from '../../components/decorations/Divider';
import SelectBtnGroup, { ButtonGroupItem } from '../../components/form/SelectBtnGroup';
import SectionWrapper from './components/SectionWrapper';
import CompanyData from './components/CompanyData';
import Payments from './components/Payments';
import Account from './components/Account';
import Users from './components/Users';
import UserRole from '../../enums/User';
import { ProfileContext } from '../../context/Profile';

const MenuTabs = [
  {
    value: 'company',
    label: 'button.companyData',
    visibleFor: [UserRole.FREE, UserRole.PRO, UserRole.PRO_HEAD, UserRole.ADMIN, UserRole.SUPER_ADMIN],
  },
  {
    value: 'payments',
    label: 'button.payments',
    visibleFor: [UserRole.FREE, UserRole.PRO, UserRole.PRO_HEAD, UserRole.ADMIN, UserRole.SUPER_ADMIN],
  },
  {
    value: 'account',
    label: 'button.account',
    visibleFor: [UserRole.FREE, UserRole.PRO, UserRole.PRO_HEAD, UserRole.ADMIN, UserRole.SUPER_ADMIN],
  },
  {
    value: 'users',
    label: 'button.users',
    visibleFor: [UserRole.PRO_HEAD, UserRole.ADMIN, UserRole.SUPER_ADMIN],
  },
];

const visibleController = (menuTab, profile) => intersection([profile.role], menuTab.visibleFor).length > 0;

const Profile = () => {
  const location = useLocation();
  const { t } = useTranslation('common');
  const {
    profile: { email },
    profile,
  } = useContext(ProfileContext);

  return (
    <React.Fragment>
      <Link to="/pages/chart/price" className="ml-1">
        {t('button.returnToMainpage')}
      </Link>
      <div className="profile-section-wrapper">
        <h2 className="mb-3">{t('label.settings') + ': ' + email}</h2>
        <Divider />
        <SelectBtnGroup wrapperClass="profile-nav" btnGroupClass="tabs">
          {MenuTabs.map(option =>
            visibleController(option, profile) ? (
              <NavLink to={`/profile/${option.value}`} activeClassName="active" key={option.value}>
                <ButtonGroupItem option={option} isActive={false} key={option.value} onHandleClick={() => {}} />
              </NavLink>
            ) : null
          )}
        </SelectBtnGroup>
        {/* <TransitionGroup>
          <CSSTransition key={location.pathname} classNames="page" timeout={300}> */}
        <Switch location={location}>
          <Route
            path="/profile/company"
            component={() => (
              <SectionWrapper title={t('label.info')}>
                <CompanyData />
              </SectionWrapper>
            )}
          />
          <Route
            path="/profile/payments"
            component={() => (
              <SectionWrapper title={t('button.payments')}>
                <Payments />
              </SectionWrapper>
            )}
          />
          <Route
            path="/profile/account"
            component={() => (
              <SectionWrapper title={t('button.account')}>
                <Account />
              </SectionWrapper>
            )}
          />
          <Route path="/profile/users" component={() => <Users title={t('button.users')} />} />
        </Switch>
        {/* </CSSTransition> */}
        {/* </TransitionGroup> */}
      </div>
    </React.Fragment>
  );
};

export default Profile;
