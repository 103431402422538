/* eslint-disable */
import React from 'react';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import MainWrapper from './components/MainWrapper';
import { withTranslation } from 'react-i18next';
// import RegisterForm from './components/RegisterForm';

const ForgotPassword = ({ t }) => (
  <MainWrapper title={t('label.passwordReset')} option="passwordReset">
    <ForgotPasswordForm />
  </MainWrapper>
);

export default withTranslation('common')(ForgotPassword);
// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
