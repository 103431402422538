/**
 * @flow
 * @relayHash 3d24c24307512a4e8e8c3483698f3c86
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PriceNutQueryVariables = {|
  departure?: ?string,
  destination?: ?string,
  gteDate?: ?any,
  lteDate?: ?any,
  includeWeekends?: ?boolean,
  periods?: ?string,
|};
export type PriceNutQueryResponse = {|
  +getActivityNuts: $ReadOnlyArray<{|
    +departure: string,
    +destination: string,
    +createDate: any,
    +priceFtl: ?number,
    +priceLtl: ?number,
    +priceBus: ?number,
    +priceLtlLag1: ?number,
    +priceFtlLag1: ?number,
    +priceBusLag1: ?number,
    +priceFtlLag7: ?number,
    +priceBusLag7: ?number,
    +priceLtlLag7: ?number,
    +priceLtlLag30: ?number,
    +priceFtlLag30: ?number,
    +priceBusLag30: ?number,
  |}>
|};
export type PriceNutQuery = {|
  variables: PriceNutQueryVariables,
  response: PriceNutQueryResponse,
|};
*/


/*
query PriceNutQuery(
  $departure: String
  $destination: String
  $gteDate: DateTime
  $lteDate: DateTime
  $includeWeekends: Boolean
  $periods: String
) {
  getActivityNuts(departure: $departure, destination: $destination, gteDate: $gteDate, lteDate: $lteDate, includeWeekends: $includeWeekends, periods: $periods) {
    departure
    destination
    createDate
    priceFtl
    priceLtl
    priceBus
    priceLtlLag1
    priceFtlLag1
    priceBusLag1
    priceFtlLag7
    priceBusLag7
    priceLtlLag7
    priceLtlLag30
    priceFtlLag30
    priceBusLag30
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "departure",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "destination",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "gteDate",
    "type": "DateTime",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "lteDate",
    "type": "DateTime",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeWeekends",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "periods",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getActivityNuts",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "departure",
        "variableName": "departure"
      },
      {
        "kind": "Variable",
        "name": "destination",
        "variableName": "destination"
      },
      {
        "kind": "Variable",
        "name": "gteDate",
        "variableName": "gteDate"
      },
      {
        "kind": "Variable",
        "name": "includeWeekends",
        "variableName": "includeWeekends"
      },
      {
        "kind": "Variable",
        "name": "lteDate",
        "variableName": "lteDate"
      },
      {
        "kind": "Variable",
        "name": "periods",
        "variableName": "periods"
      }
    ],
    "concreteType": "Activity",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "departure",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "destination",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "createDate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "priceFtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "priceLtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "priceBus",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "priceLtlLag1",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "priceFtlLag1",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "priceBusLag1",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "priceFtlLag7",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "priceBusLag7",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "priceLtlLag7",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "priceLtlLag30",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "priceFtlLag30",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "priceBusLag30",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PriceNutQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "PriceNutQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "PriceNutQuery",
    "id": null,
    "text": "query PriceNutQuery(\n  $departure: String\n  $destination: String\n  $gteDate: DateTime\n  $lteDate: DateTime\n  $includeWeekends: Boolean\n  $periods: String\n) {\n  getActivityNuts(departure: $departure, destination: $destination, gteDate: $gteDate, lteDate: $lteDate, includeWeekends: $includeWeekends, periods: $periods) {\n    departure\n    destination\n    createDate\n    priceFtl\n    priceLtl\n    priceBus\n    priceLtlLag1\n    priceFtlLag1\n    priceBusLag1\n    priceFtlLag7\n    priceBusLag7\n    priceLtlLag7\n    priceLtlLag30\n    priceFtlLag30\n    priceBusLag30\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bb87d0ba9a06c746a99a96b87b82d2ed';
module.exports = node;
