import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import BottomBar from './bottombar/BottomBar';

class Layout extends Component {
  static propTypes = {
    sideList: PropTypes.arrayOf(PropTypes.object),
  };

  state = {
    sidebarShow: false,
    sidebarCollapse: false,
  };

  changeSidebarVisibility = () => {
    this.setState(prevState => ({ sidebarCollapse: !prevState.sidebarCollapse }));
  };

  changeMobileSidebarVisibility = () => {
    this.setState(prevState => ({ sidebarShow: !prevState.sidebarShow }));
  };

  render() {
    const { sidebarShow, sidebarCollapse } = this.state;
    const { sideList } = this.props;
    const layoutClass = classNames({
      layout: true,
      'layout--collapse': sidebarCollapse,
    });

    return (
      <div className={layoutClass}>
        <Topbar changeMobileSidebarVisibility={this.changeMobileSidebarVisibility} changeSidebarVisibility={this.changeSidebarVisibility} />
        <Sidebar
          sidebarShow={sidebarShow}
          sidebarCollapse={sidebarCollapse}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          sideList={sideList}
        />
        <BottomBar sideList={sideList} />
      </div>
    );
  }
}

export default withRouter(Layout);
