/* eslint-disable */
import React, { Component } from 'react';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom';
import get from 'lodash.get';
import { fillTypes, chartTypes, timePrecision } from './utils/config';
import { ProfileContext } from '../../context/Profile';

const WithChart = WrapperedComponent => {
  const INCLUDED_WEEKENDS = Cookies.get('includeWeekends') === 'true';
  class ChartHOC extends Component {
    static contextType = ProfileContext;
    constructor(props) {
      super(props);
      this.state = {
        dane: [],
        tunnelId: 299442,
        date: [new Date('2019-01-01'), new Date()],
        chartName: '',
        fillType: fillTypes[2].value,
        chartType: this.getInitialChartType(),
        departure: null,
        destination: null,
        includeWeekends: INCLUDED_WEEKENDS || false,
        region: null,
        regionType: null,
        direction: null,
        periods: timePrecision[0].value,
      };
    }
    setInitialPeriods = () => {
      this.context.profile.role === 'FREE' &&
        this.setState({
          periods: timePrecision[2].value,
        });
    };

    componentDidUpdate(prevProps) {
      const { match } = this.props;
      if (match !== prevProps.match) {
        this.setState({
          chartType: get(match, 'params.chartType', chartTypes[0].value),
        });
      }
    }

    componentDidMount = () => {
      this.setInitialPeriods();
      this.setRegionData();
    };

    shouldComponentUpdate = nextProps => {
      if (this.props.location !== nextProps.location && this.props.location.search.length) {
        this.setState({
          region: null,
          regionType: null,
          direction: null,
        });
      }

      return true;
    };

    setRegionData = () => {
      const params = this.getUrlParamsToObj();
      const region = get(params, 'region', false);
      const regionType = get(params, 'type', 'country');
      const direction = get(params, 'direction', 'from');
      if (region) {
        this.setState({
          region,
          regionType,
          direction,
        });
      }
    };
    //get(this.props, 'location.hash', '')
    getUrlParamsToObj = () => {
      const serachString = get(this.props, 'location.search', null);
      const hashString = get(this.props, 'location.hash', null);
      const hashStringSlice = hashString.indexOf('?');
      if (serachString) {
        return serachString
          .slice(1)
          .split('&')
          .reduce(
            (acc, param) => ({
              ...acc,
              [param.split('=')[0]]: param.split('=')[1],
            }),
            {}
          );
      }
      if (hashStringSlice >= 0) {
        return hashString
          .slice(hashString.indexOf('?'))
          .split('&')
          .reduce(
            (acc, param) => ({
              ...acc,
              [param.split('=')[0]]: param.split('=')[1],
            }),
            {}
          );
      }
      return '';
    };

    getInitialChartType = () => {
      const { match } = this.props;
      return get(match, 'params.chartType', chartTypes[0].value);
    };

    getSelectedRegions = (start, end) => {
      this.setState({
        departure: start,
        destination: end,
        region: null,
      });
    };

    onChangeTunnel = event => {
      this.setState({ tunnelId: event.target.value });
    };

    onChangeChartTitle = title => {
      this.setState({ title });
    };

    onChangeTunnelId = id => {
      this.setState({ tunnelId: id });
    };

    onChangeOptions = (name, value) => {
      if (name === 'fill') {
        this.setState({ fillType: value });
      }
      if (name === 'id') {
        this.setState({ tunnelId: value });
      }
      if (name === 'departure') {
        this.setState({ departure: value });
      }
      if (name === 'destination') {
        this.setState({ destination: value });
      }
      if (name === 'includeWeekends') {
        this.setState({ includeWeekends: value });
      }
      if (name === 'chartType') {
        this.setState({ chartType: value });
      }
    };

    onDateChange = (start, end) => this.setState({ date: [start, end] });

    dateConverter = date => {
      const Year = date.getUTCFullYear().toString();
      /* eslint-disable-next-line */
      const Month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
      /* eslint-disable-next-line */
      const Day = ('0' + date.getUTCDate()).slice(-2); // +1 before - don't know why
      const YearMonthDay = `${Year}-${Month}-${Day}`;

      return YearMonthDay;
    };

    onSwitchChange = options => {
      this.setState(prevState => ({
        [options.name]: !prevState[options.name],
      }));
    };

    handleInputChange = event => {
      const { target } = event;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const { name } = target;
      this.setState({
        [name]: value,
      });
    };

    getCorrectChart = chartName => {
      this.setState({
        chartName,
      });
    };

    setPeriods = name => {
      this.setState({
        periods: name,
      });
    };

    render() {
      return (
        <WrapperedComponent
          {...this.props}
          {...this.state}
          chartFunc={{
            handleInputChange: data => this.handleInputChange(data),
            onChangeOptions: (name, value) => this.onChangeOptions(name, value),
            onSwitchChange: data => this.onSwitchChange(data),
            dateConverter: data => this.dateConverter(data),
            onDateChange: (start, end) => this.onDateChange(start, end),
            onChangeTunnel: data => this.onChangeTunnel(data),
            onChangeChartTitle: data => this.onChangeChartTitle(data),
            onChangeTunnelId: data => this.onChangeTunnelId(data),
            getCorrectChart: data => this.getCorrectChart(data),
            getSelectedRegions: (start, end) => this.getSelectedRegions(start, end),
            setPeriods: event => this.setPeriods(event),
          }}
        />
      );
    }
  }

  ChartHOC.contextType = ProfileContext;

  return withRouter(ChartHOC);
};

export default WithChart;
