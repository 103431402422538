/**
 * @flow
 * @relayHash 52be892df8b6559bc775e379f99faa97
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DemandNutsQueryVariables = {|
  departure?: ?string,
  destination?: ?string,
  gteDate?: ?any,
  lteDate?: ?any,
  includeWeekends?: ?boolean,
  periods?: ?string,
|};
export type DemandNutsQueryResponse = {|
  +getActivityNuts: $ReadOnlyArray<{|
    +departure: string,
    +destination: string,
    +createDate: any,
    +demandFtl: ?number,
    +demandLtl: ?number,
    +demandBus: ?number,
    +demandLtlLag1: ?number,
    +demandFtlLag1: ?number,
    +demandBusLag1: ?number,
    +demandLtlLag7: ?number,
    +demandFtlLag7: ?number,
    +demandBusLag7: ?number,
    +demandLtlLag30: ?number,
    +demandFtlLag30: ?number,
    +demandBusLag30: ?number,
  |}>
|};
export type DemandNutsQuery = {|
  variables: DemandNutsQueryVariables,
  response: DemandNutsQueryResponse,
|};
*/


/*
query DemandNutsQuery(
  $departure: String
  $destination: String
  $gteDate: DateTime
  $lteDate: DateTime
  $includeWeekends: Boolean
  $periods: String
) {
  getActivityNuts(departure: $departure, destination: $destination, gteDate: $gteDate, lteDate: $lteDate, includeWeekends: $includeWeekends, periods: $periods) {
    departure
    destination
    createDate
    demandFtl
    demandLtl
    demandBus
    demandLtlLag1
    demandFtlLag1
    demandBusLag1
    demandLtlLag7
    demandFtlLag7
    demandBusLag7
    demandLtlLag30
    demandFtlLag30
    demandBusLag30
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "departure",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "destination",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "gteDate",
    "type": "DateTime",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "lteDate",
    "type": "DateTime",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeWeekends",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "periods",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getActivityNuts",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "departure",
        "variableName": "departure"
      },
      {
        "kind": "Variable",
        "name": "destination",
        "variableName": "destination"
      },
      {
        "kind": "Variable",
        "name": "gteDate",
        "variableName": "gteDate"
      },
      {
        "kind": "Variable",
        "name": "includeWeekends",
        "variableName": "includeWeekends"
      },
      {
        "kind": "Variable",
        "name": "lteDate",
        "variableName": "lteDate"
      },
      {
        "kind": "Variable",
        "name": "periods",
        "variableName": "periods"
      }
    ],
    "concreteType": "Activity",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "departure",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "destination",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "createDate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandFtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandLtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandBus",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandLtlLag1",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandFtlLag1",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandBusLag1",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandLtlLag7",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandFtlLag7",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandBusLag7",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandLtlLag30",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandFtlLag30",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandBusLag30",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DemandNutsQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DemandNutsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "DemandNutsQuery",
    "id": null,
    "text": "query DemandNutsQuery(\n  $departure: String\n  $destination: String\n  $gteDate: DateTime\n  $lteDate: DateTime\n  $includeWeekends: Boolean\n  $periods: String\n) {\n  getActivityNuts(departure: $departure, destination: $destination, gteDate: $gteDate, lteDate: $lteDate, includeWeekends: $includeWeekends, periods: $periods) {\n    departure\n    destination\n    createDate\n    demandFtl\n    demandLtl\n    demandBus\n    demandLtlLag1\n    demandFtlLag1\n    demandBusLag1\n    demandLtlLag7\n    demandFtlLag7\n    demandBusLag7\n    demandLtlLag30\n    demandFtlLag30\n    demandBusLag30\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f962408f3d7f05e2e11a7fd5ed7ddf4e';
module.exports = node;
