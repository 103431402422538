/**
 * @flow
 * @relayHash 95137a4a42aab0457ce621a657c16ff8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FuelPriceMutationVariables = {|
  gteDate: any,
  lteDate: any,
  country: string,
|};
export type FuelPriceMutationResponse = {|
  +getCountryPrices: $ReadOnlyArray<{|
    +country: string,
    +date: any,
    +currency: ?string,
    +gas95: ?number,
    +diesel: ?number,
    +lpg: ?number,
    +eurRate: ?number,
    +usdRate: ?number,
  |}>
|};
export type FuelPriceMutation = {|
  variables: FuelPriceMutationVariables,
  response: FuelPriceMutationResponse,
|};
*/


/*
query FuelPriceMutation(
  $gteDate: DateTime!
  $lteDate: DateTime!
  $country: String!
) {
  getCountryPrices(gteDate: $gteDate, lteDate: $lteDate, country: $country) {
    country
    date
    currency
    gas95
    diesel
    lpg
    eurRate
    usdRate
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "gteDate",
    "type": "DateTime!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "lteDate",
    "type": "DateTime!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "country",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getCountryPrices",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "country",
        "variableName": "country"
      },
      {
        "kind": "Variable",
        "name": "gteDate",
        "variableName": "gteDate"
      },
      {
        "kind": "Variable",
        "name": "lteDate",
        "variableName": "lteDate"
      }
    ],
    "concreteType": "CountryPetrolPrice",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "country",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "date",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "currency",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "gas95",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "diesel",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lpg",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "eurRate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "usdRate",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FuelPriceMutation",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "FuelPriceMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "FuelPriceMutation",
    "id": null,
    "text": "query FuelPriceMutation(\n  $gteDate: DateTime!\n  $lteDate: DateTime!\n  $country: String!\n) {\n  getCountryPrices(gteDate: $gteDate, lteDate: $lteDate, country: $country) {\n    country\n    date\n    currency\n    gas95\n    diesel\n    lpg\n    eurRate\n    usdRate\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '636c1e856da440cf9bef414ab987e224';
module.exports = node;
