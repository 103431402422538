import Joi from 'joi-browser';

const passwordSchema = Joi.object()
  .keys({
    password: Joi.string()
      .min(8)
      .required(),
    confirmPassword: Joi.any().valid(Joi.ref('password')),
  })
  .options({ abortEarly: false });

export default passwordSchema;
