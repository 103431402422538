import { graphql } from 'react-relay';

// export const getSupplyActivityNut = graphql`
// query SupplyActivityNutQuery($region: String!, $includeWeekends: Boolean, $periods: String) {
//   getLatestNutActivity(
//     region: $region
//     includeWeekends: $includeWeekends
//     periods: $periods
//   ) {
//       date,
//       supplyLtl,
//       supplyFtl,
//       supplyBus,
//       demandLtl,
//       demandFtl,
//       demandBus,
//       priceFtl,
//       priceLtl,
//       priceBus,
//   }
// }
// `;
// export const getSupplyActivityCountry = graphql`
// query SupplyActivityCountryQuery($region: String!, $includeWeekends: Boolean, $periods: String) {
//   getLatestCountryActivity(
//     region: $region
//     includeWeekends: $includeWeekends
//     periods: $periods
//   ) {
//       date,
//       supplyLtl,
//       supplyFtl,
//       supplyBus,
//       demandLtl,
//       demandFtl,
//       demandBus,
//       priceFtl,
//       priceLtl,
//       priceBus,
//   }
// }
// `;
const getCountrySupplyTunnel = graphql`
  query SupplyCountryTunnelsQuery(
    $departure: String
    $destination: String
    $gteDate: DateTime
    $lteDate: DateTime
    $includeWeekends: Boolean
    $periods: String
  ) {
    getActivityCountry(
      departure: $departure
      destination: $destination
      gteDate: $gteDate
      lteDate: $lteDate
      includeWeekends: $includeWeekends
      periods: $periods
    ) {
      departure
      destination
      createDate
      supplyFtl
      supplyLtl
      supplyBus
      supplyLtlLag1
      supplyBusLag1
      supplyFtlLag1
      supplyFtlLag7
      supplyLtlLag7
      supplyBusLag7
      supplyLtlLag30
      supplyBusLag30
      supplyFtlLag30
    }
  }
`;

export const getNutSupplyTunnel = graphql`
  query SupplyNutTunnelsQuery($departure: String, $destination: String, $gteDate: DateTime, $lteDate: DateTime, $includeWeekends: Boolean, $periods: String) {
    getActivityNuts(
      departure: $departure
      destination: $destination
      gteDate: $gteDate
      lteDate: $lteDate
      includeWeekends: $includeWeekends
      periods: $periods
    ) {
      departure
      destination
      createDate
      supplyFtl
      supplyLtl
      supplyBus
      supplyLtlLag1
      supplyBusLag1
      supplyFtlLag1
      supplyFtlLag7
      supplyLtlLag7
      supplyBusLag7
      supplyLtlLag30
      supplyBusLag30
      supplyFtlLag30
    }
  }
`;

export default getCountrySupplyTunnel;
