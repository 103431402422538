/* eslint-disable */
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

export const joiValidatorMsg = err => [
  ...new Set(
    ((err || {}).details || []).reduce((acc, item) => {
      return [...acc, item.path[0]];
    }, [])
  ),
];

const JoiTranslateMsg = ({ err }) => {
  const { t } = useTranslation('common');
  return (
    <>
      {(err || {}).details.map((errorItem, index) => (
        <React.Fragment key={index}>
          <Trans
            i18nKey={`common:validation.${errorItem.type}`}
            count={((errorItem || {}).context || {}).limit || 0}
            values={{
              sign: t('plural.sign', { postProcess: 'interval', count: ((errorItem || {}).context || {}).limit || 0 }),
            }}
          >
            {t(`user.${errorItem.path[0]}`)}
          </Trans>
          <br />
        </React.Fragment>
      ))}
    </>
  );
};

export default JoiTranslateMsg;
