/* eslint-disable camelcase */
import common_fr from './fr/common.json';
import common_en from './en/common.json';
import common_de from './de/common.json';
import common_pl from './pl/common.json';

export const langs = {
  pl: 'Polski',
  en: 'English',
  // fr: 'France',
  // de: 'Deutsch',
};

export default {
  en: {
    common: common_en,
  },
  fr: {
    common: common_fr,
  },
  de: {
    common: common_de,
  },
  pl: {
    common: common_pl,
  },
};
