/* eslint-disable */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { useTranslation, Trans } from 'react-i18next';
import Joi from 'joi-browser';
import get from 'lodash.get';
import Input from '../../form/Input';
import { ProfileContext } from '../../../context/Profile';
import Button from '../../form/Button';
import CountrySelector from '../../../containers/Layout/components/countrySelector';
import getCurrencySymbol from '../../../utils/currency';
import getFormInputsData from '../../../utils/form';
import JoiTranslateMsg from '../../../utils/joiValidatorMsg';
import withNotificationHOC from '../../notification/NotificationsHOC';
import { BasicNotification } from '../../notification/Notifications';
import axiosInstance from '../../../utils/axios';
import { CheckBoxField } from '../../form/CheckBox';

const schema = Joi.object()
  .keys({
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    companyName: Joi.string().required(),
    phoneNumber: Joi.string()
      .min(4)
      .required(),
    address: Joi.string().required(),
    postCode: Joi.string().required(),
    city: Joi.string().required(),
    NIP: Joi.string().required(),
    country: Joi.string().required(),
    consentRODO: Joi.boolean()
      .required()
      .valid(true),
  })
  .options({ abortEarly: false });

// eslint-disable-next-line no-unused-vars
const OrderData = ({ params, handleShowNotification }) => {
  const {
    profile: { NIP, city, postCode, address, country, firstName, lastName, companyName, phoneNumber, language },
    profileUpdate,
  } = useContext(ProfileContext);
  const { t, i18n } = useTranslation('common');
  const [activeCountry, setCountry] = useState(country);
  const [consentRODO, setConsentRODO] = useState(false);
  const [paymentsMethod, setPaymentsMethod] = useState(false);

  const sendOrder = (paymentMethod = 'CARD') => {
    axiosInstance
      .post('/payments/register', {
        productId: params.productId,
        paymentMethod,
      })
      .then(resp => {
        console.dir(resp);
        window.location.href = `${process.env.REACT_APP_PRZELEWY24}${get(resp, 'data.token', '')}`;
      });
  };
  const nextPaymentStep = () => {
    if (i18n.language.slice(0, 2).toUpperCase() === 'PL' && params.period === 'YEAR') {
      setPaymentsMethod(true);
    } else {
      sendOrder();
    }
  };

  const onFormSubmit = e => {
    e.preventDefault();
    const formData = getFormInputsData(e, false);

    const valid = Joi.validate({ ...formData, country: activeCountry }, schema);

    valid
      .then(resp => {
        const { consentRODO, ...sendVal } = resp;
        profileUpdate(sendVal, nextPaymentStep);
      })
      .catch(err => {
        handleShowNotification({
          notification: <BasicNotification color="danger" title={t('msg.failed')} message={<JoiTranslateMsg err={err} />} />,
        });
      });
  };

  return (
    <div className="modal_payments modal_order">
      <p className="modal_payments__title">{t('text.yourOrder')}</p>
      <p className="modal_order__description">
        <Trans i18nKey="common:text.metrixSubscription" values={{ period: t(`packt.${params.period.toLowerCase()}ly`) }} />
      </p>
      <p className="modal_order__price">{`${params.price_net}${getCurrencySymbol(params.currency)}`}</p>
      {paymentsMethod ? (
        <div className="modal_order__paymentsMethod">
          <h2>{t('label.paymentMethod')}</h2>
          <div className="paymentsMethod_selector">
            <Button option="secondary" testid="card" onClick={() => sendOrder('CARD')}>
              {t('label.card')}
            </Button>
            <Button option="secondary" testid="other" onClick={() => sendOrder('OTHER')}>
              {t('label.bankTransfer')}
            </Button>
          </div>
        </div>
      ) : (
        <div className="modal_order__form">
          <form action="" className="form-group input-big" onSubmit={e => onFormSubmit(e)}>
            <Container>
              <Row>
                <Col md={6} sm={12}>
                  <Input required label={t('user.firstName')} name="firstName" value={firstName} />
                  <Input required label={t('user.lastName')} name="lastName" value={lastName} />
                  <Input required label={t('user.phoneNumber')} name="phoneNumber" type="number" value={phoneNumber} />
                  <Input required label={t('user.companyName')} name="companyName" value={companyName} />
                </Col>
                <Col md={6} sm={12}>
                  <Input required label={t('user.NIP')} name="NIP" type="number" value={NIP} />
                  <CountrySelector label={t('user.country')} defaultCountry={country} handleActiveCountry={(name, iso2) => setCountry(iso2)} />
                  <Row>
                    <Col xs={12} lg={5}>
                      <Input required label={t('user.postCode')} name="postCode" value={postCode} />
                    </Col>
                    <Col xs={12} lg={7}>
                      <Input required label={t('user.city')} name="city" value={city} />
                    </Col>
                  </Row>
                  <Input required label={t('label.street')} name="address" value={address} />
                </Col>
              </Row>

              <Row>
                <Col>
                  <CheckBoxField
                    className="w-100 font-size-12"
                    name="consentRODO"
                    value={consentRODO}
                    label={
                      <Trans i18nKey="common:user.marketingConsent1">
                        marketingConsent1
                        <a href="/p/terms-of-service" target="_blank" rel="nofollow noopener noreferrer">
                          termsOfService
                        </a>
                      </Trans>
                    }
                    required
                    onChange={() => setConsentRODO(!consentRODO)}
                  />
                </Col>
              </Row>

              <Row noGutters className="d-flex justify-content-end mt-1">
                <Button option="primary" testid="submit" text="order" />
              </Row>
            </Container>
          </form>
        </div>
      )}
    </div>
  );
};

OrderData.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.any,
  handleShowNotification: PropTypes.func,
};

export default withNotificationHOC(OrderData);
