/* eslint-disable */
import React, { Component } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from 'relay-env';
import Cookies from 'js-cookie';
import { withTranslation } from 'react-i18next';
import WithChart from '../ChartHOC';
import ChartQueryRender from '../ChartQueryRender';
import getSupply from '../../../relay/queries/Supply';
import getDemand from '../../../relay/queries/Demand';
import getPrice from '../../../relay/queries/Price';
import ChartHeader from '../components/ChartHeader';

class MapPopupChart extends Component {
  static defaultProps = {
    maps: null,
  };

  render() {
    const {
      date,
      includeWeekends,
      maps,
      chartType,
      chartFunc: { dateConverter, onChangeOptions, onDateChange, onChangeTunnel, onChangeChartTitle, onChangeTunnelId, getSelectedRegions },
      t,
      chartDataName,
      fillType,
    } = this.props;
    const variables = {
      gteDate: dateConverter(date[0]),
      lteDate: dateConverter(date[1]),
      departure: maps.from,
      destination: maps.to,
      includeWeekends: INCLUDED_WEEKENDS || includeWeekends,
      periods: 'weeks',
      visible: false,
    };
    const INCLUDED_WEEKENDS = Cookies.get('includeWeekends') === 'true';

    return (
      <div className="dashboard">
        <ChartHeader
          title={chartDataName}
          onChangeTunnel={onChangeTunnel}
          onChangeTunnelId={onChangeTunnelId}
          switchHandle={e => onSwitchChange(e)}
          onChangeOptions={onChangeOptions}
          date={date}
          onChangeChartTitle={onChangeChartTitle}
          onDateChange={(start, end) => onDateChange(start, end)}
          fillType={fillType}
          chartType={chartType}
          getSelectedRegions={getSelectedRegions}
        />
        <QueryRenderer
          environment={environment}
          query={chartType === 'price' ? getPrice : chartType === 'supply' ? getSupply : getDemand}
          variables={variables}
          render={({ qerror, props }) =>
            ChartQueryRender({
              qerror,
              data: (props || {}).getActivityCountry,
              compareInTooltip: true,
              chartDataName: chartType,
              chartOptions: [
                {
                  chartName: `${chartType}${fillType}`,
                  stroke: 'url(#colorUv)',
                  strokeWidth: 3,
                  dot: { stroke: '#70bbfd', strokeWidth: 3, fill: '#fff', fillOpacity: 1, r: 4 },
                  activeDot: { stroke: '#70bbfd', strokeWidth: 3, fill: '#fff', r: 6 },
                  fill: 'transparent',
                },
              ],
              ...this.props,
              ...this.props.chartFunc,
              t,
            })
          }
        />
      </div>
    );
  }
}

export default WithChart(withTranslation('common')(MapPopupChart));
