import React, { PureComponent } from 'react';
// import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Button from '../../../components/form/Button';
import Input from '../../../components/form/Input';
// import CheckBox from '../../../shared/components/form/CheckBox';
import getFormInputsData from '../../../utils/form';
import axiosInstance from '../../../utils/axios';
import withNotificationHOC from '../../../components/notification/NotificationsHOC';
import { BasicNotification } from '../../../components/notification/Notifications';

class ForgotPasswordForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    handleShowNotification: PropTypes.func,
  };

  passwordReset = e => {
    const { t, handleShowNotification } = this.props;
    e.preventDefault();
    const { email } = getFormInputsData(e);
    axiosInstance
      .post('/users/reset/link', { email })
      .then(() =>
        handleShowNotification({
          notification: <BasicNotification type="success" title={t('label.passwordReset')} message={t('msg.checkEmail')} />,
        })
      )
      .catch(() =>
        handleShowNotification({
          notification: <BasicNotification color="danger" title={t('label.passwordReset')} message={t('msg.emailNotExist')} />,
        })
      );
    // login(email, password);
  };

  render() {
    // const { t } = this.props;

    return (
      <form className="form form-group input-big" id="forgotPassword" onSubmit={e => this.passwordReset(e)}>
        <Input className="w-100 my-2" name="email" type="text" label="E-mail" />
        <div className="form__footer mt-3">
          <Button option="primary" text="send" isUpperCase className="font-size-16" />
        </div>
      </form>
    );
  }
}

export default withTranslation('common')(withNotificationHOC(ForgotPasswordForm));
