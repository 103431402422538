/* eslint-disable  */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { config as i18nextConfig } from '../../utils/translations';
// import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router from './Router';
import ScrollToTop from './ScrollToTop';
import withAuth from '../Auth/AuthHOC';
import ProfileStore from '../../context/Profile';
import Popup from './Popup';
import { ProvideNotification } from '../../utils/hooks/useNotification';

i18next
  .use(LanguageDetector)
  .use(intervalPlural)
  .use(initReactI18next)
  .init(i18nextConfig);

const App = ({ auth: { isAuth } }) => (
  <I18nextProvider i18n={i18next}>
    <ProvideNotification>
      <BrowserRouter>
        <ProfileStore>
          <Popup>
            <ScrollToTop>
              <Router isAuth={isAuth} />
            </ScrollToTop>
          </Popup>
        </ProfileStore>
      </BrowserRouter>
    </ProvideNotification>
  </I18nextProvider>
);

App.propTypes = {
  auth: PropTypes.shape({
    isAuth: PropTypes.func,
  }),
};

export default hot(module)(withAuth(App));
