/* eslint-disable */
import React from 'react';

export default () => (
  <div className="p-5 text-page">
    <h2>METRIX TERMS OF SERVICE</h2>

    <ol>
      <li>
        <span>GENERAL PROVISIONS</span>
        <ol>
          <li>
            <span>
              These Terms of Service (hereinafter referred to as the &quot;Terms&quot;) specify the rules for the provision of services by electronic means
              within the meaning of the Act of July 18, 2002, on the Provision of Services by Electronic Means (i.e., Journal of Laws of 2019, item 123) and
              terms and conditions of using the Metrix web application.
            </span>
          </li>
          <li>
            <span>
              Metrix is an application dedicated to Users operating in the freight forwarding, transport or logistics industry, intended in particular to
              provide information via an online application about current demand/supply and transport rates, fares and prices as well as information about
              trends in the TFL industry.
            </span>
          </li>
        </ol>
      </li>
      <li>
        <span>DEFINITIONS</span>
        <ol>
          <li>
            <span>Account</span>
            <span>&nbsp;- Metrix functionality management panel assigned to an individually designated User.</span>
          </li>
          <li>
            <span>Metrix</span>
            <span>
              &nbsp;- analytical tool addressed to TFL entrepreneurs, available in the Web form at the following address:{' '}
              <a href="https://trans.info/metrix/">https://trans.info/metrix/</a> allowing to track current rates, fares and prices in the TFL industry and
              demand for services.
            </span>
          </li>
          <li>
            <span>Service Provider/Controller</span>
            <span>
              &nbsp;- Trans.info sp&oacute;&#322;ka z ograniczon&#261; odpowiedzialno&#347;ci&#261; (Polish limited liability company) with its registered
              office in Wysoka, at ul. Chabrowa 4, 52-200 Wysoka, Poland, entered in the Register of Entrepreneurs of the National Court Register kept by the
              District Court for Wroc&#322;aw-Fabryczna in Wroc&#322;aw, VI Commercial Division of the National Court Register under KRS number: 0000751609,
              NIP: 8961581580, REGON: 381491570.
            </span>
          </li>
          <li>
            <span>Privacy Policy</span>
            <span>
              &nbsp;- Metrix&#39;s privacy policy available at:{' '}
              <a href="https://trans.info/en/page/privacy-policy">https://trans.info/en/page/privacy-policy</a>
            </span>
          </li>
          <li>
            <span>User</span>
            <span>
              &nbsp;- any natural or legal person or organizational unit without legal personality, who is granted legal capacity by virtue of statutory law,
              who has registered on Metrix as part of their business in the manner specified in section IV and has an Account.
            </span>
          </li>
          <li>
            <span>Primary User</span>
            <span>&nbsp;- &nbsp;the User who uses the Paid Version of Metrix and has the administrator rights.</span>
          </li>
          <li>
            <span>Subscription fee</span>
            <span>
              &nbsp;- a fee for access to the Full Version of Metrix. The User may pay the Subscription fee in an annual or monthly billing period (hereinafter:
              the &quot;Billing Period&quot;).
            </span>
          </li>
          <li>
            <span>Self-Renewing Subscription</span>
            <span>
              &nbsp;- a service provided at the request of the Primary User for successive automatically renewed Billing Periods selected by the Primary User.
              The Self-Renewing Subscription service is available only if the Monthly Billing Period is selected and is provided only to the Primary Users who
              have chosen the payment card payment method operated by an external payment operator.
            </span>
          </li>
          <li>
            <span>Full Version</span>
            <span>&nbsp;- a paid version that provides access to the functionalities specified in Section V of these Terms of Service.</span>
          </li>
          <li>
            <span>Free Version</span>
            <span>
              &nbsp;- a free version of Metrix which gives the User the possibility to see indicators averaged for whole Europe, with monthly data resolution,
              only for the last 12 months without the possibility of using filters available in the Paid Version.
            </span>
          </li>
          <li>
            <span>Price list</span>
            <span>
              &nbsp;- information about the current rates for the Full Version of Metrix, available at:
              <a href="https://trans.info/metrix/">https://trans.info/metrix/</a>
            </span>
          </li>
          <li>
            <span>Terms of Service</span>
            <span>&nbsp;- these Terms of Service.</span>
          </li>
        </ol>
      </li>
      <li>
        <span>TECHNICAL REQUIREMENTS</span>
        <ol>
          <li>
            <span>Using Metrix is possible provided that the IT system used by the User meets the following technical requirements:</span>
            <ol>
              <li>
                <span>
                  there is a device which allows the Internet access, including software used to view and navigate the Internet resources, accepting html,
                  xhtml, pdf, cookies and javascript enabled;
                </span>
              </li>
              <li>
                <span>the following web browsers are used: Chrome, Firefox, Safari; Chrome is the preferred browser recommended by the Service Provider,</span>
              </li>
              <li>
                <span>broadband connection with speed of at least 512 kbs,</span>
              </li>
            </ol>
          </li>
          <li>
            <span>
              The Service Provider takes appropriate measures to ensure the correct operation of Metrix and undertakes to remove, within a reasonable period of
              time, irregularities in its operation which have been reported by Users.{' '}
            </span>
          </li>
        </ol>
      </li>
      <li>
        <span>REGISTRATION AND LOGIN</span>
        <ol>
          <li>
            <span>In order to create an Account, the User: </span>
            <ol>
              <li>
                <span>fills in a registration form with the following data - in the event that the Free Version has been chosen:</span>
                <ol>
                  <li>
                    <span>Type of business (freight forwarder, carrier, shipper);</span>
                  </li>
                  <li>
                    <span>Country;</span>
                  </li>
                  <li>
                    <span>E-mail address;</span>
                  </li>
                  <li>
                    <span>Password;</span>
                  </li>
                </ol>
                <span>hereinafter: &ldquo;Form&rdquo;;</span>
              </li>

              <li>
                <span>confirms that he/she has read and he/she accepts these Terms of Service;</span>
              </li>
              <li>
                <span>in the event that the Paid Version has been chosen &ndash; the User fills in the registration form with the following data:</span>
                <ol>
                  <li>
                    <span>Name and surname;</span>
                  </li>
                  <li>
                    <span>Company&rsquo;s business name;</span>
                  </li>
                  <li>
                    <span>NIP;</span>
                  </li>
                  <li>
                    <span>Company&rsquo;s full address;</span>
                  </li>
                  <li>
                    <span>Contact details (phone number, e-mail address);</span>
                  </li>
                </ol>
                <span>hereinafter: &ldquo;Form&rdquo;;</span>
              </li>
            </ol>
          </li>
          <li>
            <span>Performing the activities specified in Section 1 (a-c) is mandatory for the provision of the Metrix service.</span>
          </li>
          <li>
            <span>By registering the Account, the User represents that:</span>
            <ol>
              <li>
                <span>
                  the data provided are consistent with the factual and legal status. In the event of any change in the data provided, he/she shall update the
                  data immediately;
                </span>
              </li>
              <li>
                <span>the data provided by the User do not infringe any rights of third parties;</span>
              </li>
              <li>
                <span>he/she is authorised to conclude an electronic service provision agreement;</span>
              </li>
              <li>
                <span>he/she runs business activity covered by the subject of the Metrix service, and thus acts as a professional entity.</span>
              </li>
            </ol>
          </li>
          <li>
            <span>
              After registration in the manner specified in `Subsection 1, the User may log in to Metrix by entering the email address and password generated
              during the registration process.
            </span>
          </li>
          <li>
            <span>It is forbidden to share the Account with any other entities.</span>
          </li>
          <li>
            <span>It is forbidden to use any Accounts belonging to any other entities.</span>
          </li>
          <li>
            <span>Subject to Section 5, each User may create one individual Account.</span>
          </li>
          <li>
            <span>When using Metrix, the User is not allowed to:</span>
            <ol>
              <li>
                <span>
                  download, store, use any automatic devices to save data shared via Metrix (under pain of losing access) and use them for commercial purposes,
                </span>
              </li>
              <li>
                <span>
                  use Metrix in a manner which violates rights and personal interests (and, in particular, the following personal interests: image, dignity,
                  good name) of any other Users and the Service Provider,
                </span>
              </li>
              <li>
                <span>
                  perform any actions that could disable, overload or limit the proper functioning and appearance of Metrix, such as an attack which blocks its
                  operation, interfering with the manner of its display, or any other functions.
                </span>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <span>FULL VERSION</span>
        <ol>
          <li>
            <span>
              The Primary User who created the Account in the manner specified in Section IV (1)(b) and paid for the Subscription, as part of access to the Paid
              Version, may:
            </span>
            <ol>
              <li>
                <span>give access to the Account to 3 additional Users;</span>
              </li>
              <li>
                <span> manage the abovementioned Users&#39; access;</span>
              </li>
              <li>
                <span>extend access to the Paid Version by paying the Subscription in the manner specified in Section VI (1);</span>
              </li>
              <li>
                <span> use the following functionalities:</span>
                <ol>
                  <li>
                    <span>chart presenting the freight price index;</span>
                  </li>
                  <li>
                    <span>chart and map presenting the transport demand index;</span>
                  </li>
                  <li>
                    <span>chart and map presenting the transport area supply index;</span>
                  </li>
                  <li>
                    <span>filtering the charts specified in letters a and b depending on the route and type of cargo.</span>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <span>In order to give access to additional Users, the Primary User:</span>
            <ol>
              <li>
                <span>selects the &quot;users&quot; tab in the Account;</span>
              </li>
              <li>
                <span>enters the e-mail address of an additional User (up to 3 e-mail addresses);</span>
              </li>
            </ol>
          </li>

          <li>
            <span>
              If the Primary User denies one of the Users specified in Section 2 access to the Metrix service, such User is automatically transferred to the
              Free Version or his/her access is denied. These cases are specified in Section VI (7).
            </span>
          </li>
        </ol>
      </li>
      <li>
        <span>PAYMENTS</span>
        <ol>
          <li>
            <span>In order to use the Paid Version of Metrix, the User:</span>
            <ol>
              <li>
                <span>selects the &quot;buy access&quot; tab;</span>
              </li>
              <li>
                <span>selects the type of Subscription to be paid;</span>
              </li>
              <li>
                <span>pays for the selected Subscription;</span>
              </li>
              <li>
                <span>selects the &quot;Pay&quot; button.</span>
              </li>
            </ol>
          </li>
          <li>
            <span>
              After performing the actions described in the above Subsection, the User is redirected to the website of the payment operator, where, in
              accordance with the selected type of Subscription, he/she makes the payment.
            </span>
          </li>
          <li>
            <span>Upon payment of the Subscription fee, the User becomes the Primary User and gains access to the Paid Version of Metrix.</span>
          </li>
          <li>
            <span>The Subscription fee is payable in advance in the annual billing period.</span>
          </li>
          <li>
            <span>
              The term of the Subscription begins upon the payment and granting access to the Primary User and is the same for all Users operating within one
              Account.
            </span>
          </li>
          <li>
            <span>
              Subject to Section VII, in the event of failure to pay the Subscription fee on the last day of the Subscription term at the latest, the Primary
              User shall be automatically transferred to the Free Metrix Version.
            </span>
          </li>
          <li>
            <span>
              Users who have accessed Metrix in the manner described in Section V(1) (1) of the Terms of Service, in the case specified in the above Subsection,
              will be automatically transferred to the Free Metrix Version. In the event that they have not previously registered in the manner specified in
              Section IV (1) (b) or (c), access to Metrix is &#8203;&#8203;blocked, and the User, in order to gain access to the Free Version, should register
              in the manner specified in Section IV (1) (b).
            </span>
          </li>
          <li>
            <span>
              In order to make payments, the Service Provider makes it possible to use services of a payment service provider. If the Service Recipient uses
              online payment operators, it may be necessary to accept such entity&rsquo;s terms of service.
            </span>
          </li>
        </ol>
      </li>
      <li>
        <span>SELF-RENEWING SUBSCRIPTION</span>
        <ol>
          <li>
            <span>
              The Self-Renewing Subscription service is provided if the User, when purchasing a Subscription with a monthly Billing Period, selects the
              self-renewal option and selects the method of payment by payment card. By selecting the self-renewal option, the User agrees to the Administrator
              charging the payment card or charging through the payment operator&#39;s system for subsequent Subscription Billing Periods, in the amount
              indicated in the order placed.
            </span>
            <ol>
              <li>
                <span>
                  {' '}
                  Payment for subsequent monthly Billing Periods under the Self-Renewing Subscription is made by the Administrator charging for the subsequent
                  Billing Period. The amount for the subsequent Billing Period shall be charged no earlier than 48 hours before and no later than 24 hours
                  before the beginning of the subsequent Billing Period.
                </span>
              </li>
              <li>
                <span>
                  {' '}
                  The User may resign from the self-renewal at any time by unchecking the self-renewal option in his/her Account, no later than 48 hours before
                  the beginning of the subsequent Billing Period.
                </span>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <span>COPYRIGHT</span>
        <ol>
          <li>
            <span>
              {' '}
              The use of Metrix under these Terms of Service shall not result in the acquisition by Users of any intellectual property rights to Metrix
              whatsoever. It is forbidden to infringe any intellectual property rights to Metrix in any way, in particular:
            </span>
            <ol>
              <li>
                <span>
                  {' '}
                  copying, modifying and transmitting, electronically or otherwise, Metrix or its parts, as well as individual works and databases, without the
                  express written consent of the Service Provider,
                </span>
              </li>
              <li>
                <span>using Metrix in a manner inconsistent with the Terms of Service,</span>
              </li>
              <li>
                <span>destroying, damaging, deleting or altering Metrix computer, text and graphic data or otherwise disrupting operation.</span>
              </li>
            </ol>
          </li>

          <li>
            <span>By using Metrix, the User does not acquire any rights nor shall he or she obtain a license to Metrix.</span>
          </li>
          <li>
            <span>When using Metrix, the User shall not be entitled, without the prior consent of the Service Provider, to use and publish, </span>
            <span>inter alia</span>
            <span>, screenshots, clippings, photos taken in Metrix.</span>
          </li>
        </ol>
      </li>

      <li>
        <span>DATABASE</span>
        <ol>
          <li>
            <span>
              When using Metrix, a registered User gains access to a Database, i.e. a set of data (including information, documents, analyses) provided by the
              Service Provider to the User for the purpose of using Metrix.
            </span>
          </li>
          <li>
            <span>Each User has the right to freely access and use the Database free of charge.</span>
          </li>
          <li>
            <span>
              The Service Provider uses the Database under a license agreement. The Database is protected under the provisions of Polish law: the Act of 27
              July, 2001, on the Protection of Databases and the Act of 4 February, 1994, on Copyright and Related Rights, and Directive 96/9/EC of the European
              Parliament and of the Council on 11 March, 1996, on the Legal Protection of Databases.
            </span>
          </li>
        </ol>
      </li>
      <li>
        <span>LIABILITY</span>
        <ol>
          <li>
            <span>
              The Service Provider makes every effort to ensure that the presented data are reliable and supported by prior analysis of the market situation. At
              the same time, the Service Provider shall not be held liable for any business decisions taken on the basis of information made available through
              Metrix.
            </span>
          </li>
          <li>
            <span>The Service Provider has the right to disable access to the Test Version of Metrix at any time without giving reasons.</span>
          </li>
          <li>
            <span>
              The User shall bear full liability for his/her acts and omissions related to the use of Metrix, in particular he/she may be held liable for
              damages towards the Service Provider or other Users.
            </span>
          </li>
          <li>
            <span>
              The Service Provider shall not be held liable for any disturbances in the operation of Metrix caused by force majeure, equipment failure, database
              and server overload.
            </span>
          </li>
          <li>
            <span>
              The Service Provider makes every effort to ensure the continuous operation of Metrix, but it may not guarantee its uninterrupted availability. The
              Service Provider will make every effort to ensure that Metrix is &#8203;&#8203;available at a level not lower than 98% per annum.
            </span>
          </li>
          <li>
            <span>The Service Provider shall be held liable only for damage caused by wilful misconduct.</span>
          </li>
          <li>
            <span>
              The Service Provider shall not be held liable for Users&#39; conduct while using the Metrix application, as well as for any consequences of any
              actions performed by Users and third parties in breach of these Terms. The Service Provider in particular shall not be held liable for solvency of
              Users and accuracy and reliability of the information which they provide.
            </span>
          </li>
          <li>
            <span>The Service Provider excludes its liability to the extent permitted by law for, including but not limited to:</span>
            <ol>
              <li>
                <span>any damage caused to third parties as a result of the User using the services contrary to these Terms or the law,</span>
              </li>
              <li>
                <span>any damage caused by third parties to whom Users, contrary to these Terms, provided data from the Account,</span>
              </li>
              <li>
                <span>
                  unavailability of Metrix due to reasons beyond the control of the Service Provider, including force majeure or incorrect operation of the
                  Internet,
                </span>
              </li>
              <li>
                <span>
                  interruptions in access to Metrix, including those preventing the use of Metrix, caused by the need to remove failures, hardware and software
                  testing, and maintenance,
                </span>
              </li>
              <li>
                <span>
                  damage suffered by Users caused by an account lockout or blocking access to part or all of the services for any User whose actions are
                  contrary to generally applicable provisions of law, these Terms, decency, as well as if such actions threaten or violate the interest of the
                  Service Provider or any other Users.
                </span>
              </li>
            </ol>
          </li>
          <li>
            <span>
              The Service Provider shall be held liable only in the scope of services provided electronically and in compliance with the principles set out in
              the regulations on the provision of services by electronic means and in
            </span>
            <span>
              &nbsp;Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April, 2016, on the protection of natural persons with regard
              to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (hereinafter: GDPR).
            </span>
          </li>
        </ol>
      </li>

      <li>
        <span>TERMINATION OF AGREEMENT AND ACCOUNT LOCKOUT</span>
        <ol>
          <li>
            <span>In the event of a breach of these Terms or providing false or outdated data, the Service Provider has the right to:</span>
            <ol>
              <li>
                <span>request the User to remove irregularities within the time limit set by the Service Provider;</span>
              </li>
              <li>
                <span>lock out the Account;</span>
              </li>
              <li>
                <span>terminate the service provision agreement with immediate effect.</span>
              </li>
            </ol>
          </li>
          <li>
            <span>In the event of the Account lockout referred to in Subsection 1 (b), all Metrix functionalities are disabled.</span>
          </li>
          <li>
            <span>
              In the cases specified in Subsections 1 and 2 and in Section XIV(3), the amount of the Subscription fee shall be refunded proportionally to the
              period of using the Subscription, no later than within 14 days from the date of blocking the Subscription. The refund shall be made using the same
              payment methods which were used by the Primary User when paying for the Subscription.
            </span>
          </li>
          <li>
            <span>
              In the event of withdrawal of consent to any of the conditions of these Terms, the Service Provider will lock out the User&#39;s Account.
            </span>
          </li>
          <li>
            <span>The User may terminate the Metrix Service Provision Agreement at any time by submitting a request to delete his/her Account.</span>
          </li>
        </ol>
      </li>
      <li>
        <span>PROTECTION OF PERSONAL DATA</span>
        <ol>
          <li>
            <span>
              The Controller of Users&rsquo; personal data is Trans.info sp&oacute;&#322;ka z ograniczon&#261; odpowiedzialno&#347;ci&#261; with its registered
              office in Wysoka, at ul. Chabrowa 4, 52-200 Wysoka, Poland, entered in the Register of Entrepreneurs of the National Court Register kept by the
              District Court for Wroc&#322;aw-Fabryczna in Wroc&#322;aw, VI Commercial Division of the National Court Register under KRS number: 0000751609,
              NIP: 8961581580, REGON: 381491570, share capital of PLN 217,250.00.
            </span>
          </li>
          <li>
            <span>Users&rsquo; data are processed in accordance with the GDPR and Polish regulations regarding the protection of personal data.</span>
            <ol>
              <li>
                <span>
                  Data are processed for the preparation and provision of the Metrix service - obligations under the agreement (Article 6 (1) (b) of the GDPR);
                </span>
              </li>
              <li>
                <span>
                  if necessary, we process Users&rsquo; data for purposes resulting from legitimate interests pursued by the Service Provider or a third party
                  (Article 6 (1) (f) of the GDPR);
                </span>
              </li>
              <li>
                <span>
                  we process Users&rsquo; data to protect our own legitimate interests or those of third parties. Examples of these include: data processing
                  within the Trans.eu S.A. Group, answering questions / handling complaints, sending the Controller&rsquo;s newsletter regarding changes in
                  software, changes and amendments to the Terms of Service, etc., ensuring IT security of the Controller; customer satisfaction surveys,
                  pursuing claims and defending against claims, in direct marketing of the Controller&rsquo;s products and services, as well as of entities from
                  the Trans.eu group, as well as of other entities to which the Controller provides services under separate agreements, for the
                  Controller&rsquo;s internal administrative purposes, such as preparing statistics;
                </span>
              </li>
              <li>
                <span>we process data upon Users&rsquo; consent (Article 6 (1) (a) of the GDPR), granted for specific purposes;</span>
                <span>
                  The consent may be withdrawn at any time. The consent withdrawal does not affect the lawfulness of data processing until the consent is
                  withdrawn.
                </span>
              </li>
            </ol>
          </li>

          <li>
            <span>
              The data may be made available to other recipients in order to perform the agreement with the Participant, in order to comply with a legal
              obligation of the Service Provider, upon the User&rsquo;s consent or for purposes arising from the legitimate interests of the Controller or a
              third party. The following entities may be, in particular, the recipients of the data: authorised employees of the Service Provider, entities from
              the Trans.eu S.A. Group and other entities statutorily authorised to collect Users&rsquo; data under relevant legal provisions. The data may also
              be provided to other entities carrying out activities for and on behalf of the Service Provider under separate agreements, in particular to
              entities providing IT support services or auditing entities. Access to the data is granted to these recipients who need the data in question to
              fulfill contractual and / or legal obligations.
            </span>
          </li>
          <li>
            <span>Users&rsquo; data will be processed for the period necessary to achieve the processing purposes specified in Section 2, i.e.:</span>
            <ol>
              <li>
                <span>
                  to provide the Metrix Service - until the completion thereof, and after that for the period required by law or for the execution of any
                  claims,
                </span>
              </li>
              <li>
                <span>where the processing of the data is based upon consent, immediately upon your request to delete the data,</span>
              </li>
              <li>
                <span>
                  until the legitimate interests of the Service Provider constituting the basis of this processing are fulfilled or until an objection to such
                  processing is filed, unless there are legitimate grounds for further processing.
                </span>
              </li>
            </ol>
          </li>
          <li>
            <span> Users have the right to:</span>
            <ol>
              <li>
                <span>request access to their data, as well as to demand rectification, limitation of processing or deletion thereof.</span>
              </li>
              <li>
                <span>
                  where the processing of data is based upon consent, Users have the right - at any time - to withdraw their prior consent. With the proviso
                  that the withdrawal of consent will not affect the lawfulness of processing which had been carried out upon consent before it was withdrawn.
                </span>
              </li>
              <li>
                <span>
                  request to transfer the data provided to the Service Provider processed for the purpose of entering into and performing the agreement or
                  processed upon consent. This right does not apply to any data which constitute trade secrets.
                </span>
              </li>
              <li>
                <span>
                  if Users assume that the processing of their data breaches the provisions of the GDPR, to submit a complaint to the supervisory body which, in
                  the Republic of Poland, is the President of the Office of Personal Data Protection.
                </span>
              </li>
              <span>Furthermore, Users have the right to object at any time to the processing of their data:</span>

              <li>
                <span>
                  for reasons related to a special situation when the Service Provider processes data for purposes arising from legitimate interests, including
                  profiling.
                </span>
              </li>
              <span>While exercising the said rights the Service Provider may request additional information necessary to confirm the identity.</span>
            </ol>
          </li>
          <li>
            <span>
              If Users&rsquo; data are processed for the purpose of the Metrix service, rendering the said service is subject to the provision of the data. The
              provision of the data is voluntary, but failure to do so will result in inability to provide the Metrix service.{' '}
            </span>
          </li>
          <li>
            <span>Detailed information on the processing of personal data is set out in the Privacy Policy, available at:</span>
            <span>
              <a href="https://trans.info/en/page/privacy-policy">https://trans.info/en/page/privacy-policy</a>
            </span>
            <span>.</span>
          </li>
        </ol>
      </li>
      <li>
        <span>COMPLAINTS</span>
        <ol>
          <li>
            <span>
              Any complaints, including those related to the use of Metrix, payment, operation, etc. must be submitted in writing to the following address:
              Trans.info Sp. z o. o., ul. Chabrowa 4, 52-200 Wysoka or by e-mail to redakcja@trans.info
            </span>
          </li>
          <li>
            <span>
              Complaints will be considered within 30 days of receipt. A response to a complaint will be sent to the e-mail address provided by the User, and in
              case of a complaint submitted by post - to the address provided by the User.
            </span>
          </li>
          <li>
            <span>
              Save and except the manner specified in Subsections 1 and 2 above, the Service Provider does not provide for the possibility of using any
              out-of-court means of complaints handling and pursuing claims.
            </span>
          </li>
        </ol>
      </li>

      <li>
        <span>AMENDMENTS TO THE TERMS OF SERVICE AND PRICE LIST</span>
        <ol>
          <li>
            <span>The Service Provider has the right to amend the Price List at any time.</span>
          </li>
          <li>
            <span>
              The Service Provider will inform about an amendment to the Price List 14 days in advance by sending a User an e-mail to the address provided
              during the registration process. The amendments will take effect 14 days after sending the said notification. This shall not affect the price paid
              before the Subscription amendment comes into force.
            </span>
          </li>
          <li>
            <span>
              If the amendments to the Price List referred to in the above Subsection are not accepted, the User - within 14 days from the publication of the
              amendments in the manner specified in the Subsection above, has the right to terminate the agreement with immediate effect.
            </span>
          </li>
          <li>
            <span>
              The Service Provider has the right to introduce periodic discounts, promotions and other temporary reductions in rates and fees from the Price
              List. These changes shall not constitute an amendment to the Price List within the meaning of Subsection 3 and shall not give rise to the
              termination of the agreement.
            </span>
          </li>
          <li>
            <span>The Service Provider reserves the right to amend the Terms of Service if at least one of the following important reasons occurs:</span>
            <ol>
              <li>
                <span>
                  any change of regulating the provision of electronic services by the Service Provider affecting the mutual rights and obligations set out in
                  these Terms of Service;
                </span>
              </li>
              <li>
                <span>the need to adjust the business activity of the Service Provider to any orders, rulings, decisions or guidelines resulting from:</span>
                <ol>
                  <li>
                    <span>
                      a decision of a public administration body competent in the scope of the business activity of the Service Provider, affecting mutual
                      rights and obligations set out in these Terms of Service;
                    </span>
                  </li>
                  <span>or</span>
                  <li>
                    <span>
                      a court ruling applicable to the business activity of the Service Provider affecting mutual rights and obligations set out in these Terms
                      of Service;
                    </span>
                  </li>
                </ol>
              </li>

              <li>
                <span>
                  change in the method of providing services by electronic means by the Service Provider caused solely by technical or technological reasons (in
                  particular, updating technical requirements);
                </span>
              </li>
              <li>
                <span>merging, dividing or transforming the Service Provider or any changes to any other details of the Service Provider.</span>
              </li>
            </ol>
          </li>
          <li>
            <span>
              Any change in the scope or rules of provision of services by the Service Provider to which these Terms apply, by introducing new ones, modifying
              or removing any existing functionalities by the Service Provider, as well as changing the Price List and introducing a special offer shall not
              constitute an amendment to these Terms of Service.
            </span>
          </li>
          <li>
            <span>
              The Service Provider publishes any amendments on <a href="https://trans.info/metrix/">https://trans.info/metrix/</a>. The amendments come into
              force upon publishing the amended Terms of Service on the Service Provider&#39;s website.
            </span>
          </li>
        </ol>
      </li>
      <li>
        <span>FINAL PROVISIONS</span>
        <ol>
          <li>
            <span>
              Any disputes arising in connection with the use of Metrix shall be settled by the court having jurisdiction over for the registered office of the
              Service Provider.
            </span>
          </li>
          <li>
            <span>These Terms of Service and any Metrix services shall be goverened by and construed in accordance with the laws of Poland.</span>
          </li>
        </ol>
      </li>
    </ol>
  </div>
);
