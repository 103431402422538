/* eslint-disable */
import React from 'react';

import MainWrapper from './components/MainWrapper';
import { withTranslation } from 'react-i18next';
// import RegisterForm from './components/RegisterForm';

const Beta = ({ t }) => (
  <MainWrapper>
    <h4>{t('msg.freeTimeEnded')}</h4>
  </MainWrapper>
);

export default withTranslation('common')(Beta);
