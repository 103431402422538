// import get from 'lodash.get';

// const getStatsName = (statsName = '', fillType = 'All', bodyType = 'All') => {
//   const statsDictionary = {
//     All: '',
//     0: '',
//     Bulk: '_bulk',
//     Cooler: '_cooler',
//     Curtain: '_curtain',
//     Van: '_van',
//     FTL: '_FTL',
//     LTL: '_LTL',
//   };

//   return `${statsName}${get(statsDictionary, fillType, '')}${get(statsDictionary, bodyType, '')}`;
// };

// export default getStatsName;

const getStatsName = (statsName = '', fillType = 'All') => {
  const name = statsName.substring(0, statsName.length - 3);
  const extension = statsName.substring(statsName.length - 3);

  const filteredName = fillType === extension ? `${name}${extension}` : '';

  return fillType === 'All' ? statsName : filteredName;
};

export default getStatsName;
