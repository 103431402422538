// convert time from database
// 2020-02-03T00:00:00.000Z to 2020-02-03

const convertDateFormat = (date, separator = '.') => {
  if (date === undefined || date === '' || date === null) return '';

  const d = new Date(date);
  const year = d.getFullYear();
  const month = d.toLocaleString('default', { month: '2-digit' });
  const day = d.getDate();
  return date !== undefined ? `${day}${separator}${month}${separator}${year}` : '';
};

export default convertDateFormat;

export const convertDateWithoutYear = (date, separator = '.') => {
  if (date === undefined || date === '' || date === null) return '';

  const d = new Date(date);
  const month = d.toLocaleString('default', { month: '2-digit' });
  const day = d.getDate();

  return date !== undefined ? `${day}${separator}${month}` : '';
};
