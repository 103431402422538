/* eslint-disable */
import React, { useState } from 'react';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import Cookies from 'js-cookie';
import SideSlidePanel from './SideSlidePanel';
import Calculator from './Calculator';
import 'react-datepicker/dist/react-datepicker.css';
import SelectBtnGroup, { ButtonGroupItem } from '../../../components/form/SelectBtnGroup';
import Button from '../../../components/form/Button';
import DateModal from '../../../components/modals/containers/DateModal';
import { fillTypes, chartTypes } from '../utils/config';
import Blocked, { BlockedWrapper } from '../../../components/form/Blocked';
import IncludeWeekendsSwitch from '../../Layout/components/IncludeWeekendsSwitch';
import SearchRegions from '../../../components/search/SearchRegions';
import useNotify from '../../../utils/hooks/useNotification';

const ChartHeader = ({
  onChangeOptions,
  onDateChange,
  navigationsFields = ['date', 'tunnel', 'ftl', 'weekends', 'chartTypes'],
  originalValue,
  date,
  fillType,
  chartType,
  getSelectedRegions,
  departure,
  destination,
}) => {
  const { t, i18n } = useTranslation('common');
  const notify = useNotify();
  const [regionStart, setRegionStart] = useState(null);
  const [regionEnd, setRegionEnd] = useState(null);
  const searchTunnel = () => {
    const start = regionStart || departure;
    const end = regionEnd || destination;

    if (start === null || end === null) {
      notify.show(t('msg.tunnelsNotSelected'));
      return;
    }

    if (end.type !== start.type) {
      if (regionEnd.type === 'nuts') {
        getSelectedRegions(start, {
          ...end,
          iso2: end.country,
        });
        return;
      }
      getSelectedRegions(
        {
          ...start,
          iso2: start.country,
        },
        end
      );
      return;
    }

    getSelectedRegions(start, end);
  };
  return (
    <div className="chart-header p-0">
      <Row className="m-0 justify-content-between align-items-end mb-2 main_filters_group">
        {navigationsFields.indexOf('date') !== -1 && (
          <div className="btn-group-select px-2 mb-1">
            <Blocked className="zeroMargin" />
            <p className="btn-group-select__label">{t('label.date')}:</p>
            <DateModal
              initStartProp={date[0]}
              initEndProp={date[1]}
              onDateChange={(start, end) => onDateChange(start, end)}
              lang={i18n.language.toLowerCase()}
            />
          </div>
        )}
        {navigationsFields.indexOf('tunnel') !== -1 && (
          <BlockedWrapper childClass="d-flex flex-row align-items-end" linkClass="my-2 my-md-0">
            <div className="group-dropdown group-dropdown--smaller mb-3 mb-md-1">
              <p className="group-dropdown__label d-none d-md-block">{t('label.route')}:</p>
              <div className="tunnels-selector">
                <div className="btn-group-select px-2 tunnel-zindex">
                  <p className="btn-group-select__label">{t('label.from')}:</p>
                  <SearchRegions
                    isSmall
                    handleChange={data => {
                      if (data === undefined || data === null) return;
                      Cookies.set('departure', data[0]);
                      setRegionStart(data[0]);
                    }}
                    values={regionStart || departure}
                  />
                </div>
                <Button
                  type="button"
                  // option="secondary"
                  className="tunnel-switch ml-0 mr-2"
                  onClick={() => {
                    const oldStart = regionStart || departure;
                    const oldEnd = regionEnd || destination;
                    Cookies.set('destination', oldStart);
                    Cookies.set('departure', oldEnd);
                    setRegionEnd(oldStart);
                    setRegionStart(oldEnd);
                  }}
                >
                  <span className="lnr lnr-sync ml-1"></span>
                  <span className="px-2 d-block d-md-none">{t('label.changeStartAndEnd')}</span>
                </Button>
                <div className="btn-group-select">
                  <p className="btn-group-select__label">{t('label.to')}:</p>
                  <SearchRegions
                    isSmall
                    handleChange={data => {
                      if (data === undefined || data === null) return;
                      Cookies.set('destination', data[0]);
                      setRegionEnd(data[0]);
                    }}
                    values={regionEnd || destination}
                  />
                </div>
              </div>
              <Button
                type="button"
                // option="secondary"
                className="tunnel-show"
                onClick={() => searchTunnel()}
              >
                <span className="lnr lnr-magnifier ml-1"></span>
              </Button>
            </div>
          </BlockedWrapper>
        )}
        {navigationsFields.indexOf('ftl') !== -1 && (
          <SelectBtnGroup wrapperClass="px-2 ">
            {/* label={t('label.fillType')} */}
            <Blocked className="zeroMargin" />
            {fillTypes.map((option, index) => (
              <ButtonGroupItem
                option={option}
                isActive={option.value === fillType}
                key={option.value}
                onHandleClick={() => {
                  onChangeOptions('fill', option.value);
                }}
              />
            ))}
          </SelectBtnGroup>
        )}
        {navigationsFields.indexOf('chartTypes') !== -1 && (
          <SelectBtnGroup wrapperClass="px-2 ">
            <Blocked className="zeroMargin" />
            {chartTypes.map(option => (
              <ButtonGroupItem
                option={option}
                isActive={option.value === chartType}
                key={option.value}
                onHandleClick={() => {
                  onChangeOptions('chartType', option.value);
                }}
              />
            ))}
          </SelectBtnGroup>
        )}
        {navigationsFields.indexOf('calc') !== -1 && (
          <SideSlidePanel openBtn={<span className="lnr lnr-pencil" />}>
            <Calculator value={originalValue} />
          </SideSlidePanel>
        )}
        {navigationsFields.indexOf('weekends') !== -1 && (
          <div className="btn-group-select px-2 ">
            <p className="mb-1 btn-group-select__label">{t('label.weekends')}</p>
            <IncludeWeekendsSwitch includeWeekendsFunc={onChangeOptions} />
          </div>
        )}
        <div>
          <p data-tip={t(`tooltips.${chartType}`)} data-class="tooltips_info" className="px-2 my-4 my-md-0">
            {t('tooltips.info')}
          </p>
          <ReactTooltip />
        </div>
      </Row>
    </div>
  );
};

ChartHeader.propTypes = {
  value: PropTypes.bool,
  onChangeOptions: PropTypes.func,
  switchHandle: PropTypes.func,
  companyName: PropTypes.string,
  activeChart: PropTypes.string,
  navigationsFields: PropTypes.arrayOf(PropTypes.string),
  onDateChange: PropTypes.func,
  date: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  originalValue: PropTypes.arrayOf(PropTypes.any),
  fillType: PropTypes.string,
};

export default ChartHeader;
