/* eslint-disable */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import SwitchBtn from '../../../components/form/SwitchBtn';
import Cookies from 'js-cookie';
import { ProfileContext } from '../../../context/Profile';

const LangSwitch = () => {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(i18next.language.slice(0, 2) === 'pl');
  const {
    profile: { language },
    profileUpdate,
  } = useContext(ProfileContext);
  const langName = lang ? 'en' : 'pl';

  return (
    <SwitchBtn
      onClick={() => {
        setLang(!lang);
        i18n.changeLanguage(langName.toLowerCase());
        Cookies.set('lang', langName);
        if (language !== undefined) {
          profileUpdate({ language: langName.toUpperCase() });
        }
      }}
      className="my-1 mx-0"
      checked={lang}
      testString="lang"
      label=""
      leftSide="EN"
      rightSide="PL"
    />
  );
};

LangSwitch.propTypes = {
  t: PropTypes.func,
};

export default LangSwitch;
