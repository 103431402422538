/* eslint-disable */
import React, { useContext } from 'react';
import { QueryRenderer } from 'react-relay';
import environment from 'relay-env';
import Cookies from 'js-cookie';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import WithChart from './ChartHOC';
import ChartQueryRender from './ChartQueryRender';
import getCountrySupplyTunnel, { getNutSupplyTunnel } from '../../relay/queries/Supply';
import getCountryDemand, { getNutDemand } from '../../relay/queries/Demand';
import getPriceCountry, { getPriceNuts } from '../../relay/queries/Price';
import { countryActivityQuery } from '../../relay/queries/country';
import { nutsActivityQuery } from '../../relay/queries/nuts';
import ChartHeader from './components/ChartHeader';
import getRegionName from '../../utils/getters';
import { ProfileContext } from '../../context/Profile';

const Chart = props => {
  const {
    departure,
    destination,
    date,
    includeWeekends,
    chartType,
    fillType,
    chartFunc: { dateConverter, onChangeOptions, onDateChange, onChangeTunnel, onChangeChartTitle, onChangeTunnelId, getSelectedRegions },
    region,
    chartDataName,
    regionType,
    periods,
    navigationsFields = ['date', 'tunnel', 'ftl', 'weekends'],
    maps = null,
  } = props;
  const { t, i18n } = useTranslation('common');
  const { profile } = useContext(ProfileContext);
  const getCountryName = cookie => {
    const translatedName = i18n.language === 'pl' ? cookie.namePL : cookie.nameEN;
    return translatedName !== undefined ? translatedName : cookie.name;
  };
  const getInitRegionData = cookieName => {
    const cookie = Cookies.get(cookieName);

    if (!cookie) {
      return cookieName === 'destination'
        ? {
            name: t('label.germany'),
            iso2: 'DE',
            type: 'country',
          }
        : {
            name: t('label.poland'),
            iso2: 'PL',
            type: 'country',
          };
    }
    const cookieObj = JSON.parse(cookie);
    const name = cookieObj.type === 'country' ? getCountryName(cookieObj) : cookieObj.name;

    return {
      ...cookieObj,
      name,
    };
  };

  const DEPARTURE_VALUES =
    profile.role === 'FREE'
      ? {
          name: t('label.europe'),
          iso2: 'EU',
          type: 'country',
        }
      : maps
      ? {
          name: getRegionName(maps.from, i18n.language),
          iso2: maps.from,
          type: maps.type,
        }
      : getInitRegionData('departure');
  const DESTINATION_VALUES =
    profile.role === 'FREE'
      ? {
          name: t('label.europe'),
          iso2: 'EU',
          type: 'country',
        }
      : maps
      ? {
          name: getRegionName(maps.to, i18n.language),
          iso2: maps.to,
          type: maps.type,
        }
      : getInitRegionData('destination');

  const INCLUDED_WEEKENDS = Cookies.get('includeWeekends') === 'true';
  const getRegionNameQuery = regionData => get(regionData, 'iso2', get(regionData, 'nuts', regionData));

  const getQuery = () => {
    const departureType = get(departure, 'type', get(DEPARTURE_VALUES, 'type', null));
    const destinationType = get(destination, 'type', get(DESTINATION_VALUES, 'type', null));

    if (region && regionType === 'country') {
      return countryActivityQuery; // for regions - country
    }
    if (region && regionType === 'nuts') {
      return nutsActivityQuery; // for regions - nuts
    }
    if (destinationType === departureType && departureType === 'nuts') {
      return chartType === 'price' ? getPriceNuts : chartType === 'supply' ? getNutSupplyTunnel : getNutDemand; // for nuts tunnels
    }

    return chartType === 'price' ? getPriceCountry : chartType === 'supply' ? getCountrySupplyTunnel : getCountryDemand; // for country tunnels
  };

  const getProps = renderProps => {
    if (renderProps) {
      return renderProps[Object.keys(renderProps || {})];
    }

    return null;
  };

  const variables = regionType
    ? {
        region: region,
        includeWeekends: INCLUDED_WEEKENDS || includeWeekends,
        periods: periods,
        direction: 'from',
      }
    : {
        gteDate: dateConverter(date[0]),
        lteDate: dateConverter(date[1]),
        departure: getRegionNameQuery(departure || DEPARTURE_VALUES),
        destination: getRegionNameQuery(destination || DESTINATION_VALUES),
        includeWeekends: INCLUDED_WEEKENDS || includeWeekends,
        periods: periods,
        visible: false,
      };
  return (
    <div className="dashboard">
      <ChartHeader
        title={chartDataName}
        onChangeTunnel={onChangeTunnel}
        onChangeTunnelId={onChangeTunnelId}
        switchHandle={e => onSwitchChange(e)}
        onChangeOptions={onChangeOptions}
        date={date}
        departure={DEPARTURE_VALUES}
        destination={DESTINATION_VALUES}
        onChangeChartTitle={onChangeChartTitle}
        onDateChange={(start, end) => onDateChange(start, end)}
        fillType={fillType}
        chartType={chartType}
        getSelectedRegions={getSelectedRegions}
        navigationsFields={navigationsFields}
      />
      <QueryRenderer
        environment={environment}
        query={getQuery()}
        variables={variables}
        render={result =>
          ChartQueryRender({
            qerror: result.error,
            data: getProps(result.props),
            compareInTooltip: true,
            chartDataName: chartType,
            chartOptions: [
              {
                chartName: `${chartType}${fillType}`,
                stroke: 'url(#colorUv)',
                strokeWidth: 3,
                dot: { stroke: '#70bbfd', strokeWidth: 3, fill: '#fff', fillOpacity: 1, r: 4 },
                activeDot: { stroke: '#70bbfd', strokeWidth: 3, fill: '#fff', r: 6 },
                fill: 'transparent',
              },
            ],
            ...props,
            ...props.chartFunc,
            t,
          })
        }
      />
    </div>
  );
};

export default WithChart(Chart);
