/**
 * @flow
 * @relayHash 8a3ddc64af4d0b06c02e93fc759644a5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SupplyCountryTunnelsQueryVariables = {|
  departure?: ?string,
  destination?: ?string,
  gteDate?: ?any,
  lteDate?: ?any,
  includeWeekends?: ?boolean,
  periods?: ?string,
|};
export type SupplyCountryTunnelsQueryResponse = {|
  +getActivityCountry: $ReadOnlyArray<{|
    +departure: string,
    +destination: string,
    +createDate: any,
    +supplyFtl: ?number,
    +supplyLtl: ?number,
    +supplyBus: ?number,
    +supplyLtlLag1: ?number,
    +supplyBusLag1: ?number,
    +supplyFtlLag1: ?number,
    +supplyFtlLag7: ?number,
    +supplyLtlLag7: ?number,
    +supplyBusLag7: ?number,
    +supplyLtlLag30: ?number,
    +supplyBusLag30: ?number,
    +supplyFtlLag30: ?number,
  |}>
|};
export type SupplyCountryTunnelsQuery = {|
  variables: SupplyCountryTunnelsQueryVariables,
  response: SupplyCountryTunnelsQueryResponse,
|};
*/


/*
query SupplyCountryTunnelsQuery(
  $departure: String
  $destination: String
  $gteDate: DateTime
  $lteDate: DateTime
  $includeWeekends: Boolean
  $periods: String
) {
  getActivityCountry(departure: $departure, destination: $destination, gteDate: $gteDate, lteDate: $lteDate, includeWeekends: $includeWeekends, periods: $periods) {
    departure
    destination
    createDate
    supplyFtl
    supplyLtl
    supplyBus
    supplyLtlLag1
    supplyBusLag1
    supplyFtlLag1
    supplyFtlLag7
    supplyLtlLag7
    supplyBusLag7
    supplyLtlLag30
    supplyBusLag30
    supplyFtlLag30
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "departure",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "destination",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "gteDate",
    "type": "DateTime",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "lteDate",
    "type": "DateTime",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeWeekends",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "periods",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getActivityCountry",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "departure",
        "variableName": "departure"
      },
      {
        "kind": "Variable",
        "name": "destination",
        "variableName": "destination"
      },
      {
        "kind": "Variable",
        "name": "gteDate",
        "variableName": "gteDate"
      },
      {
        "kind": "Variable",
        "name": "includeWeekends",
        "variableName": "includeWeekends"
      },
      {
        "kind": "Variable",
        "name": "lteDate",
        "variableName": "lteDate"
      },
      {
        "kind": "Variable",
        "name": "periods",
        "variableName": "periods"
      }
    ],
    "concreteType": "Activity",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "departure",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "destination",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "createDate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyFtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyLtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyBus",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyLtlLag1",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyBusLag1",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyFtlLag1",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyFtlLag7",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyLtlLag7",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyBusLag7",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyLtlLag30",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyBusLag30",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyFtlLag30",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SupplyCountryTunnelsQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SupplyCountryTunnelsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SupplyCountryTunnelsQuery",
    "id": null,
    "text": "query SupplyCountryTunnelsQuery(\n  $departure: String\n  $destination: String\n  $gteDate: DateTime\n  $lteDate: DateTime\n  $includeWeekends: Boolean\n  $periods: String\n) {\n  getActivityCountry(departure: $departure, destination: $destination, gteDate: $gteDate, lteDate: $lteDate, includeWeekends: $includeWeekends, periods: $periods) {\n    departure\n    destination\n    createDate\n    supplyFtl\n    supplyLtl\n    supplyBus\n    supplyLtlLag1\n    supplyBusLag1\n    supplyFtlLag1\n    supplyFtlLag7\n    supplyLtlLag7\n    supplyBusLag7\n    supplyLtlLag30\n    supplyBusLag30\n    supplyFtlLag30\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fd40efd48374d2e30e007119af40b404';
module.exports = node;
