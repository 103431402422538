/* eslint react/prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import convertDateFormat from '../../../utils/converters';

const CustomTooltip = ({ active, payload, tooltip, chartName, fillType, currency, t, periods }) => {
  const chartBaseName = chartName.substring(0, chartName.length - 3);
  const fuel = chartName === 'fuel';

  if (tooltip && active && payload.length > 0) {
    const { createDate } = payload[0].payload;
    const { date } = payload[0].payload;
    const current = payload[0].payload[`${chartBaseName}${fillType}`];

    // when we choose periods = months - the months value come in at Lag1 instead Lag30
    const interval = periods === 'months' ? ['Lag30', 'Lag30', 'Lag1'] : periods === 'weeks' ? ['Lag30', 'Lag1', 'Lag7'] : ['Lag1', 'Lag7', 'Lag30'];

    const dayAgo = payload[0].payload[`${chartBaseName}${fillType}${interval[0]}`];
    const weekAgo = payload[0].payload[`${chartBaseName}${fillType}${interval[1]}`];
    const monthAgo = payload[0].payload[`${chartBaseName}${fillType}${interval[2]}`];

    const dayAgoValue = current - dayAgo;
    const weekAgoValue = current - weekAgo;
    const monthAgoValue = current - monthAgo;

    const dayChangePercent = ((current - dayAgo) / Math.abs(dayAgo)) * 100;
    const weekChangePercent = ((current - weekAgo) / Math.abs(weekAgo)) * 100;
    const monthChangePercent = ((current - monthAgo) / Math.abs(monthAgo)) * 100;

    return (
      <div className="recharts_custom__tooltip">
        <div className="curr">
          <p>{`${t('label.date')}:`}</p>
          {`${convertDateFormat(fuel ? date : createDate)}`}
        </div>
        {payload.map(data => {
          const names = {
            MeanTransPrice: 'Current',
            MeanTransPrice_Index: 'Current',
            DemandIndex: 'Demand',
            SupplyIndex: 'Supply',
            MedianTransPrice: 'Median',
            BargainPrice: 'Bargain',
            GirtekaMedianPrice: 'Company',
            supplyBus: data.name,
            supplyFtl: data.name,
            supplyLtl: data.name,
            demandBus: data.name,
            demandFtl: data.name,
            demandLtl: data.name,
            priceBus: data.name,
            priceFtl: data.name,
            priceLtl: data.name,
            diesel: 'Diesel',
            gas95: 'Pb95',
            lpg: 'LPG',
            brent: 'Brent',
            opec: 'OPEC',
            wti: 'WTI',
          };
          const name = names[data.dataKey];
          return (
            // <p key={id} className="label">{`${translate(data.dataKey)}: ${data.value.toFixed(2)}`}</p>
            <div className="curr" key={name}>
              <p className={fillType === 'All' ? name : fillType}>{name}</p>
              {/* <span className={name === 'Demand' ? 'red' : 'blue'}>{data.value.toFixed(2)}</span> */}
              <span className={names[data.dataKey]}>
                {data.value.toFixed(currency || fuel ? 2 : 0)}
                {currency && <small> &euro;</small>}
              </span>
            </div>
          );
        })}
        {/* show comparison only if we have data - day ago */}
        {fillType !== 'All' && dayAgo > 0 && (
          <div className="diff">
            <p>{`${t('label.yesterday')}:`}</p>
            <span className={dayAgoValue > 0 ? 'plus' : 'minus'}>
              {dayChangePercent.toFixed(2)}
              {' %'}
            </span>
            {` (${dayAgo.toFixed(currency ? 2 : 0)})`}
          </div>
        )}
        {/* show comparison only if we have data - week ago */}
        {fillType !== 'All' && weekAgo > 0 && (
          <div className="diff">
            <p>{`${t('label.weekAgo')}:`}</p>
            <span className={weekAgoValue > 0 ? 'plus' : 'minus'}>
              {weekChangePercent.toFixed(2)}
              {' %'}
            </span>
            {` (${weekAgo.toFixed(currency ? 2 : 0)})`}
          </div>
        )}
        {/* show comparison only if we have data - month ago */}
        {fillType !== 'All' && monthAgo > 0 && (
          <div className="diff">
            <p>{`${t('label.monthAgo')}:`}</p>
            <span className={monthAgoValue > 0 ? 'plus' : 'minus'}>
              {monthChangePercent.toFixed(2)}
              {' %'}
            </span>
            {` (${monthAgo.toFixed(currency ? 2 : 0)})`}
          </div>
        )}
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
    })
  ),
  /* eslint-disable-next-line */
  chart: PropTypes.any,
  tooltip: PropTypes.bool,
  chartName: PropTypes.string,
  /* eslint-disable-next-line */
  fillType: PropTypes.any,
  currency: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  periods: PropTypes.string,
};

CustomTooltip.defaultProps = {
  active: false,
  payload: null,
  chart: null,
  tooltip: false,
  chartName: null,
};

export default withTranslation('common')(CustomTooltip);
