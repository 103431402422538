/* eslint-disable */
import React, { useEffect, createContext, useContext } from 'react';
import NotificationSystem from 'rc-notification';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { BasicNotification } from '../../components/notification/Notifications';

const notificationContext = createContext();

const useNotificationProvide = () => {
  const { t } = useTranslation('common');
  let notificationLU = null;
  let notificationRU = null;
  let notificationTC = null;

  useEffect(() => {
    NotificationSystem.newInstance({}, n => (notificationLU = n));
    NotificationSystem.newInstance({}, n => (notificationRU = n));
    NotificationSystem.newInstance({}, n => (notificationTC = n));
  }, []);

  const showNotification = ({ notification, position = 'left-up' }) => {
    switch (position) {
      case 'left-up':
        notificationLU.notice({
          content: notification,
          duration: 5,
          closable: true,
          style: { top: 0, left: 0 },
          className: position,
        });
        break;
      case 'right-up':
        notificationRU.notice({
          content: notification,
          duration: 5,
          closable: true,
          style: { top: 0, left: 'calc(100vw - 100%)' },
          className: position,
        });
        break;
      default:
        notificationTC.notice({
          content: notification,
          duration: 5,
          closable: true,
          style: { top: 0, left: 0 },
          className: position,
        });
        break;
    }
  };

  const show = (msg = '', title = t('msg.failed'), color = 'danger') =>
    showNotification({
      notification: <BasicNotification color={color} title={title} message={msg} />,
    });

  return {
    showNotification,
    show,
  };
};

export const ProvideNotification = ({ children }) => {
  const notification = useNotificationProvide();
  const { Provider } = notificationContext;
  return <Provider value={notification}>{children}</Provider>;
};

const useNotify = () => {
  return useContext(notificationContext);
};

ProvideNotification.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.any]),
};

export default useNotify;
