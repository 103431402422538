/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import CheckIcon from 'mdi-react/CheckIcon';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import classNames from 'classnames';
import Button from '../../form/Button';
import axiosInstance from '../../../utils/axios';
import getCurrencySymbol from '../../../utils/currency';
import createQueryString from '../../../utils/createQueryString';

// EUR roczny: 499 (przekreślone 708 EUR)
// EUR miesięczny: 59 (przekreślone 69 EUR)
// PLN roczny: 2199 (przekreślone 2988 zł)
// PLN miesięczny: 249 (przekreślone 299 zł)

// {
//   text: '350zł',
//   isStrike: true,
//   isGold: true,
//   isBold: true,
// },
// {
//   text: t('text.bestChoice'),
//   isEmpty: true,
// },

// eslint-disable-next-line no-unused-vars
const Packt = ({ params }) => {
  const [packtList, setPacktList] = useState([]);
  const { t } = useTranslation('common');
  const activeIndex = 1;
  const badges = {
    eur: {
      year: [
        {
          text: '708€',
          isStrike: true,
          isGold: true,
          isBold: true,
          isEmpty: false,
        },
        {
          text: t('text.bestChoice'),
          isEmpty: true,
        },
      ],
      month: [
        {
          text: '69€',
          isStrike: true,
          isGold: false,
          isBold: false,
          isEmpty: true,
        },
      ],
    },
    pln: {
      year: [
        {
          text: '2988zł',
          isStrike: true,
          isGold: true,
          isBold: true,
          isEmpty: false,
        },
        {
          text: t('text.bestChoice'),
          isEmpty: true,
        },
      ],
      month: [
        {
          text: '299zł',
          isStrike: true,
          isGold: false,
          isBold: false,
          isEmpty: true,
        },
      ],
    },
  };

  useEffect(() => {
    axiosInstance.get('payments/subscriptions').then(resp => {
      const subscriptions = get(resp, 'data.subscriptions', null);
      if (subscriptions === null) setPacktList(null);

      const subsList = Object.keys(subscriptions)
        .reduce((acc, item) => {
          return acc.concat({
            name: item,
            ...subscriptions[item],
          });
        }, [])
        .filter(product => product.productId !== 0 && product.productId !== null)
        .filter(product => product.currency === (i18next.language.slice(0, 2) === 'pl' ? 'PLN' : 'EUR'));
      setPacktList(subsList, subscriptions);
    });
  }, []);

  return (
    <div className="modal_payments modal_packt">
      <div className="modal_packt__left-side">
        <p className="modal_packt__title">{t('text.subscriptionTitle')}.</p>
        <div className="modal_packt__benefits mt-3">
          {t('text.whatYouGet')}:
          <div className="modal_packt__benefits-list">
            {[
              t('text.visualizationDemandSupply'),
              t('text.filterWeight'),
              t('text.filterDate'),
              t('text.usersAccess'),
              t('text.changeResolution'),
              t('text.filterClusters'),
            ].map(text => (
              <div className="modal_packt__benefits-list_item" key={text}>
                <CheckIcon className="modal_packt__benefits-list_item-icon" size={20} />
                {text}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="modal_packt__right-side">
        <div className="modal_packt__variant">
          <p className="modal_packt__variant-title">{t('text.selectSubscription')}</p>
          {packtList.map((product, index) => (
            <PacktOffer
              isActive={index === activeIndex}
              mainPrice={product.price_net}
              period={t(`packt.${product.period}`)}
              currency={product.currency}
              paymentMethodInfo={t(`packt.${product.period}paymentMethods`)}
              key={index}
              badges={badges[product.currency.toLowerCase()][product.period.toLowerCase()]}
            >
              <Link to={`#orderData?${createQueryString(product)}`}>
                <Button type="button" option={index === activeIndex ? 'primary' : 'secondary'} text="select" isUpperCase testid={product.period} />
              </Link>
            </PacktOffer>
          ))}
        </div>
      </div>
    </div>
  );
};

Packt.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.any,
};

const PacktOffer = ({ children, isActive = false, badges = [], mainPrice = 49, currency = 'PLN', addnotation = '', period, paymentMethodInfo = '' }) => {
  const tileClass = classNames('modal_packt__variant_tile', {
    active: isActive,
  });
  const { t } = useTranslation('common');
  let badgeClass = '';

  return (
    <div className={tileClass}>
      <div className="modal_packt__variant_tile-header">
        {badges.map(item => {
          badgeClass = classNames('modal_packt-badge', {
            gold: item.isGold,
            empty: item.isEmpty,
            strike: item.isStrike,
            bold: item.isBold,
          });

          return (
            <span key={item.text} className={badgeClass}>
              {item.text}
            </span>
          );
        })}
      </div>
      <div className="modal_packt__variant_tile-body ">
        <div className="modal_packt__variant_tile-price">
          <div className="modal_packt__variant_tile-price-main">
            <span className="text-nowrap">{`${mainPrice} ${getCurrencySymbol(currency)} ${t('label.net')}`}</span>
            <p className="modal_packt__variant_tile-price-desc">
              {period}
              <span>{addnotation}</span>
            </p>
          </div>

          {paymentMethodInfo.length > 0 && <p className="modal_packt__variant_tile-paymentsMethod">{paymentMethodInfo}</p>}
        </div>
        <div className="modal_packt__variant_tile-nav">{children}</div>
      </div>
    </div>
  );
};

PacktOffer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  isActive: PropTypes.bool,
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      isStrike: PropTypes.bool,
      isGold: PropTypes.bool,
    })
  ),
  mainPrice: PropTypes.number,
  addnotation: PropTypes.string,
  period: PropTypes.string,
};

export default Packt;
