import React from 'react';
import PropTypes from 'prop-types';

function EyeButton(props) {
  const { switchValue, switchHandle, name, testString = 'eye' } = props;

  return (
    // eslint-disable-next-line
    <div className="eye-button" onClick={() => switchHandle({ name })}>
      {/* {value}
      {currency && <small>&euro;</small>} */}
      <svg
        data-testid={`switch.${testString}.${name}`}
        name={name}
        width="15"
        height="15"
        viewBox="0 0 22 22"
        xmlns="http://www.w3.org/2000/svg"
        className={switchValue ? 'active' : 'inActive'}
      >
        {switchValue ? (
          <path
            d="M11 1c4.9 0 8.7 3.1 11 7-2.3 3.9-6.1 7-11 7S2.3 11.9 0 8c2.3-3.9 6.1-7 11-7zm0 10c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3zm0 2c4.3 0 7-2.7 8.6-5C18 5.7 15.3 3 11 3S4 5.7 2.4 8c1.6 2.3 4.3 5 8.6 5z"
            fillRule="nonzero"
          />
        ) : (
          <path
            d="M16.34 0l1.533 1.285-1.046 1.247-1.295 1.543-1.282 1.527-4.965 5.917-.873 1.04-1.378 1.642L5.66 15.84 4.13 14.555l1.042-1.242 1.303-1.553 1.278-1.522 4.966-5.918.874-1.042 1.37-1.635L16.34 0zM20 5.173c.197.22.387.445.572.675.52.653 1.003 1.345 1.428 2.072-.576.987-1.245 1.917-2 2.76-1.81 2.018-4.12 3.515-6.868 4.035-.685.13-1.393.205-2.13.205-1.035 0-2.013-.14-2.94-.388l1.45-1.726C10 13 10.48 13 11.002 13c1.838 0 3.382-.577 4.676-1.32 1.738-1 3.02-2.45 3.947-3.76-.116-.162-.253-.33-.378-.494-.745-.98-1.69-1.978-2.872-2.8l1.297-1.545c.848.604 1.625 1.31 2.328 2.093zM5.612 11.31l-1.285 1.45C3.48 12.16 2.7 11.46 2 10.68c-.198-.22-.39-.45-.575-.682C.902 9.344.425 8.648 0 7.92c.577-.986 1.246-1.917 2-2.758 1.81-2.02 4.122-3.516 6.87-4.036C9.552.996 10.26 1 11 1c.572 0 1.128-.04 1.668.04.407.06.806.14 1.194.242.027.007.05.017.077.025l-1.456 1.735c-.3-.046-.6-.087-.916-.106-.186-.01-.375-.016-.568-.016-1.84 0-3.385.498-4.68 1.243-1.735 1-4.06 3.59-3.944 3.757.117.166.24.334.37.503.745.98 1.687 2.07 2.866 2.887zm5.388.61c-.235 0-.463-.03-.687-.07l4.435-5.285c.154.424.252.876.252 1.355 0 2.21-1.79 4-4 4zm-4-4c0-2.21 1.79-4 4-4 .235 0 .463.03.687.07L7.252 9.274C7.098 8.85 7 8.4 7 7.92z"
            fillRule="evenodd"
          />
        )}
      </svg>
    </div>
  );
}

EyeButton.propTypes = {
  switchHandle: PropTypes.func,
  switchValue: PropTypes.bool,
  testString: PropTypes.string,
  name: PropTypes.string,
};

export default EyeButton;
