import React from 'react';
import PropTypes from 'prop-types';
import Divider from '../../../components/decorations/Divider';

const SectionWrapper = ({ children, title }) => (
  <div className="profile-body">
    <h4>{title}</h4>
    <Divider />
    {children}
  </div>
);

SectionWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default SectionWrapper;
