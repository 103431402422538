export const tmsList = [
  {
    label: 'ŁogieńTMS',
  },
  {
    label: 'Nie dotyczy',
  },
  {
    label: 'Własne rozwiązanie',
  },
];

export const companySize = [
  {
    label: '1-10',
  },
  {
    label: '10-50',
  },
  {
    label: '50-100',
  },
  {
    label: '100-500',
  },
  {
    label: 'Ponad 500',
  },
];

export const positionList = [
  {
    label: 'Załadowca',
  },
  {
    label: 'Przewoźnik',
  },
  {
    label: 'Spedytor',
  },
  {
    label: 'Operator logistyczny',
  },
  {
    label: 'Media',
  },
  {
    label: 'Inny',
  },
];
