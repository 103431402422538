/* eslint-disable  */
import React, { Component } from 'react';
import { AreaChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, Area, YAxis, Legend, CartesianGrid } from 'recharts';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';
import get from 'lodash.get';
import { withTranslation } from 'react-i18next';
import CustomTooltip from './CustomTooltip';
import { convertDateWithoutYear } from '../../../utils/converters';
import { fillTypes, timePrecision } from '../utils/config';
import translate from '../../../utils/dictionary/translate';
import getStatsName from '../utils/getStatsName';
import gradients from './Recharts/gradients';
import NoData from '../utils/NoData';
import { BlockedWrapper } from '../../../components/form/Blocked';
import SelectBtnGroup, { ButtonGroupItem } from '../../../components/form/SelectBtnGroup';

const referenceLines = {
  showLineAvg: [
    {
      y: 'average',
      label: { position: 'top', value: 'Avg', fill: '#70bbfd', fontSize: 14 },
      stroke: '#70bbfd',
    },
  ],
  showLineMin: [
    {
      y: 'min',
      label: { position: 'top', value: 'Min', fill: '#70bbfd', fontSize: 14 },
      stroke: '#70bbfd',
    },
  ],
  showLineMax: [
    {
      y: 'max',
      label: { position: 'top', value: 'Max', fill: '#70bbfd', fontSize: 14 },
      stroke: '#70bbfd',
    },
  ],
  showLineMed: [
    {
      y: 'median',
      label: { position: 'top', value: 'Med', fill: '#70bbfd', fontSize: 14 },
      stroke: '#70bbfd',
    },
  ],
};

class ChartBody extends Component {
  state = {
    labels: [],
    tooltip: false,
  };

  componentDidMount = () => {
    this.setLabels();
  };

  setLabels = () => {
    const { chartOptions } = this.props;
    const labelsList = chartOptions.reduce((acc, chart) => acc.concat(chart.chartName), []);
    this.setState({
      labels: labelsList,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.fillType !== this.props.fillType) {
      this.setLabels();
    }
  };
  selectDataToView = e => {
    const { labels } = this.state;
    const newLabelsList = labels.includes(e.dataKey) ? labels.filter(label => label !== e.dataKey) : labels.concat(e.dataKey);
    this.setState({
      labels: newLabelsList,
    });
  };

  gradientOffset = (data, chartDataName) => {
    const dataMax = Math.max(...data.map(i => i[chartDataName]));
    const dataMin = Math.min(...data.map(i => i[chartDataName]));

    if (dataMax <= 0) {
      return 0;
    } else if (dataMin >= 0) {
      return 1;
    } else {
      return dataMax / (dataMax - dataMin);
    }
  };

  activateTooltip = () => {
    this.setState({
      tooltip: true,
    });
  };

  deactivateTooltip = () => {
    this.setState({
      tooltip: false,
    });
  };

  // eslint-disable-next-line
  render() {
    const {
      chartOptions,
      data,
      trend,
      fillType,
      companyName = null,
      addChartNameSuffix = '',
      // chartTitle = '',
      compareInTooltip,
      currency,
      setPeriods,
      periods,
      t,
    } = this.props;
    const { labels, tooltip } = this.state;
    const activeSwitchArr = Object.keys(this.props).filter(prop => this.props[prop] === true);
    const off = this.gradientOffset(data, chartOptions[0].chartName);
    return (
      <Col className="chart">
        <Card>
          <CardBody>
            <div className="d-flex justify-content-md-end justify-content-center flex-direction-row  align-items-end mb-3">
              {/* <div className="card__title mb-0">
                <h5 className="bold-text">{`${from} -> ${to}`}</h5>
              </div> */}
              <BlockedWrapper childClass="mx-3 mt-1" linkClass="d-flex align-items-end" className="h-auto">
                <SelectBtnGroup>
                  {/* label={t('label.timePeriods')} */}
                  {timePrecision.map(option => (
                    <ButtonGroupItem
                      option={option}
                      isActive={option.value === periods}
                      key={option.value}
                      onHandleClick={() => {
                        setPeriods(option.value);
                      }}
                    />
                  ))}
                </SelectBtnGroup>
              </BlockedWrapper>
            </div>
            <div style={{ position: 'realtive' }}>
              {data.length === 0 && <NoData />}
              <ResponsiveContainer height={600}>
                <AreaChart data={data}>
                  {gradients(off)}
                  <Legend
                    verticalAlign="bottom"
                    height={36}
                    wrapperStyle={{ fontFamily: 'Roboto, sans-serif', color: '#999' }}
                    onClick={e => this.selectDataToView(e)}
                    formatter={value => translate(value)}
                  />
                  <XAxis dataKey="createDate" tickFormatter={convertDateWithoutYear} padding={{ left: 0, right: 20 }} />
                  <YAxis padding={{ bottom: 0, top: 10 }} domain={['auto', 'auto']} />
                  <CartesianGrid />

                  {chartOptions.map(chart => {
                    const Name = chart.chartName.substring(0, chart.chartName.length - 3);
                    const BaseName = t(`label.${Name}`);
                    const Type = chart.chartName.substring(chart.chartName.length - 3);
                    const BaseType = t(
                      get(
                        fillTypes.filter(item => item.value === Type),
                        '[0].label',
                        Type
                      )
                    );

                    return (
                      <Area
                        type="linear"
                        name={`${BaseName} ${BaseType}`}
                        key={chart.chartName}
                        dataKey={getStatsName(chart.chartName, fillType) + addChartNameSuffix}
                        stroke={labels.includes(chart.chartName) ? chart.stroke || 'url(#colorUv)' : 'transparent'}
                        strokeWidth={chart.strokeWidth || 5}
                        dot={labels.includes(chart.chartName) ? chart.dot || { stroke: 'url(#colorUv)' } : { stroke: 'transparent' }}
                        activeDot={
                          labels.includes(chart.chartName)
                            ? { ...chart.activeDot, onMouseOver: this.activateTooltip, onMouseLeave: this.deactivateTooltip } || { stroke: 'url(#colorUv)' }
                            : { stroke: 'transparent' }
                        }
                        fill={labels.includes(chart.chartName) ? chart.fill || 'transparent' : 'transparent'}
                      />
                    );
                  })}

                  {trend && companyName && <Area type="monotone" dataKey="DemandIndex_FTL" stroke="url(#green)" strokeWidth="5" fill="transparent" />}
                  {trend && <ReferenceLine y={0} label={{ position: 'top', value: '', fill: '#CCC', fontSize: 14 }} stroke="#CCC" strokeWidth={1} />}

                  {activeSwitchArr.map(item =>
                    (referenceLines[item] || []).map(line => (
                      <ReferenceLine
                        key={line.y}
                        y={this.props[line.y]}
                        label={line.label}
                        stroke={line.stroke}
                        strokeDasharray={line.strokeDasharray || '4 3'}
                        strokeWidth={line.strokeWidth || 3}
                      />
                    ))
                  )}
                  <Tooltip
                    isAnimationActive={false}
                    content={
                      <CustomTooltip
                        tooltip={tooltip}
                        chartName={chartOptions[0].chartName + addChartNameSuffix}
                        fillType={fillType}
                        currency={currency}
                        periods={periods}
                      />
                    }
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

ChartBody.propTypes = {
  data: PropTypes.any,
  trend: PropTypes.bool,
  // chartTitle: PropTypes.string,
  addChartNameSuffix: PropTypes.string,
  fillType: PropTypes.oneOf(['Ftl', 'Ltl', 'Bus']),
  chartOptions: PropTypes.arrayOf(
    PropTypes.shape({
      chartName: PropTypes.string.isRequired,
      stroke: PropTypes.string,
      strokeWidth: PropTypes.number,
      dot: PropTypes.shape({
        stroke: PropTypes.string,
        strokeWidth: PropTypes.number,
        fill: PropTypes.string,
        fillOpacity: PropTypes.number,
        r: PropTypes.number,
      }),
      activeDot: PropTypes.shape({
        stroke: PropTypes.string,
        strokeWidth: PropTypes.number,
        fill: PropTypes.string,
        fillOpacity: PropTypes.number,
        r: PropTypes.number,
      }),
      fill: PropTypes.string,
    })
  ),
  companyName: PropTypes.string,
  compareInTooltip: PropTypes.bool,
  currency: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  setPeriods: PropTypes.func,
  periods: PropTypes.string,
};

// const referenceLines = {
//   avgSwitchValueFTL: [
//     {
//       y: 'average',
//       label: { position: 'top', value: 'Avg', fill: '#70bbfd', fontSize: 14 },
//       stroke: '#70bbfd',
//     },
//   ],
//   avgSwitchValueLTL: [
//     {
//       y: 'average',
//       label: { position: 'top', value: 'Avg', fill: '#f30', fontSize: 14 },
//       stroke: '#f30',
//     },
//   ],
//   avgSwitchValueBUS: [
//     {
//       y: 'average',
//       label: { position: 'top', value: 'Avg', fill: '#f30', fontSize: 14 },
//       stroke: '#f30',
//     },
//   ],
//   medSwitchValueFTL: [
//     {
//       y: 'median',
//       label: { position: 'top', value: 'Median', fill: '#70bbfd', fontSize: 14 },
//       stroke: '#70bbfd',
//     },
//   ],
//   medSwitchValueLTL: [
//     {
//       y: 'median',
//       label: { position: 'top', value: 'Median', fill: '#f30', fontSize: 14 },
//       stroke: '#f30',
//     },
//   ],
//   medSwitchValueBUS: [
//     {
//       y: 'median',
//       label: { position: 'top', value: 'Median', fill: '#f30', fontSize: 14 },
//       stroke: '#f30',
//     },
//   ],
//   maxSwitchValueFTL: [
//     {
//       y: 'max',
//       label: { position: 'bottom', value: 'Max', fill: '#70bbfd', fontSize: 14 },
//       stroke: '#70bbfd',
//     },
//   ],
//   maxSwitchValueLTL: [
//     {
//       y: 'max',
//       label: { position: 'bottom', value: 'Max', fill: '#f30', fontSize: 14 },
//       stroke: '#f30',
//     },
//   ],
//   maxSwitchValueBUS: [
//     {
//       y: 'max',
//       label: { position: 'bottom', value: 'Max', fill: '#f30', fontSize: 14 },
//       stroke: '#f30',
//     },
//   ],
//   minSwitchValueFTL: [
//     {
//       y: 'min',
//       label: { position: 'bottom', value: 'Min', fill: '#70bbfd', fontSize: 14 },
//       stroke: '#70bbfd',
//     },
//   ],
//   minSwitchValueLTL: [
//     {
//       y: 'min',
//       label: { position: 'bottom', value: 'Min', fill: '#f30', fontSize: 14 },
//       stroke: '#f30',
//     },
//   ],
//   minSwitchValueBUS: [
//     {
//       y: 'min',
//       label: { position: 'bottom', value: 'Min', fill: '#f30', fontSize: 14 },
//       stroke: '#f30',
//     },
//   ],
// };

export default withTranslation('common')(ChartBody);
