import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, FormGroup, Label } from 'reactstrap';

export default class Calculator extends Component {
  state = {
    courseCount: 1,
  };

  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.any),
  };

  getSumPrices = (value, name) => value.reduce((acc, val) => acc + val[name], 0) / value.length;

  calculateSpendMoney = (value, name, count = 1) => (this.getSumPrices(value, name) * count).toFixed(2);

  render() {
    const { value } = this.props;
    const { courseCount } = this.state;
    const bargarianSumPrices = this.calculateSpendMoney(value, 'BargainPrice', courseCount);
    const transSumPrices = this.calculateSpendMoney(value, 'MedianTransPrice', courseCount);
    const girtekaSumPrices = this.calculateSpendMoney(value, 'GirtekaMedianPrice', courseCount);
    const companyCost = (girtekaSumPrices - bargarianSumPrices).toFixed(2);
    return (
      <div className="calculator">
        <h1>Calculator</h1>
        <FormGroup className="form">
          <Label for="courseCount">Number of freights:</Label>
          <Input
            type="number"
            name="courseCount"
            id="courseCount"
            placeholder="1"
            defaultValue={1}
            value={courseCount}
            className="w-100"
            min={1}
            onChange={e => this.setState({ courseCount: e.target.value })}
          />
        </FormGroup>
        <div className="calculator__row">
          <p>Distance of single freight (km):</p>
          <span>{value[0].MedianDistance}</span>
          km
        </div>

        <div className="calculator__row">
          <p>Total cost (bargain price):</p>
          <span>{bargarianSumPrices}</span>
        </div>
        <div className="calculator__row">
          <p>Total cost (normal price):</p>
          <span>{transSumPrices}</span>
        </div>
        <div className="calculator__row">
          <p>Your Cost:</p>
          <span>{girtekaSumPrices}</span>
        </div>
        <div className="calculator__row">
          <p>Cost Difference:</p>
          <span className={companyCost < 0 ? 'good' : 'bad'}>{companyCost}</span>
        </div>
      </div>
    );
  }
}
