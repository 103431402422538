/* eslint-disable */
import React, { useState, useContext } from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SidebarTab from './components/SidebarTab';
import User from './components/User';
import MenuIcon from 'mdi-react/MenuIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import intersection from 'lodash.intersection';
import { ProfileContext } from '../../../context/Profile';
// import DateModal from '../../../components/modals/containers/DateModal';
// import DateInput from '../../../components/form/DateInput';

const Sidebar = ({ sidebarShow, sidebarCollapse, sideList }) => {
  const [open, setOpen] = useState(false);
  const sidebarClass = classNames({
    sidebar: true,
    open: open,
    'sidebar--show': sidebarShow,
    'sidebar--collapse': sidebarCollapse,
  });
  const { t } = useTranslation('common');
  const {
    profile: { role },
  } = useContext(ProfileContext);

  //intersection([role], option.visibleFor).length > 0 ?

  return (
    <div className={sidebarClass}>
      <button type="button" className="sidebar-switch" onClick={() => setOpen(!open)}>
        {open ? <CloseIcon size={16} /> : <MenuIcon size={16} />}
      </button>
      <Scrollbar className="sidebar__scroll scroll">
        {sideList.map((menuItem, id) =>
          intersection([role], menuItem.visibleFor).length > 0 ? <SidebarTab name={t(menuItem.label)} link={menuItem.link} key={menuItem.link} id={id} /> : null
        )}
      </Scrollbar>
      <User handleMenuOpen={() => setOpen(false)} />
    </div>
  );
};

Sidebar.propTypes = {
  sidebarShow: PropTypes.bool.isRequired,
  sidebarCollapse: PropTypes.bool.isRequired,
  sideList: PropTypes.arrayOf(PropTypes.object),
};

export default Sidebar;
