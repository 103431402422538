/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import LangSelector from '../../Layout/components/langSelector';
// import LogInForm from './components/LogInForm';
// import RegisterForm from './components/RegisterForm';
import TransINFO from '../../../shared/img/transinfo';
import TransEU from '../../../shared/img/transeu_white.png';

const MainWrapper = ({ children, title = '', option = 'login', t }) => (
  <div className={'account ' + option}>
    <div className="global-lang-switch">
      <LangSelector customClass="login-lang" />
    </div>
    <div className="account__card">
      <div className="account__header">
        <Link className="account__logo" to="/dashboard" />
        {option !== 'login' && (
          <p className="account__header--text">
            {t('dialogs.alreadyMember')}? <Link to="/log_in">{t('button.log_in')}</Link>
          </p>
        )}
      </div>
      <div className="account__card--body">
        <h2 className="account__card--title">{title}</h2>
        {children}
      </div>
    </div>
    <div className="account__description">
      <p>{t('dialogs.frequentUpdates')}</p>
      <div className="powered">
        <span>Powered by:</span>
        <a href="https://trans.info" title="TransINFO" target="_blank" rel="noopener noreferrer">
          <TransINFO color="white" />
        </a>
        <a href="https://trans.eu" title="trans.eu" target="_blank" rel="noopener noreferrer">
          <img src={TransEU} alt="trans.eu" />
        </a>
      </div>
    </div>
  </div>
);

export default withTranslation('common')(MainWrapper);

MainWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  title: PropTypes.string,
  option: PropTypes.oneOf(['register', 'login', 'PasswordChange', 'passwordReset']),
  t: PropTypes.func,
};

// ? (
//   <p className="account__header--text">
//     {t('dialogs.noAccount')}? <Link to="/register">{t('button.register')}</Link>
//   </p>
// ) :
