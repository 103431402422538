/* eslint-disable */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
// import EyeIcon from 'mdi-react/EyeIcon';
// import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Button from '../../../components/form/Button';
import Input from '../../../components/form/Input';
// import CheckBox from '../../../shared/components/form/CheckBox';
import getFormInputsData from '../../../utils/form';
import withAuth from '../../Auth/AuthHOC';

class LogInForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    login: PropTypes.func,
  };

  signin = e => {
    const { login } = this.props;
    e.preventDefault();
    const { email, password } = getFormInputsData(e);
    login(email, password);
  };

  render() {
    const { t } = this.props;

    return (
      <form className="form form-group input-big" id="loginform" onSubmit={e => this.signin(e)}>
        <Input className="w-100 my-2" name="email" type="text" label="E-mail" />
        <div className="form__input--password">
          <Link to="/forgot_password" className="form__input--password-forget">
            {t('user.forgot_password')}
          </Link>
          <Input className="w-100 my-2" name="password" type="password" label={t('user.password')} />
        </div>
        <div className="form__footer mt-3">
          <Button option="primary" text="log_in" isUpperCase className="font-size-16" />
        </div>
        <p className="mt-4 text-center w-100">
          {t('dialogs.noAccount')}? <Link to="/register">{t('button.register')}</Link>
        </p>
        <p className="mt-2 text-center w-100 bold-text">{t('label.registerInfo')}</p>
      </form>
    );
  }
}

export default withTranslation('common')(withAuth(LogInForm));
