/* eslint-disable */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ButtonGroup from './atoms/ButtonGroup';
import Blocked from './Blocked';
import { ProfileContext } from '../../context/Profile';

const SelectBtnGroup = ({ label = '', wrapperClass = '', btnGroupClass = 'bar', children }) => (
  <div className={`btn-group-select ${wrapperClass}`}>
    <p className={`btn-group-select__label ${label.lenght === 0 ? 'd-none' : ''}`}>{label}</p>
    <ButtonGroup btnGroupClass={btnGroupClass}>{children}</ButtonGroup>
  </div>
);

export const ButtonGroupItem = ({ option, testString = 'btn', isActive, onHandleClick, isBlocked }) => {
  const { t } = useTranslation('common');
  const { profile } = useContext(ProfileContext);

  return (
    <button
      type="button"
      data-testid={`${testString}.${option.value}`}
      value={option.value}
      className={`${isActive ? 'active' : 'inactive'}`}
      key={option.value}
      onClick={profile.isBlocked && isBlocked ? () => {} : onHandleClick}
    >
      {t(option.label)}
      {isBlocked && <Blocked className="zeroMargin" />}
    </button>
  );
};

SelectBtnGroup.propTypes = {
  label: PropTypes.string,
  wrapperClass: PropTypes.string,
  btnGroupClass: PropTypes.string,
  // children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
};

SelectBtnGroup.defaultProps = {
  label: '',
  wrapperClass: '',
  btnGroupClass: 'bar',
};

ButtonGroupItem.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  }).isRequired,
  testString: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  isBlocked: PropTypes.bool,
  onHandleClick: PropTypes.func.isRequired,
  // groupTestString: PropTypes.string,
};

export default SelectBtnGroup;
