import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ButtonGroup = ({ children, isRounded = true, btnGroupClass }) => {
  const buttonGroupClass = classNames(btnGroupClass, {
    'button-group': true,
    rounded: isRounded,
  });
  return <div className={buttonGroupClass}>{children}</div>;
};

ButtonGroup.propTypes = {
  // children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  isRounded: PropTypes.bool,
  btnGroupClass: PropTypes.string,
};

export default ButtonGroup;
