import React from 'react';
import PropTypes from 'prop-types';

const TransINFO = props => {
  const { color } = props;

  const colors = {
    white: ['#FFF', '#FFF'],
    black: ['#000', '#000'],
    color: ['#395778', '#f08559'],
  };

  return (
    <svg
      // height={auto}
      width={90}
      xmlSpace="preserve"
      viewBox="0 0 477.79999 73.900002"
      y="0px"
      x="0px"
      id="Warstwa_1"
      {...props}
    >
      <style id="style2" type="text/css">
        {`.st0{fill:${colors[color][0]}}.st1{fill:${colors[color][1]}}`}
      </style>
      <g transform="translate(-154.6 -95.1)" id="g14">
        <path
          id="path4"
          d="M185.3 167.8c-16.1-.1-20.6-4.7-20.6-21.5v-31.1H156s-1.4 0-1.4-1.3V110c0-1.4 1.5-1.2 1.5-1.2h8.6V96.4s-.1-1.3 1.3-1.3h4.4c1.6 0 1.5 1.2 1.5 1.2v12.5h13.3s1.5-.1 1.5 1.2v3.9c0 1.5-1.4 1.3-1.4 1.3h-13.4v30.9c0 12.2 2.2 14.8 13.4 15 .4 0 1.5.2 1.5 1.2v4.1c0 1.6-1.4 1.4-1.5 1.4z"
          className="st0"
          fill="#395778"
        />
        <path
          id="path6"
          d="M194.6 166.4v-56.3s0-1.3 1.4-1.3h4.2c1.4 0 1.4 1.3 1.4 1.3v9.4c3.7-6.9 10.3-10.7 18.9-10.7h4.3s1.4 0 1.4 1.2v4.1c0 1.6-1.3 1.4-1.3 1.4h-4.4c-12.3 0-18.8 7.5-18.8 21.6v29.2s.2 1.6-1.5 1.6h-4.1c-1.8-.1-1.5-1.5-1.5-1.5z"
          className="st0"
          fill="#395778"
        />
        <path
          id="path8"
          d="M255.9 169c-17 0-30.3-13.5-30.3-30.7s13.3-30.7 30.3-30.7c9.5 0 18.8 5.1 23.4 12.8V110s-.1-1.3 1.5-1.3h4.2c1.4 0 1.4 1.2 1.4 1.2v56.3s0 1.4-1.4 1.4h-4.2c-1.5 0-1.5-1.3-1.5-1.3V156c-4.6 7.9-13.9 13-23.4 13zm.3-54.8c-13 0-23.6 10.8-23.6 24.1s10.6 24.1 23.6 24.1c13.3 0 23.8-10.6 23.8-24.1 0-13.5-10.4-24.1-23.8-24.1z"
          className="st0"
          fill="#395778"
        />
        <path
          id="path10"
          d="M342.9 166.6v-31.8c0-12.7-7.6-20.6-19.8-20.6-11.8 0-20.6 8.9-20.6 20.8v31.5s0 1.4-1.5 1.4h-3.9c-1.8 0-1.6-1.4-1.6-1.4v-56.2s-.2-1.4 1.4-1.4h3.9c1.5 0 1.5 1.3 1.5 1.3v9.1c4.4-7.3 12.4-11.6 21.6-11.6 7.8 0 14.4 2.5 19 7.3 4.7 4.8 7.1 11.7 7.1 19.9v31.7s.2 1.3-1.5 1.3h-4.2c-1.5 0-1.4-1.3-1.4-1.3z"
          className="st0"
          fill="#395778"
        />
        <path
          id="path12"
          d="M379.6 169c-9.2 0-17.7-3.8-23-10.3-.2-.2-.7-1 .1-1.6.8-.6 3.2-2.4 3.9-3.1.7-.7 1.4 0 1.6.2 4.4 5.4 10.7 8.5 17.6 8.5 10.3 0 14.9-5.8 14.9-11.5 0-6.7-7-8.3-15-10.3-.5-.1-1-.3-1.6-.4-10.2-2.6-19.4-5.7-19.4-16.5 0-4.4 2-8.5 5.6-11.5 3.8-3.2 9.1-4.9 15.1-4.9 8.2 0 15.6 2.9 20.5 8 .2.2.6 1 0 1.5-.5.4-2.9 2.3-3.8 3.1-.8.7-1.5 0-1.7-.3-4-4.1-8.9-6.1-15-6.1-8.2 0-13.7 4.1-13.7 10.2 0 6.6 7.1 8.3 15.4 10.4l.7.2c8.4 2.1 19.8 4.9 19.8 16.7 0 4.4-1.9 8.7-5.3 11.9-3.9 3.7-9.7 5.8-16.7 5.8z"
          className="st0"
          fill="#395778"
        />
      </g>
      <path id="path18" d="M280.8 9.3h15.7v15.9h-15.7zm0 23.1h15.7v41.5h-15.7z" className="st1" fill="#f08559" />
      <path id="rect20" className="st1" fill="#f08559" d="M256.19998 57.299995H271.89997999999997V73.199995H256.19998z" />
      <path id="path22" d="M305.8 9.3h16.4l23.3 36.8V9.3h15.3v64.6h-15.3l-24.4-38.6v38.6h-15.4V9.3z" className="st0" fill="#395778" />
      <path id="path24" d="M370.8 9.3h36.9v14.3h-21.5v10.6h21.5v14.3h-21.5v25.4h-15.4z" className="st0" fill="#395778" />
      <path
        id="path26"
        d="M413.5 41.6c0-17.8 14.2-32.3 32.1-32.3 17.9 0 32.2 14.5 32.2 32.3 0 17.9-14.1 32.3-32.2 32.3-17.9 0-32.1-14.4-32.1-32.3zm49.2 0c0-9.6-7.4-17.6-17.1-17.6-9.7 0-16.9 7.9-16.9 17.6 0 9.5 7.1 17.5 16.9 17.5 9.9 0 17.1-7.9 17.1-17.5z"
        className="st0"
        fill="#395778"
      />
    </svg>
  );
};

TransINFO.propTypes = {
  color: PropTypes.string,
};

export default TransINFO;
