/* eslint-disable */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/form/Button';
import Slick from '../../components/decorations/Slick';
import gt1 from '../../shared/img/get-started/gt1.gif';
import gt2 from '../../shared/img/get-started/gt2.gif';
import gt3 from '../../shared/img/get-started/gt3.gif';
import gt4 from '../../shared/img/get-started/gt4.gif';

const GetStarted = () => {
  const history = useHistory();
  const { t } = useTranslation('common');
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    customPaging: i => <div className="custom-dot" data-testid={`dot-${i}`}></div>,
  };

  return (
    <div className="get-started">
      <div className="get-started__wrapper">
        <button type="button" className="get-started__close" onClick={() => history.push('/pages/chart/price')}>
          <span className="lnr lnr-cross"></span>
        </button>
        <h2 className="text-center ">{t('dialogs.welcome')}</h2>
        <div className="get-started__slick">
          <Slick settings={settings}>
            {[gt1, gt2, gt3, gt4].map((url, index) => (
              <SlideItem img={url} title={t(`getStarted.title${index + 1}`)} text={t(`getStarted.text${index + 1}`)} />
            ))}
          </Slick>
        </div>
        <div className="get-started__controller">
          <Button onClick={() => history.push('/pages/chart/price')} text="skip" />
        </div>
      </div>
    </div>
  );
};

const SlideItem = ({ img = '', title = 'title', text = 'text' }) => (
  <div className="get-started__slick--item">
    <img src={img} alt="" className="slide-photo" />
    <div className="slide-footer">
      <p className="slide-title">{title}</p>
      <p className="slide-text">{text}</p>
    </div>
  </div>
);

const SampleNextArrow = ({ onClick, currentSlide, slideCount }) => (
  <div onClick={onClick} className={`slick-arrow__custom next ${currentSlide === slideCount - 1 ? 'disabled' : ''}`}>
    <span className="lnr lnr-chevron-right"></span>
  </div>
);
const SamplePrevArrow = ({ onClick, currentSlide }) => (
  <div onClick={onClick} className={`slick-arrow__custom prev ${currentSlide === 0 ? 'disabled' : ''}`}>
    <span className="lnr lnr-chevron-left"></span>
  </div>
);

export default GetStarted;
