/* eslint-disable */
import React from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import EyeButton from '../../../components/form/EyeButton';

export const IndicatorBoxHeader = ({ title, children }) => (
  <div className="d-flex justify-content-between">
    <div className="indicator_box-header">{title}</div>
    {children}
  </div>
);
const IndicatorBox = ({ title, children, currency, switchHandle, data }) => (
  <div className={`indicator_box ${title.toLowerCase()} data-size-${data.length}`}>
    {children}
    <div className="d-flex justify-content-between">
      {data.map(({ switchName, value, status, color }, index) => (
        <div
          key={index}
          className={`box box-${index}`}
          style={{
            color: color,
          }}
        >
          {value || 0}
          {/* {currency && <small>&euro;</small>} */}
          {currency && <small>{currency}</small>}
          <EyeButton name={switchName} switchHandle={switchHandle} switchValue={status} />
        </div>
      ))}
    </div>
  </div>
);

IndicatorBox.propTypes = {
  title: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      switchName: PropTypes.string,
      color: PropTypes.string,
      value: PropTypes.number.isRequired,
      status: PropTypes.bool,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  currency: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

IndicatorBoxHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

// @@@@@ data props example :
// data={[
//   {
//     switchName: "medSwitchValueFTL",
//     value: medianFTL,
//     status: medSwitchValueFTL
//   }, {
//     switchName: "medSwitchValueLTL",
//     value: medianLTL,
//     status: medSwitchValueLTL
//   }
// ]}

export default IndicatorBox;
