/* eslint-disable */
import React, { PureComponent } from 'react';
// import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Joi from 'joi-browser';
import Button from '../../../components/form/Button';
import Input from '../../../components/form/Input';
// import CheckBox from '../../../shared/components/form/CheckBox';
import getFormInputsData from '../../../utils/form';
import axiosInstance from '../../../utils/axios';
import withNotificationHOC from '../../../components/notification/NotificationsHOC';
import { BasicNotification } from '../../../components/notification/Notifications';
import passwordSchema from '../../../utils/JoiSchema/schemas';

class ForgotPasswordForm extends PureComponent {
  state = {
    passwordTooShort: false,
  };
  static propTypes = {
    t: PropTypes.func,
    handleShowNotification: PropTypes.func,
  };

  passwordChange = e => {
    e.preventDefault();
    const {
      t,
      handleShowNotification,
      location: { search },
    } = this.props;
    const code = search.split('=')[1];
    const valid = Joi.validate({ ...getFormInputsData(e) }, passwordSchema);

    valid
      .then(resp =>
        axiosInstance
          .post('/users/reset/password', {
            password: resp.password,
            code,
          })
          .then(() => {
            handleShowNotification({
              notification: <BasicNotification type="success" title={t('label.passwordReset')} message={t('msg.passwordChanged')} />,
              position: 'left-up',
            });
            setTimeout(() => {
              window.location.href = '/';
            }, 2000);
          })
      )
      .catch(() => {
        handleShowNotification({
          notification: <BasicNotification color="danger" title={t('msg.failed')} message={t('user.confirmPassword')} />,
          position: 'left-up',
        });
      });
  };

  render() {
    const { t } = this.props;
    const { passwordTooShort } = this.state;

    return (
      <form className="form form-group input-big" id="forgotPassword" onSubmit={e => this.passwordChange(e)}>
        <Input
          className="w-100"
          required
          isButtonShow={false}
          name="password"
          type="password"
          label={t('user.password')}
          helper={t('validation.helper.password')}
          error={passwordTooShort}
          onInputChange={e => {
            e.target.value.length < 8 ? this.setState({ passwordTooShort: true }) : this.setState({ passwordTooShort: false });
          }}
        />
        <Input className="w-100" required isButtonShow={false} name="confirmPassword" type="password" label={t('user.confirmPassword')} />

        <div className="form__footer mt-3">
          <Button option="primary" text="send" isUpperCase className="font-size-16" />
        </div>
      </form>
    );
  }
}

export default withTranslation('common')(withRouter(withNotificationHOC(ForgotPasswordForm)));
