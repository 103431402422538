/* eslint-disable */
import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Layout from '../Layout/index';
import { MainWrapper } from './MainWrapper';
import ContainerWrapper from '../Layout/components/ContainerWrapper';
import TermsOfService from '../StaticPages/TermsOfService';
import PrivacyPolicy from '../StaticPages/PrivacyPolicy';
import LogIn from '../LogIn/Login';
import Beta from '../LogIn/Beta';
import Register from '../LogIn/Register';
import ForgotPassword from '../LogIn/ForgotPassword';
import PasswordChange from '../LogIn/PasswordChange';
import Activate from '../LogIn/Activate';
import NewAccount from '../LogIn/NewAccount';
import LeafletMapWrapper from '../Maps/Leaflet';
import Currency from '../Currency/Currency';
import Profile from '../User/Profile';
import GetStarted from '../../containers/GetStarted';
// import Search from '../../components/search/index';
// import MapboxWrapper from '../Maps/MapboxWrapper';
// import ClusterHeatMap from '../Maps/components/mapbox/ClusterHeatMap';
import FreeSupply from '../FreeSupply';
import FreeDemand from '../FreeDemand';
import FreePrice from '../FreePrice';
import Chart from '../Charts/index';
import UserRole from '../../enums/User';
import FuelPrice from '../FuelPrice';

export const SideMenuList = [
  {
    label: 'label.rates',
    shortlabel: 'label.price',
    link: '/pages/chart/price',
    route: '/pages/chart/:chartType',
    component: Chart,
    visibleFor: [UserRole.FREE, UserRole.PRO, UserRole.PRO_HEAD, UserRole.ADMIN, UserRole.SUPER_ADMIN],
  },
  {
    label: 'label.supplyIndex',
    shortlabel: 'label.supply',
    link: '/pages/chart/supply',
    route: '/pages/chart/:chartType',
    component: Chart,
    visibleFor: [UserRole.FREE, UserRole.PRO, UserRole.PRO_HEAD, UserRole.ADMIN, UserRole.SUPER_ADMIN],
  },
  {
    label: 'label.demandIndex',
    shortlabel: 'label.demand',
    link: '/pages/chart/demand',
    route: '/pages/chart/:chartType',
    component: Chart,
    visibleFor: [UserRole.FREE, UserRole.PRO, UserRole.PRO_HEAD, UserRole.ADMIN, UserRole.SUPER_ADMIN],
  },
  {
    label: 'label.demandMap',
    shortlabel: 'label.map',
    link: '/pages/map',
    component: LeafletMapWrapper,
    visibleFor: [UserRole.FREE, UserRole.PRO, UserRole.PRO_HEAD, UserRole.ADMIN, UserRole.SUPER_ADMIN],
  },
  {
    label: 'label.fuelPrices',
    shortlabel: 'label.fuelPrices',
    link: '/fuel',
    // component: LeafletMapWrapper,
    visibleFor: [UserRole.FREE, UserRole.PRO, UserRole.PRO_HEAD, UserRole.ADMIN, UserRole.SUPER_ADMIN],
  },
  {
    label: 'label.currencyName',
    shortlabel: 'label.currency',
    link: '/currency',
    component: LeafletMapWrapper,
    visibleFor: [UserRole.FREE, UserRole.PRO, UserRole.PRO_HEAD, UserRole.ADMIN, UserRole.SUPER_ADMIN],
  },
  {
    label: 'label.subscribe',
    shortlabel: 'label.subscribeShort',
    link: '#packt',
    visibleFor: [UserRole.FREE],
  },
];

const Pages = () => (
  <Switch>
    {SideMenuList.map(route => (
      <Route path={route.route || route.link} component={route.component} key={route.link} />
    ))}
    {/* <Route path="/pages/:option(five|six)" component={LeafletMapWrapper} /> */}
  </Switch>
);

const CurrencyRoute = () => (
  <Switch>
    <Route path="/currency/:currency?" component={Currency} />
  </Switch>
);

// eslint-disable-next-line react/prop-types
const wrappedRoutes = ({ location }) => {
  const bodyClass = classNames({
    'container__wrap-body': true,
    // eslint-disable-next-line react/prop-types
    allRounded: location.pathname !== SideMenuList[0].link,
  });

  return (
    <div>
      <Layout sideList={SideMenuList} />
      <ContainerWrapper bodyClass={bodyClass}>
        <Route exact path="/" component={Chart} />
        <Route path="/dashboard" component={Chart} />
        <Route path="/pages" component={Pages} />
        <Route path="/fuel" component={FuelPrice} />
        <Route path="/currency" component={CurrencyRoute} />
        <Route path="/profile" component={Profile} />
        <Route path="/terms-of-service" component={TermsOfService} />
      </ContainerWrapper>
    </div>
  );
};

const noProtectionWrapped = () => (
  <div className="container__wrap withoutSidebar">
    <div className="container__wrap-body allRounded ">
      <Route path="/p/terms-of-service" component={TermsOfService} />
      <Route path="/p/privace-policy" component={PrivacyPolicy} />
    </div>
  </div>
);

const Router = ({ isAuth = false }) => (
  <MainWrapper>
    <main>
      <Switch>
        <Route path="/p" component={noProtectionWrapped} />
        <Route path="/get-started" component={GetStarted} />
        <Route exact path="/" component={isAuth() ? wrappedRoutes : Beta} />
        <Route exact path="/log_in" component={LogIn} />
        <Route exact path="/beta" component={LogIn} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/forgot_password" component={ForgotPassword} />
        <Route path="/reset/password" component={PasswordChange} />
        <Route path="/users/activate/pro" component={NewAccount} />
        <Route path="/users/activate" component={Activate} />
        <Route path="/" component={isAuth() ? wrappedRoutes : Beta} />
      </Switch>
    </main>
  </MainWrapper>
);

Router.propTypes = {
  isAuth: PropTypes.func,
};

export default withRouter(Router);
