import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import Avatar from '../../../../components/user/avatar/Avatar';
// import LangSwitch from '../../components/LangSwitch';
// import avatar from '../../../../shared/img/avatar.png';
import { ProfileContext } from '../../../../context/Profile';
import withAuthHOC from '../../../Auth/AuthHOC';
import { version } from '../../../../../package.json';

const User = ({ logout, handleMenuOpen = () => {} }) => {
  const { t } = useTranslation('common');
  const {
    profile: { email },
  } = useContext(ProfileContext);
  return (
    <div className="sidebar__user">
      <Link to="/profile/company" onClick={() => handleMenuOpen()}>
        <div className="sidebar__user-avatar" data-testid="user.profile">
          <Avatar email={email} />
          <p className="sidebar__user-avatar-name">{t('user.profile')}</p>
        </div>
      </Link>
      {/* <LangSwitch /> */}

      <button type="button" className="sidebar__user-signout mb-1" data-testid="sidebar.logout" onClick={logout}>
        {t('user.log_out')}
        <ArrowRightIcon size="14" />
      </button>

      <div className="sidebar_footer">
        {t('contact.company')}
        <br />
        {t('contact.street')}
        <br />
        {t('contact.address')}
        <br />
        KRS: 0000751609
        <br />
        {t('contact.nip')}
        : 8961581580
        <br />
        REGON: 381491570
        <br />
        +48 71 733 36 97
        <br />
        redakcja@trans.info
        <br />
        <br />
        <a href={t('urls.privacyPolicy')} target="_blank" rel="nofollow noopener noreferrer">
          {t('label.privacyPolicy')}
        </a>
        <br />
        <Link to="/terms-of-service">{t('label.termsOfService')}</Link>
        <br />
        {`© 2010-2021 ${t('label.rightsReserved')}`}
        {/* display version from package.json */}
        <p className="version">{version || '0'}</p>
      </div>
    </div>
  );
};

User.propTypes = {
  logout: PropTypes.func,
  handleMenuOpen: PropTypes.func,
};

export default withAuthHOC(User);
