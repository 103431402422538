/* eslint-disable */
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Joi from 'joi-browser';
// import LangSelector from '../../Layout/components/langSelector';
import Divider from '../../../components/decorations/Divider';
import Button from '../../../components/form/Button';
import Input from '../../../components/form/Input';
import passwordSchema from '../../../utils/JoiSchema/schemas';
import withNotificationHOC from '../../../components/notification/NotificationsHOC';
import { BasicNotification } from '../../../components/notification/Notifications';
import getFormInputsData from '../../../utils/form';
import LangSwitch from '../../Layout/components/LangSwitch';
// import JoiTranslateMsg from '../../../utils/joiValidatorMsg';
import { ProfileContext } from '../../../context/Profile';

const Account = ({ handleShowNotification }) => {
  const { t } = useTranslation('common');
  const { profileUpdate } = useContext(ProfileContext);
  const [passwordTooShort, setpasswordTooShort] = useState(false);

  const passwordChange = e => {
    e.preventDefault();
    const valid = Joi.validate({ ...getFormInputsData(e) }, passwordSchema);

    valid
      .then(resp => profileUpdate({ password: resp.password }))
      .catch(() =>
        handleShowNotification({
          notification: <BasicNotification color="danger" title={t('msg.failed')} message={t('user.confirmPassword')} />,
          position: 'left-up',
        })
      );
  };

  return (
    <div className="account-tab">
      {t('label.languageSelect')}:
      <LangSwitch />
      <Divider />
      <form className="form-group form-grid input-big ml-1" id="newPassword" onSubmit={e => passwordChange(e)}>
        <Input
          className="w-100"
          required
          isButtonShow={false}
          name="password"
          type="password"
          label={t('user.newPassword')}
          helper={t('validation.helper.password')}
          error={passwordTooShort}
          onInputChange={e => {
            e.target.value.length < 8 ? setpasswordTooShort(true) : setpasswordTooShort(false);
          }}
        />
        <Input className="w-100" required isButtonShow={false} name="confirmPassword" type="password" label={t('user.confirmPassword')} />

        <div className="form__footer mt-3">
          <Button option="primary" text="passwordChage" isUpperCase className="font-size-16" />
        </div>
      </form>
      <Divider />
      <Link to="#deleteAccount" className="ml-1">
        {t('user.removeAccount')}
      </Link>
    </div>
  );
};

export default withNotificationHOC(Account);
