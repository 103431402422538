import { graphql } from 'react-relay';

const getNutsActivity = graphql`
  query nutsAllDynamicsQuery($direction: String!, $date: DateTime) {
    getNutsActivity(direction: $direction, date: $date) {
      departure
      destination
      createDate
      supplyFtl
      supplyLtl
      supplyBus
      priceFtl
      priceLtl
      priceBus
      demandLtl
      demandFtl
      demandBus
      nuts {
        nuts
        latinName
        localName
        namePL
        nameEN
      }
    }
  }
`;

export const getNutActivity = graphql`
  query nutsDynamicsQuery($includeWeekends: Boolean, $periods: String, $direction: String!, $region: String!) {
    getNutActivity(includeWeekends: $includeWeekends, periods: $periods, direction: $direction, region: $region) {
      region
      supplyPercentChangeLtl
      supplyPercentChangeFtl
      supplyPercentChangeBus
      demandPercentChangeLtl
      demandPercentChangeFtl
      demandPercentChangeBus
    }
  }
`;

export const nutsActivityQuery = graphql`
  query nutsActivityQuery($region: String!, $periodsLimit: Float, $direction: String!) {
    getLatestNutActivity(region: $region, periodsLimit: $periodsLimit, direction: $direction) {
      createDate
      supplyLtl
      supplyFtl
      supplyBus
      demandLtl
      demandFtl
      demandBus
      priceFtl
      priceLtl
      priceBus
    }
  }
`;

export default getNutsActivity;
