/**
 * @flow
 * @relayHash 83061412489b0384b084ffedf294c2a0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type countryAllDynamicsQueryVariables = {|
  direction: string,
  date?: ?any,
|};
export type countryAllDynamicsQueryResponse = {|
  +getCountriesActivity: $ReadOnlyArray<{|
    +departure: string,
    +destination: string,
    +createDate: any,
    +supplyFtl: ?number,
    +supplyLtl: ?number,
    +supplyBus: ?number,
    +priceFtl: ?number,
    +priceLtl: ?number,
    +priceBus: ?number,
    +demandLtl: ?number,
    +demandFtl: ?number,
    +demandBus: ?number,
  |}>
|};
export type countryAllDynamicsQuery = {|
  variables: countryAllDynamicsQueryVariables,
  response: countryAllDynamicsQueryResponse,
|};
*/


/*
query countryAllDynamicsQuery(
  $direction: String!
  $date: DateTime
) {
  getCountriesActivity(direction: $direction, date: $date) {
    departure
    destination
    createDate
    supplyFtl
    supplyLtl
    supplyBus
    priceFtl
    priceLtl
    priceBus
    demandLtl
    demandFtl
    demandBus
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "direction",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "date",
    "type": "DateTime",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getCountriesActivity",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "date",
        "variableName": "date"
      },
      {
        "kind": "Variable",
        "name": "direction",
        "variableName": "direction"
      }
    ],
    "concreteType": "Activity",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "departure",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "destination",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "createDate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyFtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyLtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyBus",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "priceFtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "priceLtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "priceBus",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandLtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandFtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandBus",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "countryAllDynamicsQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "countryAllDynamicsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "countryAllDynamicsQuery",
    "id": null,
    "text": "query countryAllDynamicsQuery(\n  $direction: String!\n  $date: DateTime\n) {\n  getCountriesActivity(direction: $direction, date: $date) {\n    departure\n    destination\n    createDate\n    supplyFtl\n    supplyLtl\n    supplyBus\n    priceFtl\n    priceLtl\n    priceBus\n    demandLtl\n    demandFtl\n    demandBus\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8d9defa3f2e0a3b13047f1de79db4a94';
module.exports = node;
