export const fuelTypes = [
  {
    label: 'Diesel',
    value: 'diesel',
  },
  {
    label: 'Pb95',
    value: 'gas95',
  },
  {
    label: 'LPG',
    value: 'lpg',
  },
];

export const oilSources = [
  {
    label: 'Brent',
    value: 'brent',
  },
  {
    label: 'OPEC',
    value: 'opec',
  },
  {
    label: 'WTI',
    value: 'wti',
  },
];

//   export default fuelTypes;
