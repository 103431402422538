/* eslint-disable */
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { langs } from '../../../utils/translations/resources';
import Dropdown from '../../../components/form/Dropdown';
import { ProfileContext } from '../../../context/Profile';

const LangSelector = ({ customClass }) => {
  const { i18n } = useTranslation();
  const [active, setActive] = useState(Object.keys(langs).indexOf(i18next.language.slice(0, 2)));
  const {
    profile: { language },
    profileUpdate,
  } = useContext(ProfileContext);

  const list = Object.entries(langs).reduce(
    (acc, lang) =>
      acc.concat({
        label: lang[1],
        other: lang[0],
      }),
    []
  );

  return (
    <Dropdown
      mainClass={customClass}
      active={active}
      list={list}
      withActive
      testName="lang"
      onSelect={number => {
        i18n.changeLanguage(list[number].other);
        setActive(number);
        Cookies.set('lang', list[number].other);
        if (language !== undefined) {
          profileUpdate({ language: list[number].other.toUpperCase() });
        }
      }}
    />
  );
};

LangSelector.propTypes = {
  customClass: PropTypes.string,
};

export default LangSelector;
