/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unused-state */

import React, { useState } from 'react';
// import moment from 'moment';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DateGrid from '../../form/DateGrid';

const DateBox = ({ onClose, initStart = new Date('2019-01-01'), initEnd = new Date(), lang, t, type }) => {
  const [start, setStart] = useState(initStart);
  const [end, setEnd] = useState(initEnd);
  const minDate = type === 'currency' ? new Date('1999-01-01') : type === 'fuel' ? new Date('2018-01-01') : new Date('2019-01-01');

  return (
    <div className="date-container">
      <div className="date-container__calendars--header">
        <p>{t('label.starDate')}</p>
        <p>{t('label.endDate')}</p>
      </div>
      <div className="date-container__calendars">
        <p className="data-name">{t('label.starDate')}</p>
        <DateGrid onSelect={date => setStart(date)} selected={start} lang={lang} minDate={minDate} />

        <p className="data-name">{t('label.endDate')}</p>
        <DateGrid onSelect={date => setEnd(date)} selected={end} lang={lang} minDate={minDate} />
      </div>
      <div className="date-container__nav">
        <button type="button" className="btn-link mr-2" color="link" onClick={() => onClose(start, end)}>
          {t('label.cancale').toUpperCase()}
        </button>
        <button type="button" className="btn-primary" onClick={() => onClose(start, end, true)}>
          {t('label.accept').toUpperCase()}
        </button>
      </div>
    </div>
  );
};

DateBox.propTypes = {
  onClose: PropTypes.func,
  initStart: PropTypes.instanceOf(Date),
  initEnd: PropTypes.instanceOf(Date),
  lang: PropTypes.string,
  t: PropTypes.func,
  type: PropTypes.string,
};

export default withTranslation('common')(DateBox);
