/* eslint-disable */
import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import findIndex from 'lodash.findindex';
import Dropdown from '../../../components/form/Dropdown';
import countries from '../../../utils/json/countries.json';

const CountrySelector = ({ customClass, label, handleActiveCountry, defaultCountry }) => {
  const [active, setActive] = useState(0);
  const [countriesList, setCountriesList] = useState([]);

  useEffect(() => {
    const defaultCountryIndex = findIndex(countries, { iso2: defaultCountry }) !== -1 ? findIndex(countries, { iso2: defaultCountry }) : 0;

    setCountriesList(
      countries.reduce(
        (acc, lang) =>
          acc.concat({
            label: get(lang, `name${i18next.language.slice(0, 2).toUpperCase()}`, ''),
            other: lang.short,
            iso2: lang.iso2,
          }),
        []
      )
    );
    setActive(defaultCountryIndex);

    handleActiveCountry(
      get(countries, `[${defaultCountryIndex}].name${i18next.language.slice(0, 2).toUpperCase()}`, ''),
      get(countries, `[${defaultCountryIndex}].iso2`, '')
    );
  }, [i18next.language]);

  return (
    <Dropdown
      mainClass={customClass}
      active={active}
      list={countriesList}
      label={label}
      testName="country"
      onSelect={number => {
        handleActiveCountry(countriesList[number].label, countriesList[number].iso2);
        setActive(number);
      }}
    />
  );
};

CountrySelector.propTypes = {
  customClass: PropTypes.string,
  defaultCountry: PropTypes.string,
  label: PropTypes.string,
  handleActiveCountry: PropTypes.func,
};

export default CountrySelector;
