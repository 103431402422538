/* eslint-disable no-restricted-syntax */
const getFormInputsData = (e, withEmpty = true) => {
  const form = e.target;
  const data = new FormData(form);
  const inputs = {};
  for (const name of data.keys()) {
    const input = form.elements[name];
    if (withEmpty || input.value.length !== 0) {
      inputs[name] = input.value;
    }
  }
  return inputs;
};

export default getFormInputsData;
