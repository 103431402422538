/* eslint-disable */
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import FullSizeModal from '../../components/modals/FullSizeModal';
import Packt from '../../components/modals/payments/Packt';
import OrderData from '../../components/modals/payments/OrderData';
import AfterOrder from '../../components/modals/payments/AfterOrder';
import Empty from '../../components/modals/payments/Empty';
import AddUser from '../../components/modals/AddUser';
import WelcomeForm from '../../components/modals/WelcomeForm';
import DeleteAccount from '../../components/modals/DeleteAccount';
import MapNav from '../../components/modals/MapNav';
import withAuthHOC from '../../containers/Auth/AuthHOC';

const locationHashParse = hash => {
  const separateIndex = hash.indexOf('?');
  const mainHash = separateIndex === -1 ? hash.slice(1) : hash.slice(1, separateIndex);
  const params = hash
    .slice(separateIndex + 1)
    .split('&')
    .reduce(
      (acc, param) => ({
        ...acc,
        [param.split('=')[0]]: param.split('=')[1],
      }),
      {}
    );

  return { mainHash, params };
};

const popupList = {
  packt: {
    component: Packt,
    isForLogged: true,
    withCloseBtn: true,
    popupClass: 'packt',
  },
  orderData: {
    component: OrderData,
    isForLogged: true,
    withCloseBtn: true,
  },
  afterOrder: {
    component: AfterOrder,
    isForLogged: true,
    withCloseBtn: false,
  },
  empty: {
    component: Empty,
    isForLogged: true,
    withCloseBtn: true,
  },
  addUser: {
    component: AddUser,
    isForLogged: true,
    withCloseBtn: true,
  },
  welcome: {
    component: WelcomeForm,
    isForLogged: true,
    withCloseBtn: false,
  },
  deleteAccount: {
    component: DeleteAccount,
    isForLogged: true,
    withCloseBtn: true,
  },
  mapNav: {
    component: MapNav,
    isForLogged: true,
    withCloseBtn: true,
    popupClass: 'map-nav',
  },
};

const Popup = ({ children, auth: { isAuth } }) => {
  const { mainHash, params } = locationHashParse(useLocation().hash);
  const history = useHistory();
  if (!popupList.hasOwnProperty(mainHash) && !!mainHash) {
    history.replace(history.location.pathname);
    return children;
  }
  const Component = popupList.hasOwnProperty(mainHash) ? (popupList[mainHash] || {}).component : popupList.empty.component;
  const isOpen = popupList.hasOwnProperty(mainHash) && ((popupList[mainHash] || {}).isForLogged ? isAuth() : false);

  return (
    <React.Fragment>
      <FullSizeModal
        isOpen={isOpen}
        onClose={() => history.replace(history.location.pathname)}
        withClose={(popupList[mainHash] || {}).withCloseBtn}
        popupClass={(popupList[mainHash] || {}).popupClass}
      >
        <Component params={params} />
      </FullSizeModal>
      {children}
    </React.Fragment>
  );
};

export default withAuthHOC(Popup);
