/* eslint-disable */
import React from 'react';
import { Row } from 'reactstrap';
// import IndicatorBox, { IndicatorBoxHeader } from './IndicatorBox';
// import EyeButton from '../../../components/form/EyeButton';

const ChartIndicators = ({ activeChart, children }) => {
  return <div className={`indicator_wrapper px-2 ${activeChart} boxes-${children.length || 1}`}>{children}</div>;
};

export default ChartIndicators;
