// import React from 'react'
/* eslint-disable */
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import RegulationsPL from './atoms/RegulationsPL';
import RegulationsEN from './atoms/RegulationsEN';

export default () => {
  const { i18n } = useTranslation('common');
  return i18n.language === 'pl' ? <RegulationsPL /> : <RegulationsEN />;
  // <div className="text-page">
  //   <p className="title">
  //     {t('terms.title')}
  //     {/* <Trans i18nKey="common:terms.testUrl">
  //       testUrl <a href="https://trans.info/metrix/">https://trans.info/metrix/</a>
  //     </Trans> */}
  //   </p>
  //   <p className="sub-title">{t('terms.subtitle')}</p>
  //   <ol>
  //     <li>
  //       {t('terms.part1')}
  //       <ol>
  //         <li>{t('terms.part2')}</li>
  //         <li>{t('terms.part3')}</li>
  //       </ol>
  //     </li>
  //     <li>
  //       {t('terms.part4')}
  //       <ol>
  //         <li>
  //           <Trans i18nKey="common:terms.part5">
  //             <span className="bold">Account</span>
  //           </Trans>
  //         </li>
  //         <li>
  //           <Trans i18nKey="common:terms.part6">
  //             <span className="bold">Metrix</span> x <a href="https://trans.info/metrix/">https://trans.info/metrix/</a>
  //           </Trans>
  //         </li>
  //         <li>
  //           <Trans i18nKey="common:terms.part7">
  //             <span className="bold">Usługodawca/Administrator</span> x <a href="https://trans.info/metrix/">https://trans.info/metrix/</a>
  //           </Trans>{' '}
  //         </li>
  //         <li>
  //           <Trans i18nKey="common:terms.part8">
  //             <span className="bold">Polityka Prywatności</span> x
  //             <a href={t('urls.privacyPolicy')} target="_blank" rel="nofollow noopener noreferrer">
  //               https://trans.info/pl/page/polityka-prywatnosci
  //             </a>
  //           </Trans>
  //         </li>
  //         <li>
  //           <Trans i18nKey="common:terms.part9">
  //             <span className="bold">Użytkownik</span>
  //           </Trans>
  //         </li>
  //         <li>
  //           <Trans i18nKey="common:terms.part10">
  //             <span className="bold">Regulamin</span>
  //           </Trans>
  //         </li>
  //       </ol>
  //     </li>
  //     <li>
  //       {t('terms.part11')}
  //       <ol>
  //         <li>{t('terms.part12')}</li>
  //         <li>{t('terms.part13')}</li>
  //         <li>{t('terms.part14')}</li>
  //         <li>{t('terms.part15')}</li>
  //         <li>{t('terms.part16')}</li>
  //       </ol>
  //     </li>
  //     <li>
  //       {t('terms.part17')}
  //       <ol>
  //         <li>
  //           {t('terms.part18')}
  //           <ol>
  //             <li>
  //               {t('terms.part19')}
  //               <ol>
  //                 <li>{t('terms.part20')}</li>
  //                 <li>{t('terms.part21')}</li>
  //                 <li>{t('terms.part22')}</li>
  //                 <li>{t('terms.part23')}</li>
  //                 <li>{t('terms.part24')}</li>
  //                 <li>{t('terms.part25')}</li>
  //                 <li>{t('terms.part26')}</li>
  //                 <li>{t('terms.part27')}</li>
  //               </ol>
  //               {t('terms.part28')}
  //             </li>

  //             <li>{t('terms.part29')}</li>
  //             <li>{t('terms.part30')}</li>
  //           </ol>
  //         </li>
  //         <li>{t('terms.part31')}</li>
  //         <li>
  //           {t('terms.part32')}
  //           <ol>
  //             <li>{t('terms.part33')}</li>
  //             <li>{t('terms.part34')}</li>
  //             <li>{t('terms.part35')}</li>
  //             <li>{t('terms.part36')}</li>
  //           </ol>
  //         </li>
  //         <li>{t('terms.part37')} </li>
  //         <li>{t('terms.part38')}</li>
  //         <li>{t('terms.part39')}</li>
  //         <li>{t('terms.part40')}</li>
  //         <li>{t('terms.part41')}</li>
  //         <li>{t('terms.part42')}</li>
  //         <li>{t('terms.part43')}</li>
  //         <li>
  //           {t('terms.part44')}
  //           <ol>
  //             <li>{t('terms.part45')}</li>
  //             <li>{t('terms.part46')}</li>
  //             <li>{t('terms.part47')}</li>
  //           </ol>
  //         </li>
  //       </ol>
  //     </li>
  //     <li>
  //       {t('terms.part48')}
  //       <ol>
  //         <li>{t('terms.part49')}</li>
  //         <li>{t('terms.part50')}</li>
  //         <li>{t('terms.part51')}</li>
  //       </ol>
  //     </li>
  //     <li>
  //       {t('terms.part52')}
  //       <ol>
  //         <li>{t('terms.part53')}</li>
  //         <li>{t('terms.part54')}</li>
  //         <li>{t('terms.part55')}</li>
  //       </ol>
  //     </li>
  //     <li>
  //       {t('terms.part56')}
  //       <ol>
  //         <li>{t('terms.part57')}</li>
  //         <li>{t('terms.part58')}</li>
  //         <li>{t('terms.part59')}</li>
  //         <li>{t('terms.part60')}</li>
  //         <li>{t('terms.part61')}</li>
  //         <li>{t('terms.part62')}</li>
  //       </ol>
  //     </li>
  //     <li>
  //       {t('terms.part63')}
  //       <ol>
  //         <li>{t('terms.part64')}</li>
  //         <li>{t('terms.part65')}</li>
  //         <li>{t('terms.part66')}</li>
  //       </ol>
  //     </li>
  //     <li>
  //       {t('terms.part67')}
  //       <ol>
  //         <li>{t('terms.part68')}</li>
  //         <li>{t('terms.part69')}</li>
  //         <li>{t('terms.part70')} </li>
  //         <li>{t('terms.part71')}</li>
  //         <li>{t('terms.part72')}</li>
  //         <li>
  //           {t('terms.part73')}
  //           <ol>
  //             <li>{t('terms.part74')}</li>
  //             <li>{t('terms.part75')}</li>
  //             <li>{t('terms.part76')}</li>
  //             <li>{t('terms.part77')}</li>
  //             <li>{t('terms.part78')}</li>
  //             <li>{t('terms.part79')}</li>
  //             <li>{t('terms.part80')}</li>
  //             <li>{t('terms.part81')}</li>
  //           </ol>
  //         </li>
  //         <li>{t('terms.part82')}</li>
  //       </ol>
  //     </li>
  //     <li>
  //       {t('terms.part83')}
  //       <ol>
  //         <li>
  //           <li>{t('terms.part84')}</li>
  //           <ol>
  //             <li>{t('terms.part85')}</li>
  //             <li>{t('terms.part86')}</li>
  //             <li>{t('terms.part87')}</li>
  //           </ol>
  //         </li>
  //         <li>{t('terms.part88')}</li>
  //         <li>{t('terms.part89')} </li>
  //         <li>{t('terms.part90')}</li>
  //       </ol>
  //     </li>
  //     <li>
  //       {t('terms.part91')}
  //       <ol>
  //         <li>{t('terms.part92')}</li>
  //         <li>
  //           {t('terms.part93')}
  //           <ol>
  //             <li>{t('terms.part94')}</li>
  //             <li>{t('terms.part95')}</li>
  //             <li>{t('terms.part96')}</li>
  //             <li>{t('terms.part97')}</li>
  //           </ol>
  //           {t('terms.part98')}
  //         </li>
  //         <li>{t('terms.part99')} </li>
  //         <li>
  //           {t('terms.part100')}
  //           <ol>
  //             <li>{t('terms.part101')}</li>
  //             <li>{t('terms.part102')}</li>
  //             <li>{t('terms.part103')}</li>
  //           </ol>
  //         </li>
  //         <li>
  //           {t('terms.part104')}
  //           <ol>
  //             <li>{t('terms.part105')}</li>
  //             <li>{t('terms.part105')}</li>
  //             <li>{t('terms.part106')}</li>
  //             <li>{t('terms.part107')}</li>
  //             <li>
  //               {t('terms.part108')}
  //               <ol>
  //                 <li>{t('terms.part109')}</li>
  //               </ol>
  //             </li>
  //           </ol>
  //           {t('terms.part110')}
  //         </li>
  //         <li>{t('terms.part111')}</li>
  //         <li>
  //           <Trans i18nKey="common:terms.part112">
  //             x
  //             <a href={t('urls.privacyPolicy')} target="_blank" rel="nofollow noopener noreferrer">
  //               https://trans.info/pl/page/polityka-prywatnosci
  //             </a>
  //           </Trans>
  //         </li>
  //       </ol>
  //     </li>
  //     <li>
  //       {t('terms.part113')}
  //       <ol>
  //         <li>{t('terms.part114')} </li>
  //         <li>{t('terms.part115')}</li>
  //         <li>{t('terms.part116')}</li>
  //       </ol>
  //     </li>
  //     <li>
  //       {t('terms.part117')}
  //       <ol>
  //         <li>
  //           {t('terms.part118')}
  //           <ol>
  //             <li>{t('terms.part119')}</li>
  //             <li>
  //               {t('terms.part120')}
  //               <ol>
  //                 <li>{t('terms.part121')} </li>
  //                 {t('terms.part122')}
  //                 <li>{t('terms.part123')}</li>
  //               </ol>
  //             </li>
  //             <li>{t('terms.part124')}</li>
  //             <li>{t('terms.part125')}</li>
  //           </ol>
  //         </li>
  //         <li>{t('terms.part126')}</li>
  //         <li>
  //           <Trans i18nKey="common:terms.part127">
  //             x <a href="https://trans.info/metrix/">https://trans.info/metrix/</a>
  //           </Trans>
  //         </li>
  //       </ol>
  //     </li>
  //   </ol>
  // </div>
  // );
};
