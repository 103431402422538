import { graphql } from 'react-relay';

export const getGlobal = graphql`
  query FuelPriceQuery($gteDate: DateTime!, $lteDate: DateTime!) {
    getGlobalPrices(gteDate: $gteDate, lteDate: $lteDate) {
      date
      currency
      wti
      opec
      brent
      eurRate
    }
  }
`;

export const getCountry = graphql`
  query FuelPriceMutation($gteDate: DateTime!, $lteDate: DateTime!, $country: String!) {
    getCountryPrices(gteDate: $gteDate, lteDate: $lteDate, country: $country) {
      country
      date
      currency
      gas95
      diesel
      lpg
      eurRate
      usdRate
    }
  }
`;
