/* eslint-disable */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../form/Input';
import Button from '../form/Button';
import getFormInputsData from '../../utils/form';
import axiosInstance from '../../utils/axios';
import { ProfileContext } from '../../context/Profile';
import withAuthHOC from '../../containers/Auth/AuthHOC';
import withNotificationHOC from '../notification/NotificationsHOC';
import { BasicNotification } from '..//notification/Notifications';

const DeleteAccount = ({ logout, handleShowNotification }) => {
  const {
    profile: { email },
  } = useContext(ProfileContext);
  const { t } = useTranslation('common');

  const onFormSubmit = e => {
    e.preventDefault();
    const { password } = getFormInputsData(e);
    axiosInstance
      .delete('users/delete', {
        data: {
          password,
          email,
        },
      })
      .then(() => logout())
      .catch(err =>
        handleShowNotification({
          notification: <BasicNotification color="danger" title={t('msg.failed')} message={t('msg.wrongPassword')} />,
          position: 'left-up',
        })
      );
  };

  return (
    <div className="modal_deleteAccount">
      <div className="modal_deleteAccount__body">
        <h4 className="modal_deleteAccount__header">{t('user.removeAccount')}</h4>
        <div className="modal_deleteAccount__form">
          <form className="form-group form-grid input-big ml-1 mt-5" id="newPassword" onSubmit={e => onFormSubmit(e)}>
            <Input
              className="w-100"
              required
              isButtonShow={true}
              name="password"
              type="password"
              label={t('user.password')}
              helper={t('validation.helper.password')}
            />
            <div className="form__footer mt-3 d-flex justify-content-end">
              <Button option="primary" text="send" isUpperCase className="font-size-16" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withNotificationHOC(withAuthHOC(DeleteAccount));
