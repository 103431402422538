import React, { useState, useContext } from 'react';
// import Input from '../../../components/form/Input';
import { Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Dropdown from '../../../components/form/Dropdown';
import Button from '../../../components/form/Button';
import { ProfileContext } from '../../../context/Profile';
import { tmsList, companySize, positionList } from '../config';

const WelcomeUserForm = () => {
  const [activePosition, setActivePosition] = useState(0);
  const [activeCompanySize, setActiveCompanySize] = useState(0);
  const [activeTms, setActiveTms] = useState(0);
  const { profileUpdate } = useContext(ProfileContext);
  const history = useHistory();

  const onFormSubmit = e => {
    e.preventDefault();

    profileUpdate({
      companySpecialization: positionList[activePosition].label,
      companySize: companySize[activeCompanySize].label,
      usedTMS: tmsList[activeTms].label,
    });
    history.replace(history.location.pathname);
  };

  return (
    <form action="" className="form-group form-grid input-big mt-4 " onSubmit={e => onFormSubmit(e)}>
      <Container>
        <Row noGutters>
          <Dropdown
            testName="companySpecialization"
            label="Stanowisko:"
            active={activePosition}
            list={positionList}
            onSelect={number => setActivePosition(number)}
          />
        </Row>
        <Row noGutters>
          <Dropdown
            testName="companySize"
            label="Wielkość firmy:"
            active={activeCompanySize}
            list={companySize}
            onSelect={number => setActiveCompanySize(number)}
          />
        </Row>
        <Row noGutters>
          <Dropdown testName="usedTMS" label="Używany TMS:" active={activeTms} list={tmsList} onSelect={number => setActiveTms(number)} />
        </Row>
        <Row noGutters className="mt-2 justify-content-end">
          <Button />
        </Row>
      </Container>
    </form>
  );
};

export default WelcomeUserForm;
