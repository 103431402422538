/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import DateBox from './DateBox';
import moment from 'moment';
// import { getMonth } from '../../../utils/date';
// import DataInput from '../../form/DateInput';

const DateModal = ({ onDateChange, initStartProp, initEndProp, lang, type }) => {
  const initStart = initStartProp || new Date('2019-01-01');
  const initEnd = initEndProp || new Date();
  const [isOpen, setOpen] = useState(false);
  const [start, setStart] = useState(initStart);
  const [end, setEnd] = useState(initEnd);

  return (
    <React.Fragment>
      <button type="button" className="btn-date-range" onClick={() => setOpen(true)}>
        {`${parseDate(start, lang)} - ${parseDate(end, lang)}`}
        <span className="btn-date-range__down" />
      </button>
      <Modal isOpen={isOpen} className="date-modal theme-light" backdropClassName="date-modal-background" toggle={() => setOpen(false)}>
        <DateBox
          data-testid="dateBox"
          onClose={(start, end, save = false) => {
            if (save) {
              setStart(start);
              setEnd(end);
              onDateChange(start, end);
            }
            setOpen(false);
          }}
          initStart={start}
          initEnd={end}
          lang={lang}
          type={type}
        />
      </Modal>
    </React.Fragment>
  );
};

const parseDate = (date, lang) =>
  `${moment(date)
    .locale(lang)
    .format('ll')}`;

DateModal.propTypes = {
  onDateChange: PropTypes.func,
  initStartProp: PropTypes.instanceOf(Date),
  initEndProp: PropTypes.instanceOf(Date),
  lang: PropTypes.string,
  type: PropTypes.string,
};

export default DateModal;

// wrapClassName: PropTypes.string,
// modalClassName: PropTypes.string,
// backdropClassName: PropTypes.string,
// contentClassName: PropTypes.string,
