/* eslint-disable */
import React, { useEffect, useContext, useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utils/axios';
import { ProfileContext } from '../../context/Profile';
import MainWrapper from './components/MainWrapper';
import Joi from 'joi-browser';
import { withTranslation, Trans } from 'react-i18next';
import Button from '../../components/form/Button';
import Input from '../../components/form/Input';
import { CheckBoxField } from '../../components/form/CheckBox';
import getFormInputsData from '../../utils/form';
import withNotificationHOC from '../../components/notification/NotificationsHOC';
import { BasicNotification } from '../../components/notification/Notifications';
import JoiTranslateMsg from '../../utils/joiValidatorMsg';

const NewAccount = props => {
  const { t } = useTranslation('common');
  const location = useLocation();
  const { dispatch } = useContext(ProfileContext);
  const [redirect, setRedirect] = useState(false);
  const [passwordTooShort, setPasswordWarn] = useState(false);
  const [consentRODO, setRodo] = useState(false);
  const [consentMarketingTI, setMarketingTI] = useState(false);
  const [consentMarketingTRANS, setMarketingTrans] = useState(false);
  const getActivateCode = searchString => searchString.split('=')[1];

  const notification = (title = 'Błąd', message) => {
    const { handleShowNotification } = props;
    handleShowNotification({
      notification: <BasicNotification color="danger" title={title} message={message} />,
      position: 'left-up',
    });
  };

  const register = e => {
    e.preventDefault();
    const valid = Joi.validate(getFormInputsData(e), schema);

    valid
      .then(resp => {
        const { confirmPassword, ...sendVal } = resp;
        axiosInstance
          .post('users/activate/pro', {
            ...sendVal,
            code: getActivateCode(location.search),
          })
          .then(resp => {
            Cookies.set('auth', { ...resp.data }, { expires: 30 });
            dispatch({ type: 'LOGIN', data: resp.data.profile });
            setRedirect(true);
          })
          .catch(err => {
            notification(`${t('msg.failed')} ${((err || {}).response || {}).status}`, `${t('backend.' + (((err || {}).response || {}).data || {}).err)} `);
          });
      })
      .catch(err => {
        notification(t('msg.failed'), <JoiTranslateMsg err={err} />);
      });
  };

  return redirect ? (
    <Redirect to="/get-started" />
  ) : (
    <MainWrapper title={t('dialogs.register')}>
      <div className="form form-group input-big">
        <form onSubmit={e => register(e)} className="w-100">
          <div className="form-my-grid">
            <Input
              className="f-grid-6"
              required
              isButtonShow={false}
              name="password"
              type="password"
              label={t('user.password')}
              helper={t('validation.helper.password')}
              error={passwordTooShort}
              onInputChange={e => {
                e.target.value.length < 8 ? setPasswordWarn(true) : setPasswordWarn(false);
              }}
            />
            <Input className="f-grid-6" required isButtonShow={false} name="confirmPassword" type="password" label={t('user.confirmPassword')} />

            <div className="f-grid-6">
              <CheckBoxField
                className="w-100 font-size-12"
                name="consentRODO"
                value={consentRODO}
                label={
                  <Trans i18nKey="common:user.marketingConsent1">
                    marketingConsent1
                    <a href="/p/terms-of-service" target="_blank" rel="nofollow noopener noreferrer">
                      termsOfService
                    </a>
                  </Trans>
                }
                required
                onChange={() => setRodo(!consentRODO)}
              />
            </div>
            <div className="f-grid-6">
              <CheckBoxField
                className="w-100  font-size-12"
                name="consentMarketingTI"
                value={consentMarketingTI}
                label={t('user.marketingConsent2')}
                required
                onChange={() => setMarketingTI(!consentMarketingTI)}
              />
            </div>
            <div className="f-grid-6">
              <CheckBoxField
                className="w-100  font-size-12"
                name="consentMarketingTRANS"
                value={consentMarketingTRANS}
                label={t('user.marketingConsent3')}
                required
                onChange={() => setMarketingTrans(!consentMarketingTRANS)}
              />
            </div>

            <div className="f-grid-6  font-size-12 font-italic">
              <Trans i18nKey="common:user.statement">
                marketingConsent1
                <a href={t('urls.privacyPolicy')} target="_blank" rel="nofollow noopener noreferrer">
                  Privacy Policy
                </a>
              </Trans>
            </div>
          </div>
          <div className="d-flex flex-row w-100 mt-4 justify-content-end">
            <Button option="primary" className="font-size-16 px-4" isUpperCase>
              {t('user.createAccount')}
            </Button>
          </div>
        </form>
      </div>
    </MainWrapper>
  );
};

const schema = Joi.object()
  .keys({
    password: Joi.string()
      .min(8)
      .required(),
    confirmPassword: Joi.any().valid(Joi.ref('password')),
    consentRODO: Joi.boolean()
      .required()
      .valid(true),
    consentMarketingTI: Joi.boolean()
      .required()
      .valid(true),
    consentMarketingTRANS: Joi.boolean()
      .required()
      .valid(true),
  })
  .options({ abortEarly: false });

export default withTranslation('common')(withNotificationHOC(NewAccount));
