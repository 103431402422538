import { graphql } from 'react-relay';

const getCountriesActivity = graphql`
  query countryAllDynamicsQuery($direction: String!, $date: DateTime) {
    getCountriesActivity(direction: $direction, date: $date) {
      departure
      destination
      createDate
      supplyFtl
      supplyLtl
      supplyBus
      priceFtl
      priceLtl
      priceBus
      demandLtl
      demandFtl
      demandBus
    }
  }
`;

export const countryActivityQuery = graphql`
  query countryActivityQuery($region: String!, $periodsLimit: Float, $direction: String!) {
    getLatestCountryActivity(region: $region, periodsLimit: $periodsLimit, direction: $direction) {
      createDate
      supplyLtl
      supplyFtl
      supplyBus
      demandLtl
      demandFtl
      demandBus
      priceFtl
      priceLtl
      priceBus
    }
  }
`;

export default getCountriesActivity;
