/* eslint-disable */
import React from 'react';
// import { withTranslation } from 'react-i18next';
import ChartHeader from './components/ChartHeader';
import NoData from './utils/NoData';
import ChartBody from './components/ChartBody';
import ChartIndicators from './components/ChartIndicators';
import IndicatorBox, { IndicatorBoxHeader } from './components/IndicatorBox';

import prepareChartValues from './utils/prepareChartValues';
import Loader from '../../components/loaders/Loader';

const ChartQueryRender = ({
  qerror,
  data,
  trend,
  avgSwitchValueFTL,
  minSwitchValueFTL,
  maxSwitchValueFTL,
  medSwitchValueFTL,
  medSwitchValueLTL,
  avgSwitchValueLTL,
  maxSwitchValueLTL,
  minSwitchValueLTL,
  minSwitchValueBUS,
  maxSwitchValueBUS,
  medSwitchValueBUS,
  avgSwitchValueBUS,
  fillType,
  title,
  date,
  onSwitchChange,
  dateConverter,
  addIndexName = false,
  chartDataName,
  chartOptions,
  compareInTooltip = false,
  setPeriods,
  periods,
  t,
}) => {
  if (qerror) {
    return (
      <div>
        <h1 className="text-center py-4">{t('dialogs.noData')}</h1>
      </div>
    );
  }
  if (!data) {
    return (
      <div className="py-5">
        <Loader />
      </div>
    );
  }

  const switchesValue = {
    Ftl: {
      minY: minSwitchValueFTL,
      maxY: maxSwitchValueFTL,
      median: medSwitchValueFTL,
      average: avgSwitchValueFTL,
    },
    Ltl: {
      minY: minSwitchValueLTL,
      maxY: maxSwitchValueLTL,
      median: medSwitchValueLTL,
      average: avgSwitchValueLTL,
    },
    Bus: {
      minY: minSwitchValueBUS,
      maxY: maxSwitchValueBUS,
      median: medSwitchValueBUS,
      average: avgSwitchValueBUS,
    },
  };

  if (data || data.length === 0) {
    const { minY, maxY, median, dateList, average } = prepareChartValues(
      data,
      chartOptions[0].chartName,
      dateConverter(date[1]),
      chartDataName === 'FreePrice' && 2
    );
    const currency = false; // chartDataName === 'price' && 'EUR';

    return (
      <div>
        <div>
          <ChartIndicators activeChart="">
            <IndicatorBox
              title={t('label.median')}
              data={[
                {
                  switchName: `medSwitchValue${fillType.toUpperCase()}`,
                  value: median,
                  status: switchesValue[fillType].median,
                },
              ]}
              currency={currency}
              switchHandle={e => onSwitchChange(e)}
            >
              <IndicatorBoxHeader title={t('label.median')}>
                {/* <EyeButton name="medSwitchValueFTL" switchHandle={e => onSwitchChange(e)} switchValue={medSwitchValueFTL} /> */}
              </IndicatorBoxHeader>
            </IndicatorBox>
            <IndicatorBox
              title={t('label.average')}
              data={[
                {
                  switchName: `avgSwitchValue${fillType.toUpperCase()}`,
                  value: average,
                  status: switchesValue[fillType].average,
                },
              ]}
              currency={currency}
              switchHandle={e => onSwitchChange(e)}
            >
              <IndicatorBoxHeader title={t('label.average')}>
                {/* <EyeButton name="medSwitchValueFTL" switchHandle={e => onSwitchChange(e)} switchValue={medSwitchValueFTL} /> */}
              </IndicatorBoxHeader>
            </IndicatorBox>
            <IndicatorBox
              title={t('label.maximum')}
              data={[
                {
                  switchName: `maxSwitchValue${fillType.toUpperCase()}`,
                  value: maxY,
                  status: switchesValue[fillType].maxY,
                },
              ]}
              currency={currency}
              switchHandle={e => onSwitchChange(e)}
            >
              <IndicatorBoxHeader title={t('label.maximum')}>
                {/* <EyeButton name="medSwitchValueFTL" switchHandle={e => onSwitchChange(e)} switchValue={medSwitchValueFTL} /> */}
              </IndicatorBoxHeader>
            </IndicatorBox>
          </ChartIndicators>
        </div>
        <div>
          <ChartBody
            data={data}
            fillType={fillType}
            addChartNameSuffix={addIndexName ? '_Index' : ''}
            trend={trend}
            showLineMin={switchesValue[fillType].minY}
            showLineMax={switchesValue[fillType].maxY}
            showLineMed={switchesValue[fillType].median}
            showLineAvg={switchesValue[fillType].average}
            min={minY}
            max={maxY}
            median={median}
            dateList={dateList}
            average={average}
            chartTitle={title}
            chartOptions={chartOptions}
            compareInTooltip={compareInTooltip}
            currency={currency}
            setPeriods={setPeriods}
            periods={periods}
          />
        </div>
      </div>
    );
  }
  return <div>Loading</div>;
};

export default ChartQueryRender;
