/* eslint-disable */
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import Joi from 'joi-browser';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import JoiTranslateMsg from '../../../utils/joiValidatorMsg';
import axiosInstance from '../../../utils/axios';
import Button from '../../../components/form/Button';
import Input from '../../../components/form/Input';
import { CheckBoxField } from '../../../components/form/CheckBox';
import getFormInputsData from '../../../utils/form';
import { CompanySpecialization } from '../../../enums/User';
import CountrySelector from '../../Layout/components/countrySelector';
import withNotificationHOC from '../../../components/notification/NotificationsHOC';
import { BasicNotification } from '../../../components/notification/Notifications';
import Dropdown from '../../../components/form/Dropdown';

class RegisterForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    handleShowNotification: PropTypes.func,
  };

  state = {
    passwordsWrong: false,
    redirectAfterLogin: false,
    consentRODO: false,
    consentMarketingTI: false,
    consentMarketingTRANS: false,
    companySpecializationIndex: 0,
    passwordTooShort: false,
    country: '',
  };

  notification = (title = 'Błąd', message) => {
    const { handleShowNotification } = this.props;
    handleShowNotification({
      notification: <BasicNotification color="danger" title={title} message={message} />,
      position: 'left-up',
    });
  };

  register = e => {
    const { t } = this.props;
    e.preventDefault();
    const { companySpecializationIndex, country } = this.state;
    const companySpecialization = generateLabelFromEnum(CompanySpecialization)[companySpecializationIndex].value;
    const valid = Joi.validate({ ...getFormInputsData(e), country, companySpecialization }, schema);

    valid
      .then(resp => {
        const { confirmPassword, ...sendVal } = resp;
        axiosInstance
          .post('/users/signup', {
            ...sendVal,
          })
          .then(() => {
            this.setState({
              redirectAfterLogin: true,
            });
          })
          .catch(err => {
            this.notification(`${t('msg.failed')} ${((err || {}).response || {}).status}`, `${t('backend.' + (((err || {}).response || {}).data || {}).err)} `);
          });
      })
      .catch(err => {
        this.notification(t('msg.failed'), <JoiTranslateMsg err={err} />);
      });
  };

  render() {
    const { t } = this.props;
    const { redirectAfterLogin, passwordTooShort, consentRODO, consentMarketingTI, companySpecializationIndex, consentMarketingTRANS } = this.state;

    const companySpecializationList = generateLabelFromEnum(CompanySpecialization, 'user.companySpecializationEnum.', t);

    return redirectAfterLogin ? (
      <h4 data-testid="register.email.sended">{t('msg.activationEmail')}</h4>
    ) : (
      <div className="form form-group input-big">
        <form onSubmit={e => this.register(e)} className="w-100">
          <div className="form-my-grid">
            <div className="f-grid-6">
              <CountrySelector
                label={t('user.country')}
                defaultCountry="Polska"
                handleActiveCountry={(name, iso2) => {
                  this.setState({
                    country: iso2,
                  });
                }}
              />
            </div>
            <div className="f-grid-6">
              <Dropdown
                active={companySpecializationIndex}
                list={companySpecializationList}
                label={t('user.companySpecialization')}
                testName="companySpecialization"
                onSelect={index =>
                  this.setState({
                    companySpecializationIndex: index,
                  })
                }
                handleInput={() => {}}
              />
            </div>
            <Input className="f-grid-6" required name="email" label={t('user.email')} helper={t('validation.helper.email')} showHelper />
            <Input
              className="f-grid-6"
              required
              isButtonShow={false}
              name="password"
              type="password"
              label={t('user.password')}
              helper={t('validation.helper.password')}
              error={passwordTooShort}
              onInputChange={e => {
                e.target.value.length < 8 ? this.setState({ passwordTooShort: true }) : this.setState({ passwordTooShort: false });
              }}
            />
            <Input className="f-grid-6" required isButtonShow={false} name="confirmPassword" type="password" label={t('user.confirmPassword')} />

            <div className="f-grid-6">
              <CheckBoxField
                className="w-100 font-size-12"
                name="consentRODO"
                value={consentRODO}
                label={
                  <Trans i18nKey="common:user.marketingConsent1">
                    marketingConsent1
                    <a href="/p/terms-of-service" target="_blank" rel="nofollow noopener noreferrer">
                      termsOfService
                    </a>
                  </Trans>
                }
                required
                onChange={() =>
                  this.setState({
                    consentRODO: !consentRODO,
                  })
                }
              />
            </div>
            <div className="f-grid-6">
              <CheckBoxField
                className="w-100  font-size-12"
                name="consentMarketingTI"
                value={consentMarketingTI}
                label={t('user.marketingConsent2')}
                required
                onChange={() =>
                  this.setState({
                    consentMarketingTI: !consentMarketingTI,
                  })
                }
              />
            </div>
            <div className="f-grid-6">
              <CheckBoxField
                className="w-100  font-size-12"
                name="consentMarketingTRANS"
                value={consentMarketingTRANS}
                label={t('user.marketingConsent3')}
                required
                onChange={() =>
                  this.setState({
                    consentMarketingTRANS: !consentMarketingTRANS,
                  })
                }
              />
            </div>

            <div className="f-grid-6  font-size-12 font-italic">
              <Trans i18nKey="common:user.statement">
                marketingConsent1
                <a href={t('urls.privacyPolicy')} target="_blank" rel="nofollow noopener noreferrer">
                  Privacy Policy
                </a>
              </Trans>
            </div>
          </div>
          <div className="d-flex flex-row w-100 mt-4 justify-content-end">
            <Button option="primary" className="font-size-16 px-4" isUpperCase>
              {t('user.createAccount')}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const schema = Joi.object()
  .keys({
    email: Joi.string()
      .email()
      .required(),
    password: Joi.string()
      .min(8)
      .required(),
    confirmPassword: Joi.any().valid(Joi.ref('password')),
    companySpecialization: Joi.string().required(),
    consentRODO: Joi.boolean()
      .required()
      .valid(true),
    consentMarketingTI: Joi.boolean()
      .required()
      .valid(true),
    consentMarketingTRANS: Joi.boolean()
      .required()
      .valid(true),
    country: Joi.string().required(),
  })
  .options({ abortEarly: false });

const generateLabelFromEnum = (arr, translatePath = '', translate = text => text) =>
  Object.values(arr).reduce(
    (acc, item) =>
      acc.concat({
        label: translate(`${translatePath}${item}`),
        value: item,
      }),
    []
  );

export default withTranslation('common')(withNotificationHOC(RegisterForm));
