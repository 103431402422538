/* eslint-disable */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Blocked from '../../../../components/form/Blocked';
import { ReactComponent as NaviLeft1 } from '../../../../shared/img/nav/NaviLeft1.svg';
import { ReactComponent as NaviLeft3 } from '../../../../shared/img/nav/NaviLeft3.svg';
import { ReactComponent as NaviLeft4 } from '../../../../shared/img/nav/NaviLeft4.svg';
import { ReactComponent as NaviLeft6 } from '../../../../shared/img/nav/NaviLeft6.svg';
import { ReactComponent as NaviLeft7 } from '../../../../shared/img/nav/NaviLeft7.svg';
import { ReactComponent as NaviLeftCurrency } from '../../../../shared/img/nav/NaviLeftCurrency.svg';
import { ReactComponent as NaviLeftUnlock } from '../../../../shared/img/nav/NaviLeftUnlock.svg';

const icons = [NaviLeft4, NaviLeft6, NaviLeft7, NaviLeft1, NaviLeft3, NaviLeftCurrency, NaviLeftUnlock, NaviLeft6, NaviLeft7];

const SidebarTab = ({ name, link, id }) => {
  const tabClass = classNames({
    sidebar__tab: true,
    yellow: link === '#packt' ? true : false,
  });
  const Icon = icons[id];

  return (
    <NavLink to={link} activeClassName={link[0] === '#' ? '' : 'active'} className={tabClass} key={name} data-testid={`sidebar.${id}`}>
      {id === 99 && <Blocked className="zeroMargin" />}
      <div className="sidebar__tab_gradient" />
      <div className={`sidebar__tab_wrapper ${link === '#packt' && 'yellow'}`}>
        <Icon />
        <p>{name}</p>
      </div>
    </NavLink>
  );
};

SidebarTab.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  id: PropTypes.number,
};

export default SidebarTab;
