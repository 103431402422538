/* eslint-disable */

import React from 'react';
// import { useTranslation, Trans } from 'react-i18next';

export default () => {
  // const { t } = useTranslation('common');
  // const test = [{
  //     message: "\"email\" must be a valid email",
  //     path: ["email"],
  //     type: "string.email",
  //     context: {
  //       value: "11",
  //         key: "email",
  //           label: "email"}
  //     },{
  //     message: "\"password\" length must be at least 8 characters long",
  //     path: ["password"],
  //     type: "string.min",
  //     context: {limit: 8, value: "1", encoding: undefined, key: "password", label: "password"}
  //     }]
  // "password" length must be at least 8 characters long"
  return (
    <div>
      {/* {      
            test.map(errorItem => <Trans 
                i18nKey={`common:validation.${errorItem.type}`} 
                count={((errorItem || {}).context || {}).limit || 0}>
                    {t(`user.${errorItem.path[0]}`)}
            </Trans>)
        } 
        <br /> */}
      {/* <Trans i18nKey={`common:validation.${test[0].type}`} count={8}>
            {t(`user.${test[0].path[0]}`)}
        </Trans>
        <br />
        <Trans i18nKey={`common:validation.${test[1].type}`} count={8} inputName={test[1].path[0]}>
            {t(`user.${test[1].path[0]}`)}
        </Trans> */}
      Policy
      {/* <br />
       1 { t('plural.sign', { postProcess: 'interval', count: 1 }) }  <br />
       2 { t('plural.sign', { postProcess: 'interval', count: 2 }) }  <br />
       3 { t('plural.sign', { postProcess: 'interval', count: 3 }) }  <br />
       4 { t('plural.sign', { postProcess: 'interval', count: 4 }) }  <br />
       5 { t('plural.sign', { postProcess: 'interval', count: 5 }) }  <br />
       6 { t('plural.sign', { postProcess: 'interval', count: 6 }) }  <br />
       7 { t('plural.sign', { postProcess: 'interval', count: 7 }) }  <br /> */}
    </div>
  );
};
