/**
 * @flow
 * @relayHash 33a5dacbad0dee62d5f083f8c5c2b8d3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type nutsDynamicsQueryVariables = {|
  includeWeekends?: ?boolean,
  periods?: ?string,
  direction: string,
  region: string,
|};
export type nutsDynamicsQueryResponse = {|
  +getNutActivity: $ReadOnlyArray<{|
    +region: string,
    +supplyPercentChangeLtl: number,
    +supplyPercentChangeFtl: number,
    +supplyPercentChangeBus: number,
    +demandPercentChangeLtl: number,
    +demandPercentChangeFtl: number,
    +demandPercentChangeBus: number,
  |}>
|};
export type nutsDynamicsQuery = {|
  variables: nutsDynamicsQueryVariables,
  response: nutsDynamicsQueryResponse,
|};
*/


/*
query nutsDynamicsQuery(
  $includeWeekends: Boolean
  $periods: String
  $direction: String!
  $region: String!
) {
  getNutActivity(includeWeekends: $includeWeekends, periods: $periods, direction: $direction, region: $region) {
    region
    supplyPercentChangeLtl
    supplyPercentChangeFtl
    supplyPercentChangeBus
    demandPercentChangeLtl
    demandPercentChangeFtl
    demandPercentChangeBus
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "includeWeekends",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "periods",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "direction",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "region",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getNutActivity",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "direction",
        "variableName": "direction"
      },
      {
        "kind": "Variable",
        "name": "includeWeekends",
        "variableName": "includeWeekends"
      },
      {
        "kind": "Variable",
        "name": "periods",
        "variableName": "periods"
      },
      {
        "kind": "Variable",
        "name": "region",
        "variableName": "region"
      }
    ],
    "concreteType": "RegionDynamics",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "region",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyPercentChangeLtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyPercentChangeFtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyPercentChangeBus",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandPercentChangeLtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandPercentChangeFtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandPercentChangeBus",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "nutsDynamicsQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "nutsDynamicsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "nutsDynamicsQuery",
    "id": null,
    "text": "query nutsDynamicsQuery(\n  $includeWeekends: Boolean\n  $periods: String\n  $direction: String!\n  $region: String!\n) {\n  getNutActivity(includeWeekends: $includeWeekends, periods: $periods, direction: $direction, region: $region) {\n    region\n    supplyPercentChangeLtl\n    supplyPercentChangeFtl\n    supplyPercentChangeBus\n    demandPercentChangeLtl\n    demandPercentChangeFtl\n    demandPercentChangeBus\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '87beac13054a07e7cdda8e36c87ef808';
module.exports = node;
