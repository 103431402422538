import React, { useEffect, useContext, useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utils/axios';
import { ProfileContext } from '../../context/Profile';

const Activate = () => {
  const location = useLocation();
  const { dispatch } = useContext(ProfileContext);
  const [activationStep, setActivationStep] = useState(null);
  const { t } = useTranslation('common');

  const getActivateCode = searchString => searchString.split('=')[1];

  useEffect(() => {
    axiosInstance
      .post('users/activate', {
        activationCode: getActivateCode(location.search),
      })
      .then(resp => {
        Cookies.set('auth', { ...resp.data }, { expires: 30 });
        dispatch({ type: 'LOGIN', data: resp.data.profile });

        setActivationStep('success');
      })
      .catch(error => {
        console.log(error);
        setActivationStep('failed');
      });
  }, []);

  return (
    <div className="container__wrap withoutSidebar">
      <div className="container__wrap-body allRounded ">
        <h2 className="mt-5">
          {activationStep === null && t('msg.activationAccount')}
          {activationStep === 'failed' && t('msg.activationFailed')}
          {activationStep === 'success' && <Redirect to="/get-started" />}
        </h2>
      </div>
    </div>
  );
};

export default Activate;
