import maxBy from 'lodash.maxby';
import minBy from 'lodash.minby';
import get from 'lodash.get';

const prepareChartValues = (data, name, stopDate, fixed = 0) => {
  const avgValues = data.map(item => item[name]);
  // console.warn(">>>", avgValues, 'x', data);
  const average = +(avgValues.reduce((a, b) => a + b, 0) / avgValues.length).toFixed(fixed);
  const medianValues = avgValues.sort((a, b) => a - b);
  const minY = +get(minBy(data, name), name, 0).toFixed(fixed);
  const maxY = +get(maxBy(data, name), name, 0).toFixed(fixed);
  /*eslint no-bitwise: ["error", { "allow": [">>"] }] */
  const median = +((medianValues[(medianValues.length - 1) >> 1] + medianValues[medianValues.length >> 1]) / 2).toFixed(fixed);

  return {
    dateList: data.reduce((acc, item) => acc.concat(item.YearMonth), []).concat(`${stopDate}`),
    DdateList: data.reduce((acc, item) => acc.concat(item.YearMonth), []).concat(`${stopDate}`),
    minY,
    maxY,
    average,
    median,
  };
};

export default prepareChartValues;
