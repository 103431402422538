/**
 * @flow
 * @relayHash b39ce0c00edd3691f7a19ce3a5012a34
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SearchRegionsQueryVariables = {|
  query: string,
  sortLang?: ?string,
|};
export type SearchRegionsQueryResponse = {|
  +getRegions: {|
    +nuts: $ReadOnlyArray<{|
      +nuts: string,
      +country: ?string,
      +latinName: ?string,
      +namePL: ?string,
      +nameEN: ?string,
    |}>,
    +countries: $ReadOnlyArray<{|
      +iso2: string,
      +short: string,
      +nameEN: string,
      +namePL: string,
    |}>,
    +nutsByPostal: $ReadOnlyArray<{|
      +postalCode: string,
      +nuts: string,
    |}>,
    +countriesByPostal: $ReadOnlyArray<{|
      +postalCode: string,
      +country: string,
    |}>,
  |}
|};
export type SearchRegionsQuery = {|
  variables: SearchRegionsQueryVariables,
  response: SearchRegionsQueryResponse,
|};
*/


/*
query SearchRegionsQuery(
  $query: String!
  $sortLang: String
) {
  getRegions(query: $query, sortLang: $sortLang) {
    nuts {
      nuts
      country
      latinName
      namePL
      nameEN
    }
    countries {
      iso2
      short
      nameEN
      namePL
    }
    nutsByPostal {
      postalCode
      nuts
    }
    countriesByPostal {
      postalCode
      country
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sortLang",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "nuts",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "country",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "namePL",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "nameEN",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "postalCode",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getRegions",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "query",
        "variableName": "query"
      },
      {
        "kind": "Variable",
        "name": "sortLang",
        "variableName": "sortLang"
      }
    ],
    "concreteType": "DictionarySearchResult",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "nuts",
        "storageKey": null,
        "args": null,
        "concreteType": "Nuts",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "latinName",
            "args": null,
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "countries",
        "storageKey": null,
        "args": null,
        "concreteType": "Country",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "iso2",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "short",
            "args": null,
            "storageKey": null
          },
          (v4/*: any*/),
          (v3/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "nutsByPostal",
        "storageKey": null,
        "args": null,
        "concreteType": "NutsPostalCode",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          (v1/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "countriesByPostal",
        "storageKey": null,
        "args": null,
        "concreteType": "CountryPostalCode",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          (v2/*: any*/)
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SearchRegionsQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v6/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SearchRegionsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v6/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SearchRegionsQuery",
    "id": null,
    "text": "query SearchRegionsQuery(\n  $query: String!\n  $sortLang: String\n) {\n  getRegions(query: $query, sortLang: $sortLang) {\n    nuts {\n      nuts\n      country\n      latinName\n      namePL\n      nameEN\n    }\n    countries {\n      iso2\n      short\n      nameEN\n      namePL\n    }\n    nutsByPostal {\n      postalCode\n      nuts\n    }\n    countriesByPostal {\n      postalCode\n      country\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cd9c39f77535c94830bfc5ffca91dd8b';
module.exports = node;
