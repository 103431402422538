import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const ThemeContext = React.createContext();
export class MainWrapper extends PureComponent {
  static propTypes = {
    children: PropTypes.element.isRequired,
  };

  state = {
    isDark: false,
  };

  switchTheme = () => {
    const { isDark } = this.state;
    this.setState({
      isDark: !isDark,
    });
  };

  render() {
    const { children } = this.props;
    const { isDark } = this.state;

    const themeClass = classNames({
      'theme-light': !isDark,
      'theme-dark': isDark,
    });

    return (
      <ThemeContext.Provider
        value={{
          isDark,
          switchTheme: this.switchTheme,
        }}
      >
        <div className={themeClass}>
          <div className="wrapper">{children}</div>
        </div>
      </ThemeContext.Provider>
    );
  }
}

export const ThemeContextConsumer = ThemeContext.Consumer;
