import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

const Slick = ({ children, settings }) => <Slider {...settings}>{children}</Slider>;

Slick.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.any]),
  // eslint-disable-next-line react/forbid-prop-types
  settings: PropTypes.object,
};

export default Slick;
