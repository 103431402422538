import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import LockIcon from 'mdi-react/LockIcon';
import PropTypes from 'prop-types';
import { ProfileContext } from '../../context/Profile';

const Blocked = ({ className = '' }) => {
  const { profile } = useContext(ProfileContext);

  return profile.role === 'FREE' ? (
    <Link to="#packt">
      <div className={`blocker ${className} `}>
        <span className="blocker__lock">
          <LockIcon size={16} />
        </span>
      </div>
    </Link>
  ) : (
    ''
  );
};

Blocked.propTypes = {
  className: PropTypes.string,
};

export const BlockedWrapper = ({ className = '', linkClass = '', childClass = '', children }) => {
  const { profile } = useContext(ProfileContext);

  return profile.role === 'FREE' ? (
    <Link
      to="#packt"
      onClickCapture={e => {
        e.stopPropagation();
      }}
      className={linkClass}
    >
      <div className={`blocker blocker-wrapper ${className} `}>
        <span className="blocker__lock">
          <LockIcon size={16} />
        </span>
        <div className={`blocker-child ${childClass}`}>{children}</div>
      </div>
    </Link>
  ) : (
    children
  );
};

BlockedWrapper.propTypes = {
  className: PropTypes.string,
  linkClass: PropTypes.string,
  childClass: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.any]),
};

export default Blocked;
