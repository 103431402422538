/* eslint-disable  */
import React, { Component } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { fetchQuery } from 'react-relay';
import environment from 'relay-env';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import MapWrapper from './components/leaflet/MapWrapper';
import ClustersFilterGroup from './components/ClustersFilterGroup';
import ChartSidebar from '../Layout/components/ChartSidebar';
import MapChart from './components/MapChart';
import SwitchBtn from '../../components/form/SwitchBtn';
import { BlockedWrapper } from '../../components/form/Blocked';
import PolygonsData from './components/leaflet/PolygonsData';
import { chartTypes, fillTypes, timePrecision } from '../Charts/utils/config';
import { countryActivityQuery } from '../../relay/queries/country';
import { nutsActivityQuery } from '../../relay/queries/nuts';

class LeafletMapWrapper extends Component {
  dateFormat = (date = null) => {
    const dateObj = date ? new Date(date) : new Date();

    return moment(dateObj)
      .subtract(0, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
  };

  state = {
    activeRegion: null,
    activeRegionDetails: [],
    date: this.dateFormat(),
    fillType: fillTypes[2].value,
    chartType: chartTypes[2].value,
    timeType: timePrecision[2].value,
    mapType: true,
    sidebarToggle: false,
    direction: false,
  };

  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        option: PropTypes.string,
      }),
    }),
  };

  toggle = () => {
    this.setState(prevState => ({ sidebarToggle: !prevState.sidebarToggle }));
  };

  setActiveRegion = data => {
    const { mapType, direction } = this.state;

    fetchQuery(environment, mapType ? nutsActivityQuery : countryActivityQuery, {
      region: data.id,
      direction: direction ? 'to' : 'from',
    }).then(resp => {
      this.setState({
        activeRegion: data,
        sidebarToggle: true,
        activeRegionDetails: resp.getLatestCountryActivity || resp.getLatestNutActivity,
      });
    });
  };

  chartDivider = () => (
    <div className="charts-sidebar__divider">
      <svg width="300px" height="12px" viewBox="0 0 300 12">
        <line x1="20" x2="260" y1="5" y2="5" stroke="#335" strokeWidth="2" strokeLinecap="round" strokeDasharray="1, 20" />
      </svg>
    </div>
  );

  getClusterHeader = clusterData => {
    if (!get(clusterData, 'name', null)) {
      return {
        title: '',
        subtitle: '',
      };
    }
    return {
      title: get(clusterData, 'name', '')
        .split('_')
        .slice(0, 1)[0],
      subtitle: get(clusterData, 'name', '')
        .split('_')
        .slice(1, 2)
        .map(word => `${word[0].toUpperCase() + word.slice(1)} `)[0],
    };
  };

  render() {
    const { fillType, mapType, timeType, chartType, direction, sidebarToggle, date, activeRegion, activeRegionDetails } = this.state;

    const { t } = this.props;
    return (
      <React.Fragment>
        <div className="d-flex flex-md-row flex-column align-items-end">
          <ClustersFilterGroup
            bodyType={false}
            fillTypeActive={fillType}
            chartTypeActive={chartType}
            showTimeGranulation={false}
            className="map-header w-100 flex-column flex-md-row flex-start"
            handler={data => {
              this.setState(data);
            }}
          >
            <BlockedWrapper linkClass="ml-1 mr-3 mb-1" className="d-flex align-items-end">
              <SwitchBtn
                onClick={() => this.setState({ direction: !direction })}
                checked={direction}
                className="my-1 "
                testString="direction"
                label={t('label.direction')}
                leftSide={t('label.from')}
                rightSide={t('label.to')}
              />
            </BlockedWrapper>
            {/* <BlockedWrapper linkClass="ml-1 mr-3 mb-1" className="d-flex align-items-end"> */}
            <SwitchBtn
              onClick={() => this.setState({ mapType: !mapType })}
              checked={mapType}
              testString="mapType"
              className="mr-3 my-1 "
              // label="Map type"
              leftSide={t('label.countries')}
              rightSide={t('label.regions')}
            />
            {/* </BlockedWrapper> */}
            <BlockedWrapper linkClass="ml-1 mr-3 mb-1" childClass="mt-3 ml-1 " className="d-flex align-items-end">
              <div className="group-dropdown date-selector ml-2">
                {/* //<p className="group-dropdown__label">{t('label.date')}</p> // */}
                <div className="btn-group-select px-0 px-md-2">
                  <p className="btn-group-select__label">{t('label.date')}:</p>
                  <DatePicker
                    selected={new Date(date)}
                    onChange={event => {
                      this.setState({ date: this.dateFormat(event) });
                    }}
                    minDate={new Date('2017-01-01')}
                    maxDate={new Date(this.dateFormat())}
                    dateFormat="MM.yyyy"
                    className="dropdown-date"
                    showMonthYearPicker
                  />
                </div>
              </div>
            </BlockedWrapper>
          </ClustersFilterGroup>
          <p data-tip={t(`tooltips.map`)} className="map-tooltip text-nowrap" data-class="tooltips_info" data-place="bottom">
            {t('tooltips.infoMap')}
          </p>
          <ReactTooltip />
        </div>
        <ChartSidebar handleToggle={this.toggle} isOpen={sidebarToggle} name={(activeRegion || {}).name} subtitle={(activeRegion || {}).nutsName}>
          <div className="pt-3">
            <MapChart
              title={t('label.supplyIndex')}
              // expandLink={`/pages/chart/supply?region=${(activeRegion || {}).id}&type=${mapType ? 'nuts' : 'country'}&direction=${direction ? 'to' : 'from'}`}
              data={activeRegionDetails}
              dataSetName={`supply${fillType}`}
            />
          </div>
          {this.chartDivider()}
          <div className="pt-3">
            <MapChart
              title={t('label.demandIndex')}
              // expandLink={`/pages/chart/demand?region=${(activeRegion || {}).id}&type=${mapType ? 'nuts' : 'country'}&direction=${direction ? 'to' : 'from'}`}
              data={activeRegionDetails}
              dataSetName={`demand${fillType}`}
            />
          </div>
          {/* {this.chartDivider()}
          <div className="pt-3">
            <MapChart
              title={t('label.priceIndex')}
              expandLink={`/pages/chart/price?region=${(activeRegion || {}).id}&type=${mapType ? 'nuts' : 'country'}&direction=${direction ? 'to' : 'from'}`}
              data={activeRegionDetails}
              dataSetName={`price${fillType}`}
            />
          </div> */}
        </ChartSidebar>
        <div className="leaflet-all">
          <div className="leaflet-wrapper">
            <MapWrapper>
              <PolygonsData
                direction={direction ? 'to' : 'from'}
                mapType={mapType}
                timeType={timeType}
                weight={fillType}
                chartType={chartType}
                dateTime={date}
                handleActiveRegion={this.setActiveRegion}
              />
            </MapWrapper>
            <svg id="clusterHeatMap" className="map-legend-wrapper" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation('common')(LeafletMapWrapper);
