/* eslint-disable */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash.get';
import Scrollbar from 'react-smooth-scrollbar';
import useOutsideClick from '../../utils/hooks/useOutsideClick';

const Dropdown = ({
  list = [],
  active,
  mainClass = '',
  onSelect,
  disabled = false,
  withActive = false,
  withTextInput = false,
  handleInput,
  textValue = '',
  label = '',
  placeholder,
  testName = '',
  currency = false,
  lang = '',
}) => {
  const [open, dropdownToggle] = useState(false);
  const dropdown = useRef();
  const dropdownClass = classNames({
    dropdown: true,
    'dropdown--open': open && list.length > 0,
    'dropdown--disabled': disabled || (list.length === 0 && !withTextInput),
  });

  useOutsideClick(dropdown, () => dropdownToggle(false));

  // useEffect(() => {
  //   dropdownToggle( withTextInput); // list.length !== 0 &&
  // }, [list]);

  return (
    <div className="dropdown-wrapper">
      <div className="dropdown-label">{label}</div>

      <div className={`${dropdownClass} ${mainClass}`} ref={dropdown} data-testid={`dropdown.${testName}`}>
        {withTextInput ? (
          <input
            type="text"
            className="dropdown-input"
            onChange={e => handleInput(e.target.value)}
            onClick={() => dropdownToggle(!open && !disabled)} // && list.length !== 0
            value={textValue}
            placeholder={placeholder}
          />
        ) : (
          <button type="button" onClick={() => dropdownToggle(!open && !disabled && list.length !== 0)} className="dropdown-active">
            {get(list, `${active}.label`, <span />)}
            <span className="lnr lnr-chevron-down dropdown-down" />
          </button>
        )}

        <div className="dropdown-list">
          <Scrollbar alwaysShowTracks className="scroll">
            {list.map(
              (item, index) =>
                (withActive || index !== active) && (
                  <button
                    key={item + index}
                    type="button"
                    onClick={() => {
                      onSelect(index);
                      dropdownToggle(!open);
                    }}
                    className="dropdown-list__item"
                  >
                    <div>
                      {item.label}
                      {get(item, 'annotation', '').length !== 0 ? (
                        <span className="dropdown-list__annotation">{currency ? (lang === 'pl' ? item.annotationPL : item.annotation) : item.annotation}</span>
                      ) : null}
                    </div>
                    <span>{item.other}</span>
                  </button>
                )
            )}
          </Scrollbar>
        </div>
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      other: PropTypes.any,
      annotation: PropTypes.string,
      value: PropTypes.any,
    })
  ),
  // active: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  active: PropTypes.any,
  disabled: PropTypes.bool,
  withActive: PropTypes.bool,
  withTextInput: PropTypes.bool,
  mainClass: PropTypes.string,
  label: PropTypes.string,
  textValue: PropTypes.string,
  testName: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  handleInput: PropTypes.func,
  placeholder: PropTypes.string,
};

export default Dropdown;
