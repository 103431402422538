import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'reactstrap';

export default class SideSlidePanel extends Component {
  state = {
    opened: false,
  };

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    openBtn: PropTypes.element,
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  handleClick = e => {
    if (e.target.id === 'side-slide-panel_bg') {
      this.toggle();
    }
  };

  toggle = () => {
    this.setState(prevState => ({ opened: !prevState.opened }));
  };

  render() {
    const { children, openBtn } = this.props;
    const { opened } = this.state;
    const SideSlidePanelClass = classNames({
      opened,
    });

    return (
      <React.Fragment>
        <Button className="py-1 px-2 m-0" color="primary" onClick={() => this.toggle()}>
          {openBtn}
        </Button>
        <div className={`side-slide-panel ${SideSlidePanelClass}`} id="side-slide-panel_bg">
          <div className={`side-slide-panel_container ${SideSlidePanelClass}`}>
            <div className="d-flex justify-content-end">
              <button className="lnr lnr-cross side-slide-panel_container__close-btn py-0" type="button" onClick={() => this.toggle()} />
            </div>
            {children}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
