import resources from './resources';

export const config = {
  interpolation: { escapeValue: false }, // React already does escaping
  detection: {
    order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    lookupCookie: 'lang',
  },
  resources,
  fallbackLng: 'en',
};

// const getLangFromBrowser = () => (navigator.language || navigator.userLanguage).replace('-', '_').split('_')[0];

// export const getInitialLang = () => getLangFromBrowser();

export { resources };
