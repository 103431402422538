/* eslint-disable react/prop-types */
import React from 'react';

export default ({ off }) => (
  <defs>
    <linearGradient id="colorUv" x1="0%" y1="0%" x2="0%" y2="100%">
      <stop offset="20%" stopColor="#5cc4d2" />
      <stop offset="100%" stopColor="#075397" />
    </linearGradient>
    <linearGradient id="red" x1="0%" y1="0%" x2="0%" y2="100%">
      <stop offset="0%" stopColor="#F51717" />
      <stop offset="100%" stopColor="#D01818" />
    </linearGradient>
    <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
      <stop offset={off || '0%'} stopColor="green" stopOpacity={0} />
      <stop offset={off || '100%'} stopColor="#ff4861" stopOpacity={1} />
    </linearGradient>
    <linearGradient id="green" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stopColor="#b8e986" />
      <stop offset="100%" stopColor="#b9f090" />
    </linearGradient>
    <linearGradient id="gradient-opacity" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" stopColor="transparent" />
      <stop offset="5%" stopColor="#fff" />
      <stop offset="95%" stopColor="#fff" />
      <stop offset="100%" stopColor="transparent" />
    </linearGradient>
  </defs>
);
