import React from 'react';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';

//   className,
//   wrapClassName,
//   modalClassName,
//   backdropClassName,
//   contentClassName,
const FullSizeModal = ({ children, isOpen = false, onClose, withClose, popupClass = '' }) => {
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={onClose}
        modalClassName={`modal_background ${popupClass}`}
        contentClassName="modal_content"
        className={`modal_dialog theme-light ${popupClass}`}
      >
        {withClose && <CloseCircleIcon className="modal_closeIcon" data-testid="icon.close" size={42} onClick={onClose} />}
        <div className="modal_children">{children}</div>
      </Modal>
    </React.Fragment>
  );
};

FullSizeModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  isOpen: PropTypes.bool,
  withClose: PropTypes.bool,
  onClose: PropTypes.func,
  popupClass: PropTypes.string,
};

export default FullSizeModal;
