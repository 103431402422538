import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import pl from 'date-fns/locale/pl';
import en from 'date-fns/locale/en-GB';

registerLocale('pl', pl);
registerLocale('en', en);

const DateGrid = ({ selected = new Date(), onSelect, minDate = new Date('2019-01-01'), maxDate = new Date(), lang }) => {
  return (
    <div className="date-grid">
      <DatePicker
        inline
        dateFormat="MM/yyyy"
        minDate={minDate}
        maxDate={maxDate}
        selected={selected}
        onChange={onSelect}
        locale={lang}
        scrollableYearDropdown
        showYearDropdown
        yearDropdownItemNumber={15}
      />
    </div>
  );
};

DateGrid.propTypes = {
  selected: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onSelect: PropTypes.func,
  lang: PropTypes.string,
};
export default DateGrid;
