import React from 'react';
import PropTypes from 'prop-types';
// import Scrollbar from 'react-smooth-scrollbar';

const ContainerWrapper = ({ bodyClass, children }) => (
  <div className="container__wrap">
    <div className={bodyClass}>
      {/* <Scrollbar className="container__wrap--scroll"> */}
      {children}
      {/* </Scrollbar> */}
    </div>
  </div>
);

export default ContainerWrapper;

ContainerWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  bodyClass: PropTypes.string,
};
