/* eslint-disable */

import React, { useContext } from 'react';
import { Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Joi from 'joi-browser';
import get from 'lodash.get';
import Button from '../form/Button';
import getFormInputsData from '../../utils/form';
import JoiTranslateMsg from '../../utils/joiValidatorMsg';
import Input from '../form/Input';
import withNotificationHOC from '../notification/NotificationsHOC';
import { BasicNotification } from '../notification/Notifications';
import { ProfileContext } from '../../context/Profile';
import axiosInstance from '../../utils/axios';

const schema = Joi.object()
  .keys({
    email: Joi.string()
      .email()
      .required(),
    firstName: Joi.string(),
    lastName: Joi.string(),
  })
  .options({ abortEarly: false });

const AddUser = ({ handleShowNotification, t }) => {
  const { dispatch } = useContext(ProfileContext);
  const history = useHistory();

  const onFormSubmit = e => {
    e.preventDefault();
    const valid = Joi.validate(getFormInputsData(e), schema);
    valid
      .then(resp => {
        axiosInstance
          .post('/users/access/grant', {
            ...resp,
          })
          .then(() => {
            dispatch({
              type: 'PROFILE_USERLIST_ADD',
              ...resp,
            });

            history.goBack();
          })
          .catch(error => {
            const errorMsg = get(error, 'response.data.err', false)
              ? `backend.${get(error, 'response.data.err', 'msg.failed')}`
              : get(error, 'response.details.type', 'msg.failed');
            console.error('Add user err:', error, Object.keys(error));
            return handleShowNotification({
              notification: <BasicNotification color="danger" title={t('msg.failed')} message={t(errorMsg)} />,
            });
          });
      })
      .catch(err =>
        handleShowNotification({
          notification: <BasicNotification color="danger" title={t('msg.failed')} message={<JoiTranslateMsg err={err} />} />,
        })
      );
  };

  return (
    <div className="modal_adduser">
      <div className="modal_adduser__body">
        <h4 className="modal_adduser__header">{t('button.addUser')}</h4>
        <div className="modal_adduser__form">
          <form action="" className="form-group form-grid input-big" onSubmit={e => onFormSubmit(e)}>
            <Container>
              <Row noGutters className="my-3">
                <Input label={t('user.firstName')} name="firstName" className="w-100" />
              </Row>
              <Row noGutters className="my-3">
                <Input label={t('user.lastName')} name="lastName" className="w-100" />
              </Row>
              <Row noGutters className="my-3">
                <Input label={t('user.email')} name="email" className="w-100" required />
              </Row>
              <Row noGutters className="d-flex justify-content-end my-3">
                <Button option="primary" testid="submit" />
              </Row>
            </Container>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withTranslation('common')(withNotificationHOC(AddUser));
