import React from 'react';

export default () => (
  <div className="p-5 text-page">
    <h2>REGULAMIN METRIX</h2>
    <ol>
      <li>
        POSTANOWIENIA OGóLNE
        <ol>
          <li>
            Niniejszy regulamin (zwany dalej „Regulaminem”), określa zasady świadczenia usług drogą elektroniczną w rozumieniu ustawy z dnia 18 lipca 2002 r. o
            świadczeniu usług drogą elektroniczną (t.j. Dz. U. z 2019 r. poz. 123.) oraz warunki korzystania z internetowej aplikacji Metrix.
          </li>
          <li>
            Metrix jest aplikacją dedykowaną Użytkownikom prowadzącym działalność w zakresie spedycji, transportu lub logistyki, przeznaczoną w szczególności do
            przekazywania w formie aplikacji online informacji o bieżącym popycie/podaży oraz stawkach za transport oraz trendach w branży TSL.
          </li>
        </ol>
      </li>
      <li>
        DEFINICJE
        <ol>
          <li>Konto - panel zarządzania funkcjonalnościami Metrix, przypisany do indywidualnie oznaczonego Użytkownika.</li>
          <li>
            Metrix - narzędzie analityczne skierowane dla przedsiębiorców z branży TSL, dostępne w postaci Webowej pod następującym adresem:
            {' '}
            <a href="https://trans.info/metrix/">https://trans.info/metrix/</a>
            pozwalające śledzić aktualne stawki w branży TSL i popyt na usługi.
          </li>
          <li>
            Usługodawca/Administrator - Trans.info spółka z ograniczoną odpowiedzialnością z siedzibą w Wysokiej, posiadająca adres ul. Chabrowa 4, 52-200
            Wysoka, Polska, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla Wrocławia &ndash; Fabrycznej we
            Wrocławiu VI Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000751609, NIP: 8961581580, REGON: 381491570.
          </li>
          <li>
            Polityka Prywatności - polityka prywatności Metrix dostępna pod adresem:
            <a href="https://trans.info/pl/page/polityka-prywatnosci">https://trans.info/pl/page/polityka-prywatnosci</a>
            .
          </li>
          <li>
            Użytkownik - każda osoba fizyczna, prawna lub jednostka organizacyjna, nie posiadająca osobowości prawnej, której ustawa przyznaje zdolność prawną,
            która w ramach prowadzonej przez siebie działalności gospodarczej zarejestrowała się na Metrix w sposób określony w dziale IV oraz posiada Konto.
          </li>
          <li>Użytkownik Główny - Użytkownik, który korzysta z Wersji Płatnej Metrixa oraz posiada uprawnienia administratora .</li>
          <li>
            Abonament - opłata z tytułu dostępu do Pełnej Wersji Metrixa. Użytkownik ma możliwość opłacania Abonamentu w rocznym lub miesięcznym okresie
            rozliczeniowym (dalej: „Okres Rozliczeniowy”).
          </li>
          <li>
            Abonament Samoodnawialny - usługa świadczona na życzenie Użytkownika Głównego przez kolejne automatycznie odnawiane Okresy Rozliczeniowe wybrane
            przez Użytkownika Głównego. Usługa Abonamentu Samoodnawialnego jest dostępna wyłącznie w przypadku wyboru Okresu Rozliczeniowego miesięcznego i jest
            świadczona wyłącznie na rzecz Użytkowników Głównych, którzy wybrali sposób płatności kartą płatniczą obsługiwanej przez zewnętrznego operatora
            płatności.
          </li>
          <li>Pełna wersja - płatna wersja zapewniająca dostęp do funkcjonalności określonych w dziale V niniejszego Regulaminu.</li>
          <li>
            Darmowa Wersja - nieodpłatna wersja Metrixa w ramach której Użytkownik otrzymuje możliwość przeglądania wskaźników uśrednionych dla całej Europy, z
            rozdzielczością danych miesięczną, tylko z ostatnich 12 miesięcy bez możliwości używania filtrów dostępnych w Wersji Płatnej.
          </li>
          <li>
            Cennik - informacja odnośnie aktualnych stawek za Pełną Wersję Metrixa, dostępna pod adresem:
            {' '}
            <a href="https://trans.info/metrix">https://trans.info/metrix/</a>
          </li>
          <li>Regulamin - niniejszy Regulamin.</li>
        </ol>
      </li>
      <li>
        WYMAGANIA TECHNICZNE
        <ol>
          <li>
            Korzystanie z Metrix jest możliwe pod warunkiem spełnienia przez system teleinformatyczny, z którego korzysta Użytkownik, wymagań technicznych w
            postaci:
            <ol>
              <li>
                posiadania urządzenia pozwalającego na dostęp do sieci Internet, łącznie z oprogramowaniem służącym do przeglądania jej zasobów, akceptującym
                pliki typu html, xhtml, pdf, cookies i z włączoną obsługą javascript;
              </li>
              <li>
                korzystania z następujących przeglądarek internetowych: Chrome, Firefox, Safari; preferowaną i zalecaną przez Usługodawcę przeglądarką
                internetową jest Chrome,
              </li>
              <li>łącza internetowego o przepustowości co najmniej 512 kbs,</li>
            </ol>
          </li>

          <li>
            Usługodawca podejmuje działania w celu zapewnienia poprawnego działania Metrix oraz zobowiązuje się usunąć w rozsądnym terminie nieprawidłowości w
            jego działaniu, które zostały zgłoszone przez Użytkowników.
          </li>
        </ol>
      </li>
      <li>
        REJESTRACJA I LOGOWANIE
        <ol>
          <li>
            W celu założenia Konta, Użytkownik:
            <ol>
              <li>
                uzupełnia formularz rejestracyjny o następujące dane - w przypadku wyboru Wersji Darmowej:
                <ol>
                  <li>Typ firmy (spedytor, przewoźnik, załadowca);</li>
                  <li>Kraj;</li>
                  <li>Adres e-mail;</li>
                  <li>Hasło;</li>
                </ol>
                dalej jako: „Formularz”;
              </li>
              <li>potwierdza, że zapoznał się i akceptuje niniejszy Regulamin;</li>
              <li>
                w przypadku wyboru Wersji Płatnej - uzupełnia formularz rejestracyjny o następujące dane -:
                <ol>
                  <li>Imię i nazwisko;</li>
                  <li>Nazwa firmy;</li>
                  <li>NIP;</li>
                  <li>Pełny adres firmy;</li>
                  <li>Dane kontaktowe (numer telefonu, adres e-mail);</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>Czynności określone w ust. 1 lit. a-c są obligatoryjnie dla świadczenia usługi Metrix.</li>
          <li>
            Dokonując rejestracji Konta, Użytkownik oświadcza, że:
            <ol>
              <li>podane dane są zgodne ze stanem faktycznym i prawnym. W przypadku zmiany podanych danych dokona niezwłocznej ich aktualizacji;</li>
              <li>podane przez Użytkownika dane nie naruszają praw osób trzecich;</li>
              <li>jest uprawniony do zawarcia umowy o świadczenie usług drogą elektroniczną;</li>
              <li>
                prowadzi działalność gospodarczą w zakresie objętym przedmiotem świadczenia usługi Metrix, a tym samym występuje jako podmiot profesjonalny.
              </li>
            </ol>
          </li>
          <li>
            Po dokonaniu rejestracji w sposób określony w ust. 1 Użytkownik może logować się do Metrix poprzez wpisanie adresu e-mail i hasła utworzonego
            podczas rejestracji.
            {' '}
          </li>
          <li>Zabrania się udostępniania Konta innym podmiotom.</li>
          <li>Zabrania się korzystania z Kont należących do innych podmiotów.</li>
          <li>Z zastrzeżeniem działu V, każdy z Użytkowników ma możliwość założenia jednego indywidualnego Konta.</li>
          <li>
            W ramach korzystania z Metrix Użytkownik nie może:
            <ol>
              <li>
                pobierać, przechowywać, używać jakichkolwiek urządzeń automatycznych do zapisywania danych udostępnianych za pośrednictwem Metrix (pod rygorem
                utraty dostępu) oraz wykorzystywania ich w celach komercyjnych,
              </li>
              <li>
                korzystać z Metrix w sposób naruszający prawa i dobra osobiste (a w szczególności: dobra osobiste takie jak: wizerunek, godność, dobre imię)
                innych Użytkowników i Usługodawcy,
              </li>
              <li>
                wykonywać jakichkolwiek czynności, które mogłyby wyłączyć, przeciążyć lub ograniczyć prawidłowe funkcjonowanie oraz wygląd Metrix takich jak
                atak powodujący zablokowanie obsługi, ingerowanie w sposób wyświetlania strony lub inne funkcje.
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        PEŁNA WERSJA
        <ol>
          <li>
            Użytkownik Główny, który założył Konto w sposób określony w dziale IV ust. 1 lit. b oraz opłacił Abonament, w ramach dostępu do Wersji Płatnej ma
            możliwość:
            <ol>
              <li>przyznania w ramach Konta dostępu dla 3 dodatkowych Użytkowników;</li>
              <li>zarządzania dostępami Użytkowników określonych w punkcie powyżej;</li>
              <li>przedłużenia dostępu do Wersji Płatnej poprzez opłacenie Abonamentu w sposób określony w dziale VI ust. 1;</li>
              <li>
                korzystania z następujących funkcjonalności:
                <ol>
                  <li>indeks cen frachtów w formie wykresu;</li>
                  <li>indeks popytu na transport w formie wykresu i mapy;</li>
                  <li>indeks podaży powierzchni transportowej w postaci indeksu i mapy;</li>
                  <li>możliwość filtrowania wykresów określonych w lit.a i b w zależności od trasy oraz typu ładunku.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            W celu przyznania dostępu dodatkowym Użytkownikom, Użytkownik Główny:
            <ol>
              <li>wybiera zakładkę „użytkownicy” na Koncie;</li>
              <li>wpisuje adres e-mail dodatkowego Użytkownika (maksymalnie 3 adresy e-mail);</li>
            </ol>
          </li>
          <li>
            W przypadku gdy Użytkownik Główny odbierze dostęp do usługi Metrix jednemu z Użytkowników, określonych w ust. 2, Użytkownik taki zostaje
            automatycznie przeniesiony do Wersji Darmowej lub odbierany jest mu dostęp. Przypadki te określa dział VI ust. 7.
            {' '}
          </li>
        </ol>
      </li>
      <li>
        PŁATNOŚCI
        <ol>
          <li>
            W celu skorzystania z Płatnej Wersji Metrixa, Użytkownik:
            <ol>
              <li>wybiera zakładkę „wykup dostęp”;</li>
              <li>wybiera rodzaj Abonamentu, jaki chce opłacić;</li>
              <li>dokonuje płatności za wybrany Abonament;</li>
              <li>wybiera przycisk „Zapłać”.</li>
            </ol>
          </li>
          <li>
            Po dokonaniu czynności opisanych w ustępie powyżej Użytkownik zostaje przekierowany na stronę operatora płatności, gdzie zgodnie z wybranym rodzajem
            Abonamentu dokonuje płatności.
          </li>
          <li>Z chwilą opłacenia Abonamentu Użytkownik staje się Użytkownikiem Głównym oraz uzyskuje dostęp do Wersji Płatnej Metrixa.</li>
          <li>Abonament jest płatny z góry w rocznym okresie rozliczeniowym.</li>
          <li>
            Czas trwania Abonamentu rozpoczyna się z chwilą opłacenia i przyznania dostępu Użytkownikowi Głównemu i jest wspólny dla wszystkich Użytkowników
            działających w obrębie jednego Konta.
          </li>
          <li>
            Z zastrzeżeniem działu VII, w przypadku niedokonania płatności za Abonament najpóźniej w ostatnim dniu trwania Abonamentu, Użytkownik Główny zostaje
            automatycznie przeniesiony do Darmowej Wersji Metrixa.
          </li>
          <li>
            Użytkownicy, którzy uzyskali dostęp do Metrixa w sposób określony w dziale V ust. 1 pkt 1) Regulaminu, w przypadku określonym w ustępie powyżej,
            zostaną automatycznie przeniesieni do Darmowej Wersji Metrix. W przypadku, gdy nie dokonali uprzednio rejestracji w sposób określony w dziale IV
            ust. 1 lit. b) lub c), dostęp do Metrix zostaje zablokowany, a Użytkownik w celu uzyskania dostępu do Darmowej Wersji, powinien zarejestrować się w
            sposób określony w dziale IV ust. 1 lit. b).
          </li>
          <li>
            W celu realizacji płatności Usługodawca umożliwia skorzystanie z usług dostawcy usług płatniczych. W przypadku korzystania przez Usługobiorcę z
            pośrednictwa operatorów płatności online może być konieczne zaakceptowanie regulaminu świadczenia takich usług przez dany podmiot.
          </li>
        </ol>
      </li>
      <li>
        ABONAMENT SAMOODNAWIALNY
        <ol>
          <li>
            Usługa Abonamentu Samoodnawialnego jest świadczona o ile Użytkownik przy zakupie Abonamentu z miesięcznym Okresem Rozliczeniowym zaznaczy opcję
            samoodnowienia oraz wybierze sposób płatności kartą płatniczą . Zaznaczając opcję samoodnowienia Użytkownik wyraża zgodę na pobieranie przez
            Administratora z karty płatniczej lub w systemie operatora płatności ceny za kolejne Okresy Rozliczeniowe Abonamentu, w wysokości wskazanej w
            złożonym zamówieniu.
            <ol>
              <li>
                Płatność za kolejne miesięczne Okresy Rozliczeniowe, w ramach Abonamentu Samoodnawialnego jest realizowana poprzez pobranie przez Administratora
                ceny za kolejny Okres Rozliczeniowy. Cena za kolejny Okres Rozliczeniowy jest pobierana nie wcześniej niż 48 h przed i nie później niż 24h przed
                rozpoczęciem kolejnego Okresu Rozliczeniowego.
              </li>
              <li>
                Użytkownik może w każdym czasie zrezygnować z samoodnowienia poprzez odznaczenie opcji samoodnowienia na swoim Koncie, nie później, niż 48h.
                przed rozpoczęciem kolejnego Okresu Rozliczeniowego.
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        PRAWA AUTORSKIE
        <ol>
          <li>
            Korzystanie z Metrix na podstawie Regulaminu, nie skutkuje w żadnym zakresie nabyciem przez Użytkowników jakichkolwiek praw własności intelektualnej
            do Metrix. Zabronione jest naruszanie w jakikolwiek sposób praw własności intelektualnej do Metrix, w szczególności:
            <ol>
              <li>
                kopiowanie, modyfikowanie oraz transmitowanie elektronicznie lub w inny sposób Metrix lub jego części, a także poszczególnych utworów i baz
                danych, bez wyraźnej pisemnej zgody Usługodawcy,
              </li>
              <li>korzystanie z Metrix w sposób niezgodny z Regulaminem, </li>
              <li>
                niszczenie, uszkadzanie, usuwanie lub zmienianie danych informatycznych, tekstowych i graficznych Metrix lub zakłócanie działania w inny sposób.
              </li>
            </ol>
          </li>
          <li>Poprzez korzystanie z Metrix Użytkownik nie nabywa żadnych praw, ani też nie uzyskuje licencji do Metrix.</li>
          <li>
            W ramach korzystania z Metrix Użytkownik nie jest uprawniony, bez uprzedniej zgody Usługodawcy, do wykorzystywania oraz publikowania m.in. zrzutów
            ekranu, wycinków, zdjęć, pochodzących z Metrix.
          </li>
        </ol>
      </li>
      <li>
        BAZA DANYCH
        <ol>
          <li>
            W ramach korzystania z Metrix, zarejestrowany Użytkownik uzyskuje dostęp do Bazy Danych tj. zbioru danych (m.in. informacji, dokumentów, analiz)
            przekazanych przez Usługodawcę Użytkownikowi na cele korzystania z Metrix.
          </li>
          <li>Każdy Użytkownik ma prawo swobodnego dostępu i korzystania z Bazy Danych nieodpłatnie.</li>
          <li>
            Usługodawca korzysta z Bazy Danych w drodze umowy licencji. Baza Danych podlega ochronie na podstawie przepisów prawa polskiego: ustawy z dnia 27
            lipca 2001 r. o ochronie baz danych oraz przepisów ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych oraz Dyrektywy 96/9/WE
            Parlamentu Europejskiego i Rady z dnia 11 marca 1996 r. w sprawie ochrony prawnej baz danych.
          </li>
        </ol>
      </li>

      <li>
        ODPOWIEDZIALNOŚ&#262;
        <ol>
          <li>
            Usługodawca dokłada wszelkich starań, aby dane prezentowane były rzetelne, i poparte uprzednią analizą sytuacji na rynku. Jednocześnie Usługodawca
            nie ponosi odpowiedzialności za decyzje biznesowe, które zostały podjęte na bazie informacji udostępnionych w ramach Metrix.
          </li>
          <li>Usługodawca ma prawo w każdym czasie wyłączyć dostęp do wersji testowej Metrix bez podania uzasadnienia.</li>
          <li>
            Użytkownik ponosi pełną odpowiedzialność za swoje działania i zaniechania związane z korzystaniem z Metrix, w szczególności może ponosić
            odpowiedzialność odszkodowawczą względem Usługodawcy lub innych Użytkowników.
          </li>
          <li>
            Usługodawca nie ponosi odpowiedzialności za zakłócenia w funkcjonowaniu Metrix wywołane siłą wyższą, awarią sprzętu, przeciążeniem bazy danych i
            serwera.
          </li>
          <li>
            Usługodawca dokłada wszelkiej staranności w celu zapewnienia ciągłego funkcjonowania Metrix, jednak nie może zagwarantować jego nieprzerwanej
            dostępności. Usługodawca dochowa wszelkich starań, aby Metrix był dostępny na poziomie nie niższym niż 98 % w skali roku.
          </li>
          <li>Usługodawca odpowiada wyłącznie za szkody powstałe z winy umyślnej.</li>
          <li>
            Usługodawca nie ponosi odpowiedzialności za zachowania Użytkowników na Metrix , jak również za następstwa działań podjętych przez Użytkowników oraz
            osoby trzecie, a stanowiących naruszenie postanowień Regulaminu. Usługodawca w szczególności nie ponosi odpowiedzialności za wypłacalność
            Użytkowników oraz prawdziwość i rzetelność podawanych przez nich informacji.
          </li>
          <li>
            Usługodawca wyłącza swoją odpowiedzialność w zakresie w jakim jest to dopuszczalne w świetle obowiązujących przepisów prawnych, m.in. za:
            <ol>
              <li>
                wszelkie szkody wyrządzone osobom trzecim wskutek korzystania przez Użytkownika z usług w sposób sprzeczny z Regulaminem lub z przepisami prawa,
              </li>
              <li>wszelkie szkody wyrządzone przez osoby trzecie, którym Użytkownicy wbrew zapisom Regulaminu udostępnili dane z Konta,</li>
              <li>
                niedostępność Metrix z przyczyn niezależnych od Usługodawcy, w tym spowodowane działaniem siły wyższej czy nieprawidłowym działaniem sieci
                Internet,
              </li>
              <li>
                przerwy w dostępie do Metrix, w tym uniemożliwiające korzystanie z Metrix, spowodowane koniecznością usunięcia awarii, testowaniem sprzętu i
                oprogramowania, koniecznością konserwacji,
              </li>
              <li>
                szkody poniesione przez Użytkowników, spowodowane zablokowaniem Konta albo dostępu do części lub całości usług wobec każdego Użytkownika,
                którego działania są sprzeczne z powszechnie obowiązującym prawem, przepisami niniejszego Regulaminu, dobrymi obyczajami, jak również, jeżeli
                działania te zagrażają lub naruszają interes Usługodawcy lub innych Użytkowników.
              </li>
            </ol>
          </li>
          <li>
            Usługodawca ponosi odpowiedzialność tylko w zakresie usług świadczonych drogą elektroniczną i na zasadach określonych w przepisach o świadczeniu
            usług drogą elektroniczną oraz w Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony osób
            fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej:
            &bdquo;RODO”).
          </li>
        </ol>
      </li>
      <li>
        WYPOWIEDZENIE UMOWY I BLOKADA KONTA
        <ol>
          <li>
            W przypadku naruszenia postanowień Regulaminu bądź podania nieprawdziwych lub nieaktualnych danych Usługodawca ma prawo:
            <ol>
              <li>wezwać Użytkownika do usunięcia nieprawidłowości w wyznaczonym przez siebie terminie;</li>
              <li>zablokować Konto;</li>
              <li>wypowiedzieć umowę o świadczenie usług w trybie natychmiastowym.</li>
            </ol>
          </li>
          <li>W przypadku zablokowania Konta, o którym mowa w ust. 1 lit. b. wszelkie funkcjonalności Metrix zostaną wyłączone.</li>
          <li>
            W przypadkach określonych w ust. 1 i 2 oraz w dziale XIV ust. 3, kwota Abonamentu zostanie zwrócona proporcjonalnie do okresu korzystania z
            Abonamentu, nie później, niż w terminie 14 dni od dnia zablokowania Abonamentu. Zwrot płatności zostanie dokonany przy użyciu takich samych sposobów
            płatności, jakie zostały użyte przez Użytkownika Głównego przy dokonywaniu płatności za Abonament.
          </li>
          <li>W przypadku cofnięcia zgody na którykolwiek z warunków Regulaminu, Usługodawca zablokuje Konto Użytkownika. </li>
          <li>W dowolnym momencie Użytkownik ma prawo rozwiązać umowę o świadczenie usługi Metrix poprzez zgłoszenie żądania usunięcia swojego Konta</li>
        </ol>
      </li>
      <li>
        OCHRONA DANYCH OSOBOWYCH
        <ol>
          <li>
            Administratorem danych osobowych Użytkowników jest Trans.info spółka z ograniczoną odpowiedzialnością z siedzibą w Wysokiej, posiadająca adres ul.
            Chabrowa 4, 52-200 Wysoka, Polska, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla Wrocławia
            &ndash; Fabrycznej we Wrocławiu VI Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000751609, NIP: 8961581580, REGON: 381491570,
            posiadająca kapitał zakładowy w wysokości 5.000,00 PLN.
          </li>
          <li>
            Dane Użytkowników są przetwarzane zgodnie z postanowieniami RODO i polskimi przepisami w zakresie ochrony danych osobowych.
            <ol>
              <li>Dane są przetwarzane w celu przygotowania i realizacji usługi Metrix - zobowiązania wynikające z umowy (art. 6 ust. 1 lit b RODO); </li>
              <li>
                jeśli zajdzie taka potrzeba przetwarzamy dane Użytkowników, do celów wynikających z prawnie uzasadnionych interesów realizowanych przez
                Usługodawcę lub stronę trzecią (art. 6 ust. 1 lit f RODO);
              </li>
              <li>
                Przetwarzamy dane Użytkowników, w celu ochrony prawnie uzasadnionych interesów własnych lub stron trzecich. Przykłady tego obejmują:
                przetwarzanie danych w ramach Grupy Trans.eu S.A., do udzielenia odpowiedzi na zadane pytanie/reklamację, przesyłania biuletynu informacyjnego
                Administratora dot. zmian w oprogramowaniu, Regulaminie itp., na rzecz zapewnienia bezpieczeństwa informatycznego Administratora; w celu badania
                satysfakcji klientów, dochodzenia roszczeń oraz obrony przed roszczeniami, w marketingu bezpośrednim produktów i usług Administratora oraz
                podmiotów z grupy Trans.eu, a także innych podmiotów, na rzecz których Administrator świadczy usługi na mocy odrębnych umów, do wewnętrznych
                celów administracyjnych Administratora, takich jak przygotowanie statystyk.
              </li>
              <li>przetwarzamy dane na podstawie zgody Użytkowników (art. 6 ust. 1 lit a RODO), udzielonej w określonych celach.</li>
            </ol>
          </li>
          <p>Zgoda może zostać wycofana w dowolnym momencie. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania danych do czasu wycofania zgody. </p>
          <li>
            Dane mogą być udostępniane innym odbiorcom w celu wykonania umowy z Użytkownikiem, w celu wykonania ciążącego na Usługodawcy obowiązku prawnego, w
            oparciu o zgodę Użytkownika lub dla celów wynikających z prawnie uzasadnionych interesów Administratora lub strony trzeciej. Odbiorcami danych mogą
            być w szczególności: upoważnieni pracownicy Usługodawcy, podmioty z Grupy Trans.eu S.A., usługi płatnicze oraz inne podmioty ustawowo upoważnione do
            odbioru danych Użytkowników na podstawie odpowiednich przepisów prawa. Dane mogą być również przekazane innym podmiotom realizującym działania w
            imieniu i na rzecz Usługodawcy na mocy odrębnych umów, w szczególności do podmiotów świadczących usługi wsparcia IT, czy podmiotów audytujących.
            Dostęp do danych jest przekazywany do tych odbiorców, którzy potrzebują przedmiotowych danych celem realizacji zobowiązań umownych bądź/i prawnych.
          </li>
          <li>
            Dane Użytkowników będą przetwarzane przez okres niezbędny do realizacji celów przetwarzania wskazanych w ust. 2 tj.:
            <ol>
              <li>
                w zakresie realizacji usługi Metrix - do czasu zakończenia jej realizacji, a po tym czasie przez okres wymagany przez przepisy prawa lub dla
                realizacji ewentualnych roszczeń,
              </li>
              <li>w zakresie przetwarzania realizowanego w oparciu o zgodę niezwłocznie po zgłoszeniu przez państwo żądania usunięcia danych,</li>
              <li>
                do czasu wypełnienia prawnie uzasadnionych interesów Usługodawcy stanowiących podstawę tego przetwarzania lub do czasu wniesienia sprzeciwu
                wobec takiego przetwarzania, o ile nie występują prawnie uzasadnione podstawy dalszego przetwarzania danych.
                {' '}
              </li>
            </ol>
          </li>
          <li>
            Użytkownicy mają prawo:
            <ol>
              <li>do żądania dostępu do swoich danych, a także żądania ich sprostowania, ograniczenia ich przetwarzania lub ich usunięcia.</li>
              <li>
                w zakresie, w jakim podstawą do przetwarzania danych jest zgoda, Użytkownicy mają prawo - w dowolnym momencie - do wycofania wcześniej
                udzielonej zgody na przetwarzanie danych. Z tym zastrzeżeniem, że wycofanie zgody nie będzie miało wpływu zgodność z prawem przetwarzania,
                którego dokonano na podstawie zgody przed jej wycofaniem.
                {' '}
              </li>
              <li>
                żądania przeniesienia dostarczonych Usługodawcy danych przetwarzanych w celu zawarcia i wykonywania umowy lub przetwarzanych na podstawie zgody.
                Uprawnienie nie dotyczy danych, które stanowią tajemnicę przedsiębiorstwa,
              </li>
              <li>
                wniesienia skargi do organu nadzorczego, którym w Rzeczypospolitej Polskiej jest Prezes Urzędu Ochrony Danych Osobowych, jeżeli Użytkownicy
                uznają, że przetwarzanie ich danych narusza przepisy RODO.
              </li>
            </ol>
            <p>Ponadto Użytkownicy mają prawo wnieść w dowolnym momencie sprzeciw wobec przetwarzania danych:</p>
            <ol>
              <li>
                z przyczyn związanych ze szczególną sytuacją, gdy Usługodawca przetwarza dane do celów wynikających z prawnie uzasadnionych interesów, w tym
                profilowania.
              </li>
            </ol>
            <p>Usługodawca realizując przedmiotowe prawa może zażądać dodatkowych informacji niezbędnych do potwierdzenia tożsamości.</p>
          </li>
          <li>
            W zakresie, w jakim przetwarzanie danych Użytkowników następuje w celu realizacji usługi Metrix, podanie danych jest warunkiem świadczenia usługi
            Metrix. Podanie danych ma charakter dobrowolny, jednak konsekwencją nie podania tych danych będzie brak możliwości świadczenia usługi Metrix.
            {' '}
          </li>
          <li>
            Szczegółowe informacje o przetwarzaniu danych osobowych określa Polityka prywatności, dostępna pod adresem:
            https://trans.info/pl/page/polityka-prywatnosci.
          </li>
        </ol>
      </li>
      <li>
        REKLAMACJE
        <ol>
          <li>
            Wszelkie reklamacje, w tym związane z korzystaniem z Metrix, dokonaniem płatności, funkcjonowaniem itp. należy składać pisemnie na adres: Trans.info
            Sp. z o. o., ul. Chabrowa 4, 52-200 Wysoka lub e-mailem na adres redakcja@trans.info
          </li>
          <li>
            Reklamacje będą rozpatrywane w terminie 30 dni od dnia otrzymania. Odpowiedź na reklamację zostanie przesłana na adres e-mail wskazany przez
            Użytkownika, a w przypadku reklamacji złożonej pocztą tradycyjną &ndash; na adres wskazany przez Użytkownika.
          </li>
          <li>
            Poza trybem wskazanym w ust. 1 i 2 powyżej, Usługodawca nie przewiduje możliwości skorzystania z pozasądowych sposobów rozpatrywania reklamacji i
            dochodzenia roszczeń.
          </li>
        </ol>
      </li>
      <li>
        ZMIANA REGULAMINU I CENNIKA
        <ol>
          <li>Usługodawca ma prawo do zmiany Cennika w każdym czasie.</li>
          <li>
            Usługodawca poinformuje o zmianie Cennika z 14 dniowym wyprzedzeniem poprzez wysłanie Użytkownikowi wiadomości e-mail na adres wskazany podczas
            rejestracji. Zmiany wejdą w życie po upływie 14 dni od wysłania zawiadomienia. Nie spowoduje to zmiany ceny opłaconego przed wejściem w życie zmiany
            Abonamentu.
          </li>
          <li>
            W przypadku braku akceptacji zmian w Cenniku, o których mowa w ustępie powyżej, Użytkownik - w terminie 14 dni od od opublikowania zmian w sposób
            określony w ustępie powyżej, ma prawo wypowiedzieć umowę w trybie natychmiastowym.
          </li>
          <li>
            Usługodawca ma prawo wprowadzać okresowe rabaty, promocje i inne czasowe obniżki stawek z Cennika. Zmiany te nie stanowią zmiany Cennika w
            rozumieniu ust. 3 i nie powodują możliwości wypowiedzenia umowy.
          </li>
          <li>
            Usługodawca zastrzega sobie prawo do zmiany Regulaminu w przypadku wystąpienia przynajmniej jednej z poniżej wskazanych ważnych przyczyn:
            <ol>
              <li>
                zmiana przepisów prawa regulujących świadczenie usług drogą elektroniczną przez Usługodawcę wpływająca na wzajemne prawa i obowiązki określone w
                Regulaminie;
              </li>
              <li>
                konieczność dostosowania działalności Usługodawcy do nakazów, orzeczeń, postanowień lub wytycznych wynikających z:
                <ol>
                  <li>
                    decyzji właściwego w zakresie działalności Usługodawcy organu administracji publicznej wpływającej na wzajemne prawa i obowiązki określone w
                    Regulaminie;
                  </li>
                  lub
                  <li>
                    orzeczenia sądowego znajdującego zastosowanie w zakresie działalności Usługodawcy wpływającego na wzajemne prawa i obowiązki określone w
                    Regulaminie;
                  </li>
                </ol>
              </li>

              <li>
                zmiana sposobu świadczenia usług drogą elektroniczną przez Usługodawcę spowodowana wyłącznie względami technicznymi lub technologicznymi (w
                szczególności aktualizacji wymagań technicznych);
              </li>
              <li>połączenia, podziału albo przekształcenia Usługodawcy lub zmiany innych danych Usługodawcy.</li>
            </ol>
          </li>
          <li>
            Zmiana zakresu lub zasad świadczenia przez Usługodawcę usług, do których stosują się postanowienia Regulaminu, poprzez wprowadzenie nowych,
            modyfikację lub wycofanie przez Usługodawcę dotychczasowych funkcjonalności oraz wprowadzenie promocji nie stanowią zmiany Regulaminu.
          </li>
          <li>
            Usługodawca publikuje zmiany na stronie
            <a href="https://trans.info/metrix/">https://trans.info/metrix/</a>
            . Zmiany wchodzą w życie z chwilą umieszczenia zmienionego tekstu Regulaminu na
            stronie internetowej Usługodawcy.
          </li>
        </ol>
      </li>
      <li>
        POSTANOWIENIA KO&#323;COWE
        <ol>
          <li>Wszelkie spory powstałe w związku z korzystaniem z Metrix, rozstrzygane będą przez Sąd właściwy dla siedziby Usługodawcy.</li>
          <li>Prawem właściwym dla niniejszego Regulaminu i usług świadczonych w ramach Metrix jest prawo polskie.</li>
        </ol>
      </li>
    </ol>
  </div>
);
