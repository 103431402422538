/* eslint-disable */
import React from 'react';
import WelcomeUserForm from '../../containers/User/components/WelcomeUserForm';

const WelcomeForm = () => (
  <div className="modal_welcome">
    <p className="modal_welcome__title">Welcome</p>
    <div className="modal_welcome__form">
      <h4>Ankieta użytkownika</h4>
      <WelcomeUserForm />
    </div>
  </div>
);

export default WelcomeForm;
