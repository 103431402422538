/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import { Area, Line, AreaChart, ResponsiveContainer, Tooltip, YAxis, XAxis, CartesianGrid, ReferenceLine } from 'recharts';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import Cookies from 'js-cookie';
import { convertDateWithoutYear } from '../../utils/converters';
import DateModal from '../../components/modals/containers/DateModal';
import IndicatorBox, { IndicatorBoxHeader } from '../Charts/components/IndicatorBox';
import Loader from '../../components/loaders/Loader';
import Dropdown from '../../components/form/Dropdown';
import currencies from './currencies.json';

const CustomTooltip = ({ active, payload, currency, date }) => {
  if (active) {
    return (
      <div className="recharts_custom__tooltip">
        <div className="curr">
          <p>{`${date}:`}</p>
          {`${payload[0].payload.name}`}
        </div>
        <div className="curr">
          <p className="All">{currency}</p>
          <span className="FTL">{`${payload[0].value.toFixed(4)}`}</span>
        </div>
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
    })
  ),
  currency: PropTypes.bool,
  date: PropTypes.string,
};

CustomTooltip.defaultProps = {
  active: false,
  payload: null,
};

const Currency = props => {
  const { t } = props;
  const lang = i18next.language.slice(0, 2) || 'pl';

  const [active, setActive] = useState(0);

  const today = new Date().toISOString().slice(0, 10);

  const date = new Date();
  date.setMonth(date.getMonth() - 12);
  const yearAgo = `${date.getUTCFullYear()}-${`0${date.getUTCMonth() + 1}`.slice(-2)}-${`0${date.getUTCDate()}`.slice(-2)}`;

  const [startDate, setstartDate] = useState(yearAgo);
  const [endDate, setendDate] = useState(today);
  const [currencyFrom, setcurrencyFrom] = useState(Cookies.get('currencyFrom') || 'USD');
  const [currencyTo, setcurrencyTo] = useState(Cookies.get('currencyTo') || 'EUR');
  const [hasError, setErrors] = useState(false);
  const [dane, setData] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [avgSwitchValue, setavgSwitchValue] = useState(false);
  const [maxSwitchValue, setmaxSwitchValue] = useState(false);
  const [minSwitchValue, setminSwitchValue] = useState(false);

  async function fetchData() {
    // if we check EUR to EUR rest response error - this if dont show error on charts
    if (currencyFrom === 'EUR' && currencyTo === 'EUR') {
      const rates = { stop: { EUR: 1.0 }, start: { EUR: 1.0 } };
      const data = { rates, start_at: startDate, base: 'PLN', end_at: endDate };
      const datax = Array.from(
        Object.keys(data.rates).map(key => {
          return { name: key, bch: data.rates[key][currencyTo] };
        })
      );
      setData(datax);
      setisLoading(false);
    } else {
      const res = await fetch(`https://api.exchangeratesapi.io/history?start_at=${startDate}&end_at=${endDate}&symbols=${currencyTo}&base=${currencyFrom}`);
      res
        .json()
        .then(data => {
          const datax = Array.from(
            Object.keys(data.rates).map(key => {
              return { name: key, bch: data.rates[key][currencyTo] };
            })
          );
          setData(datax);
          setisLoading(false);
        })
        .catch(err => setErrors(err));
    }
  }

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, currencyFrom, currencyTo]);

  // render() {
  // const { isLoading, dane, error, avgSwitchValue, medSwitchValue, minSwitchValue, maxSwitchValue } = this.state;
  // const currencyNames = getCurrencyNames();
  const compare = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const genreA = a.name;
    const genreB = b.name;

    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
  };

  const sortd = dane.sort(compare);

  const minY = !isLoading && sortd.reduce((min, p) => (p.bch < min ? p.bch : min), sortd[0].bch);
  const maxY = !isLoading && sortd.reduce((max, p) => (p.bch > max ? p.bch : max), sortd[0].bch);

  // eslint-disable-next-line
  const avgValues = sortd.map(({ bch }) => bch);
  const average = avgValues.reduce((a, b) => a + b, 0) / avgValues.length;

  // const medianValues = avgValues.sort((a, b) => a - b);
  /*eslint no-bitwise: ["error", { "allow": [">>"] }] */
  // const median = (medianValues[(medianValues.length - 1) >> 1] + medianValues[medianValues.length >> 1]) / 2;

  const onDateChange = (start, end) => {
    const dateStart = `${start.getUTCFullYear()}-${`0${start.getUTCMonth() + 1}`.slice(-2)}-${`0${start.getUTCDate()}`.slice(-2)}`;
    const dateEnd = `${end.getUTCFullYear()}-${`0${end.getUTCMonth() + 1}`.slice(-2)}-${`0${end.getUTCDate()}`.slice(-2)}`;
    setstartDate(dateStart);
    setendDate(dateEnd);
  };

  return (
    <div className="chart-header p-0">
      <Row className="m-0 align-items-end mb-2 main_filters_group">
        <div className="btn-group-select px-2 mr-4">
          <p>
            {t('label.date')}
            :
          </p>
          <DateModal
            initStartProp={new Date(startDate)}
            initEndProp={new Date(endDate)}
            onDateChange={(start, end) => onDateChange(start, end)}
            lang={lang}
            type="currency"
          />
        </div>

        <div className="group-dropdown group-dropdown--smaller mb-1 flex-wrap">
          <p className="group-dropdown__label d-none d-md-block">{`${t('label.currency')}:`}</p>
          <div className="btn-group-select pl-2 ">
            <p className="btn-group-select__label">{`${t('label.currencyFrom')}:`}</p>
            <Dropdown
              active={active}
              list={currencies}
              withTextInput
              withActive
              textValue={currencyFrom}
              onSelect={index => {
                setcurrencyFrom(currencies[index].label);
                setActive(index);
                Cookies.set('currencyFrom', currencies[index].label, { expires: 30 });
              }}
              currency
              lang={lang}
            />
          </div>
          <div className="btn-group-select pl-2">
            <p className="btn-group-select__label">{`${t('label.currencyTo')}:`}</p>
            <Dropdown
              active={active}
              list={currencies}
              withTextInput
              withActive
              textValue={currencyTo}
              onSelect={index => {
                setcurrencyTo(currencies[index].label);
                setActive(index);
                Cookies.set('currencyTo', currencies[index].label, { expires: 30 });
              }}
              lang="pl"
            />
          </div>
        </div>
        <p data-tip={t('tooltips.currency')} data-class="tooltips_info" className="px-2 ml-auto">
          {t('tooltips.info')}
        </p>
        <ReactTooltip />
      </Row>

      <Row className="indicator_wrapper px-2 boxes-3">
        <IndicatorBox
          title="Average"
          // values={[average.toFixed(3), average.toFixed(3)]}
          // trend="up"
          // dataExist
          data={[
            {
              switchName: 'avg',
              value: average.toFixed(3),
              status: avgSwitchValue,
            },
          ]}
          // changePercent={12}
          // change={3.3}
          name="avgSwitchValue"
          // switchValue={avgSwitchValue}
          switchHandle={() =>
            setavgSwitchValue(prevState => {
              return !prevState;
            })
          }
          currency={currencyTo}
          // showCalculateValues={false}
          // active
          // currencies
        >
          <IndicatorBoxHeader title={t('label.average')} />
        </IndicatorBox>
        <IndicatorBox
          title="Minimum"
          data={[
            {
              switchName: 'avg',
              value: parseFloat(minY).toFixed(3),
              status: minSwitchValue,
            },
          ]}
          values={[parseFloat(minY).toFixed(3), parseFloat(minY).toFixed(3)]}
          name="minSwitchValue"
          // switchValue={minSwitchValue}
          switchHandle={() =>
            setminSwitchValue(prevState => {
              return !prevState;
            })
          }
          currency={currencyTo}
          // showCalculateValues={false}
          // dataExist
          // currencies
        >
          <IndicatorBoxHeader title={t('label.minimum')} />
        </IndicatorBox>
        <IndicatorBox
          title="Maximum"
          data={[
            {
              switchName: 'max',
              value: parseFloat(maxY).toFixed(3),
              status: maxSwitchValue,
            },
          ]}
          // values={[parseFloat(maxY).toFixed(3), parseFloat(maxY).toFixed(3)]}
          name="maxSwitchValue"
          // switchValue={maxSwitchValue}
          switchHandle={() =>
            setmaxSwitchValue(prevState => {
              return !prevState;
            })
          }
          currency={currencyTo}
          // showCalculateValues={false}
          // dataExist
          // currencies
        >
          <IndicatorBoxHeader title={t('label.maximum')} />
        </IndicatorBox>
      </Row>
      <Row>
        <Card>
          <CardBody className="dashboard__card-widget">
            <div>
              <h5 className="bold-text">{`${currencyFrom}/${currencyTo}`}</h5>
              {/* <h5 className="subhead">{`${currencyFrom} course`}</h5> */}
            </div>
            {hasError ? <p>error</p> : null}
            {!isLoading ? (
              <div className="dashboard__total dashboard__total--area">
                {/* <TrendingUpIcon className="dashboard__trend-icon" /> */}
                <ResponsiveContainer height={600}>
                  <AreaChart data={dane} margin={{ top: 0, left: 0, bottom: 0 }}>
                    <YAxis type="number" domain={[minY - 0.001, maxY + 0.001]} tickFormatter={value => value.toFixed(3)} />
                    <XAxis dataKey="name" tickFormatter={convertDateWithoutYear} />
                    <CartesianGrid />
                    <Area
                      name="EUR"
                      type="linear"
                      dataKey="bch"
                      stroke="#075397"
                      strokeWidth={1}
                      fill="transparent"
                      dot={{ stroke: '#075397', strokeWidth: 1, fill: '#fff', fillOpacity: 1, r: 1 }}
                      activeDot={{ stroke: '#075397', strokeWidth: 2, fill: '#fff', r: 2 }}
                    />
                    <Line dataKey="value" activeDot={{ stroke: '#075397', strokeWidth: 5, r: 10 }} />
                    {/* <Brush dataKey="name" /> */}
                    {avgSwitchValue && (
                      <ReferenceLine
                        y={average}
                        label={{ position: 'top', value: 'Avg', fill: '#70bbfd', fontSize: 14 }}
                        stroke="#70bbfd"
                        strokeDasharray="4 3"
                        strokeWidth="3"
                      />
                    )}
                    {minSwitchValue && (
                      <ReferenceLine
                        y={minY}
                        label={{ position: 'top', value: 'Min', fill: '#70bbfd', fontSize: 14 }}
                        stroke="#70bbfd"
                        strokeDasharray="4 3"
                        strokeWidth="3"
                      />
                    )}
                    {maxSwitchValue && (
                      <ReferenceLine
                        y={maxY}
                        label={{ position: 'bottom', value: 'Max', fill: '#70bbfd', fontSize: 14 }}
                        stroke="#70bbfd"
                        strokeDasharray="4 3"
                        strokeWidth="3"
                      />
                    )}

                    <Tooltip
                      isAnimationActive={false}
                      content={<CustomTooltip tooltip fillType="all" currency={`${currencyFrom}/${currencyTo}`} date={lang === 'pl' ? 'Data' : 'Date'} />}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <Loader />
            )}
          </CardBody>
        </Card>
      </Row>
    </div>
  );
  // }
};

export default withTranslation('common')(withRouter(Currency));
