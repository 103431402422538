/* eslint-disable */
import React, { lazy, Suspense } from 'react';
import { QueryRenderer } from 'react-relay';
import environment from 'relay-env';
import getNutsActivity from '../../../../relay/queries/nuts';
import getCountriesActivity from '../../../../relay/queries/country';
import Loader from '../../../../components/loaders/Loader';
import MapLoader from '../../../../components/loaders/MapLoader';
import max from 'lodash.max';
import min from 'lodash.min';

const DrawPolygons = lazy(() => import('./DrawPolygons'));

const PolygonsData = ({ mapType, timeType, weight = 'Bus', handleActiveRegion, chartType, direction, dateTime }) => {
  const keyName = `${chartType}${weight}`;
  const queryVariables = {
    direction,
    date: dateTime,
  };
  const getDataInformation = (data = []) => {
    const values = data.reduce((acc, item) => acc.concat([item[keyName]]), []);
    // console.warn('keyName', keyName, values, data);
    return {
      max: max(values),
      min: min(values),
    };
  };

  return (
    <QueryRenderer
      environment={environment}
      query={mapType ? getNutsActivity : getCountriesActivity}
      fetchPolicy="store-and-network"
      variables={queryVariables}
      render={({ qerror, props }) => {
        if (qerror) {
          return <div>Error!</div>;
        }
        if (!props) {
          return <MapLoader />;
        }
        return (
          <Suspense fallback={<Loader />}>
            <DrawPolygons
              mapType={mapType}
              direction={direction}
              data={props[mapType ? 'getNutsActivity' : 'getCountriesActivity']}
              keyName={keyName}
              weight={weight}
              chartType={chartType}
              handleActiveRegion={handleActiveRegion}
              chartData={getDataInformation(props[mapType ? 'getNutsActivity' : 'getCountriesActivity'])}
            />
          </Suspense>
        );
      }}
    />
  );
};

export default PolygonsData;
