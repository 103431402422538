/* eslint-disable */
import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation, useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'reactstrap';
import moment from 'moment';
import axiosInstance from '../../../utils/axios';
import fv from '../../../shared/img/icons/fv.svg';
import { ProfileContext } from '../../../context/Profile';
import Input from '../../../components/form/Input';
import Divider from '../../../components/decorations/Divider';
import SwitchBtn from '../../../components/form/SwitchBtn';
import Button from '../../../components/form/Button';
import useNotify from '../../../utils/hooks/useNotification';

const Payments = () => {
  const {
    profile: { cardInfo },
  } = useContext(ProfileContext);
  const notify = useNotify();
  const { t } = useTranslation('common');
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    axiosInstance
      .get('/users/invoices')
      .then(resp => {
        setInvoices(resp.data.invoices);
      })
      .catch(err => console.warn('Invoice', err));
  }, []);

  return (
    <div className="payments">
      <PaymentsInformation />
      <CreditCard cardInfo={cardInfo} />
      <h4>{t('label.invoices')}</h4>
      <ul className="invoice-list">
        {invoices.length === 0
          ? t('msg.invoiceListEmpty')
          : (invoices || []).map(({ name, id, view_url }, index) => (
              <li key={id} className="invoice-list_item">
                <img src={fv} className="invoice-list_item-icon" data-testid={`icon.fv-${index}`} alt="" />
                <p className="invoice-list_item-text">{`${name}`}</p>
                <a href={view_url} rel="noopener noreferrer" target="_blank">
                  {t('button.see')}
                </a>
              </li>
            ))}
      </ul>
    </div>
  );
};
const PaymentsInformation = () => {
  const {
    profile: { subscriptionUntil, enabledRecurrentPayments, role, cardInfo },
    profileUpdate,
  } = useContext(ProfileContext);
  const { t, i18n } = useTranslation('common');
  const [paymentsFailed, setPaymentsFailed] = useState(false);

  useEffect(() => {
    axiosInstance
      .get('/users/profile/payments')
      .then(resp => {
        setPaymentsFailed(resp.data.paymentsRejected);
      })
      .catch(err => console.error('Payments rejected', err));
  }, []);
  if (role !== 'PRO_HEAD') return null;

  return subscriptionUntil !== null ? (
    <React.Fragment>
      <Container>
        <Row>
          {`${t('label.subscriptionValidTo')}: ${moment(subscriptionUntil)
            .locale(i18n.language)
            .format('ll')}`}
        </Row>
        {cardInfo !== null && (
          <Row className="mt-3 d-flex align-items-center">
            {t('label.recurrentPayments')}
            <SwitchBtn
              onClick={() => {
                profileUpdate({ enabledRecurrentPayments: !enabledRecurrentPayments });
              }}
              leftSide={t('label.disable')}
              rightSide={t('label.enable')}
              className="ml-3"
              checked={enabledRecurrentPayments}
            />
          </Row>
        )}
        {paymentsFailed && (
          <Row className="mt-1 d-flex align-items-center">
            {t('msg.recurrentPaymentsRejected')}
            <Button
              className="ml-lg-3 mt-md-1"
              text="tryAgain"
              onClick={() => {
                axiosInstance
                  .post('payments/recurrent')
                  .then(resp => {
                    profileUpdate({});
                  })
                  .catch(err => {
                    console.error('Payments rejected', err);
                    notify.show(t('msg.recurrectPaymentFailed'));
                  });
              }}
            />
          </Row>
        )}
      </Container>
      <Divider />
    </React.Fragment>
  ) : null;
};
const CreditCard = ({ cardInfo }) => {
  const { t } = useTranslation('common');
  if (cardInfo === null) {
    return null;
  }

  return (
    <div>
      <div className="form-group form-grid input-big">
        <Container>
          <Row noGutters>
            <Input disabled label={t('label.cardNumber')} value={cardInfo.mask} />
          </Row>
          <Row>
            <Col xs={5}>
              <Input disabled label={t('label.currency')} value={cardInfo.currency} />
            </Col>
            <Col xs={7}>
              <Input disabled label={t('label.validDate')} value={cardInfo.cardDate} />
            </Col>
          </Row>
        </Container>
      </div>
      <Divider />
    </div>
  );
};

Payments.propTypes = {
  t: PropTypes.func,
};

export default withTranslation('common')(Payments);
