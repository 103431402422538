/* eslint-disable */
import React from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip, YAxis, XAxis, CartesianGrid, ReferenceLine } from 'recharts';
import { useTranslation } from 'react-i18next';
import { convertDateWithoutYear } from '../../utils/converters';
import CustomTooltip from '../Charts/components/CustomTooltip';
import prepareChartValues from '../Charts/utils/prepareChartValues';

export default ({ data, oilSource, fuelType, min, minY, max, maxY, avg, average, med, median }) => {
  const { t } = useTranslation('common');

  return (
    <ResponsiveContainer height={600}>
      <AreaChart data={data} margin={{ top: 0, left: 0, bottom: 0 }}>
        {/* <YAxis type="number" domain={[minY - 0.001, maxY + 0.001]} tickFormatter={value => value.toFixed(3)} /> */}
        <XAxis dataKey="date" tickFormatter={convertDateWithoutYear} padding={{ left: 0, right: 20 }} />
        <YAxis padding={{ bottom: 0, top: 10 }} domain={['auto', 'auto']} />
        <CartesianGrid />
        {oilSource === 'brent' && (
          <Area
            name="EUR"
            type="linear"
            dataKey="brent"
            stroke="#075397"
            strokeWidth={3}
            fill="transparent"
            dot={{ stroke: '#70bbfd', strokeWidth: 3, fill: '#fff', fillOpacity: 1, r: 4 }}
            activeDot={{ stroke: '#70bbfd', strokeWidth: 3, fill: '#fff', r: 6 }}
          />
        )}
        {oilSource === 'opec' && (
          <Area
            name="EUR"
            type="linear"
            dataKey="opec"
            stroke="#075397"
            strokeWidth={3}
            fill="transparent"
            dot={{ stroke: '#70bbfd', strokeWidth: 3, fill: '#fff', fillOpacity: 1, r: 4 }}
            activeDot={{ stroke: '#70bbfd', strokeWidth: 3, fill: '#fff', r: 6 }}
          />
        )}
        {oilSource === 'wti' && (
          <Area
            name="EUR"
            type="linear"
            dataKey="wti"
            stroke="#075397"
            strokeWidth={3}
            fill="transparent"
            dot={{ stroke: '#70bbfd', strokeWidth: 3, fill: '#fff', fillOpacity: 1, r: 4 }}
            activeDot={{ stroke: '#70bbfd', strokeWidth: 3, fill: '#fff', r: 6 }}
          />
        )}
        {/* fuel Types */}
        {fuelType === 'diesel' && (
          <Area
            name="EUR"
            type="linear"
            dataKey="diesel"
            stroke="#075397"
            strokeWidth={3}
            fill="transparent"
            dot={{ stroke: '#70bbfd', strokeWidth: 3, fill: '#fff', fillOpacity: 1, r: 4 }}
            activeDot={{ stroke: '#70bbfd', strokeWidth: 3, fill: '#fff', r: 6 }}
          />
        )}
        {fuelType === 'gas95' && (
          <Area
            name="EUR"
            type="linear"
            dataKey="gas95"
            stroke="#075397"
            strokeWidth={3}
            fill="transparent"
            dot={{ stroke: '#70bbfd', strokeWidth: 3, fill: '#fff', fillOpacity: 1, r: 4 }}
            activeDot={{ stroke: '#70bbfd', strokeWidth: 3, fill: '#fff', r: 6 }}
          />
        )}
        {fuelType === 'lpg' && (
          <Area
            name="EUR"
            type="linear"
            dataKey="lpg"
            stroke="#075397"
            strokeWidth={3}
            fill="transparent"
            dot={{ stroke: '#70bbfd', strokeWidth: 3, fill: '#fff', fillOpacity: 1, r: 4 }}
            activeDot={{ stroke: '#70bbfd', strokeWidth: 3, fill: '#fff', r: 6 }}
          />
        )}

        {min && (
          <ReferenceLine
            y={minY}
            label={{ position: 'top', value: 'Min', fill: '#70bbfd', fontSize: 14 }}
            stroke="#70bbfd"
            strokeDasharray="4 3"
            strokeWidth="3"
          />
        )}
        {max && (
          <ReferenceLine
            y={maxY}
            label={{ position: 'top', value: 'Max', fill: '#70bbfd', fontSize: 14 }}
            stroke="#70bbfd"
            strokeDasharray="4 3"
            strokeWidth="3"
          />
        )}
        {avg && (
          <ReferenceLine
            y={average}
            label={{ position: 'top', value: 'Avg', fill: '#70bbfd', fontSize: 14 }}
            stroke="#70bbfd"
            strokeDasharray="4 3"
            strokeWidth="3"
          />
        )}
        {med && (
          <ReferenceLine
            y={median}
            label={{ position: 'top', value: 'Med', fill: '#70bbfd', fontSize: 14 }}
            stroke="#70bbfd"
            strokeDasharray="4 3"
            strokeWidth="3"
          />
        )}

        {/* <Brush dataKey="name" /> */}

        {/* <Tooltip /> */}

        <Tooltip isAnimationActive={false} content={<CustomTooltip tooltip chartName="fuel" chart={data} fillType="all" date={t('label.date')} />} />
      </AreaChart>
    </ResponsiveContainer>
  );
};
