/**
 * @flow
 * @relayHash d9c147d4784c58361b20b751fa08e88b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type nutsActivityQueryVariables = {|
  region: string,
  periodsLimit?: ?number,
  direction: string,
|};
export type nutsActivityQueryResponse = {|
  +getLatestNutActivity: $ReadOnlyArray<{|
    +createDate: any,
    +supplyLtl: ?number,
    +supplyFtl: ?number,
    +supplyBus: ?number,
    +demandLtl: ?number,
    +demandFtl: ?number,
    +demandBus: ?number,
    +priceFtl: ?number,
    +priceLtl: ?number,
    +priceBus: ?number,
  |}>
|};
export type nutsActivityQuery = {|
  variables: nutsActivityQueryVariables,
  response: nutsActivityQueryResponse,
|};
*/


/*
query nutsActivityQuery(
  $region: String!
  $periodsLimit: Float
  $direction: String!
) {
  getLatestNutActivity(region: $region, periodsLimit: $periodsLimit, direction: $direction) {
    createDate
    supplyLtl
    supplyFtl
    supplyBus
    demandLtl
    demandFtl
    demandBus
    priceFtl
    priceLtl
    priceBus
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "region",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "periodsLimit",
    "type": "Float",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "direction",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getLatestNutActivity",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "direction",
        "variableName": "direction"
      },
      {
        "kind": "Variable",
        "name": "periodsLimit",
        "variableName": "periodsLimit"
      },
      {
        "kind": "Variable",
        "name": "region",
        "variableName": "region"
      }
    ],
    "concreteType": "Activity",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "createDate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyLtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyFtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supplyBus",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandLtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandFtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "demandBus",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "priceFtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "priceLtl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "priceBus",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "nutsActivityQuery",
    "type": "Root",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "nutsActivityQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "nutsActivityQuery",
    "id": null,
    "text": "query nutsActivityQuery(\n  $region: String!\n  $periodsLimit: Float\n  $direction: String!\n) {\n  getLatestNutActivity(region: $region, periodsLimit: $periodsLimit, direction: $direction) {\n    createDate\n    supplyLtl\n    supplyFtl\n    supplyBus\n    demandLtl\n    demandFtl\n    demandBus\n    priceFtl\n    priceLtl\n    priceBus\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '93435681be842d7b671550c70cfa2453';
module.exports = node;
