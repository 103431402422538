/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SwitchBtn from '../../../components/form/SwitchBtn';
import Cookies from 'js-cookie';

const IncludeWeekendsSwitch = props => {
  const isIncludeWeekends = Cookies.get('includeWeekends') === 'true';
  const [includeWeekends, setincludeWeekends] = useState(isIncludeWeekends);

  const { t, includeWeekendsFunc } = props;

  return (
    <SwitchBtn
      onClick={() => {
        setincludeWeekends(!includeWeekends);
        Cookies.set('includeWeekends', !includeWeekends);
        includeWeekendsFunc('includeWeekends', !includeWeekends);
      }}
      className="my-1 mx-0"
      checked={!includeWeekends}
      testString="includeWeekends"
      label=""
      leftSide={t('button.showW')}
      rightSide={t('button.hide')}
    />
  );
};

IncludeWeekendsSwitch.propTypes = {
  t: PropTypes.func,
  includeWeekendsFunc: PropTypes.func,
};

export default withTranslation('common')(IncludeWeekendsSwitch);
