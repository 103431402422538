import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const NoData = ({ t, customClass = '' }) => {
  return (
    <div className={`nodata ${customClass}`}>
      <svg version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" width="100" height="100">
        <g>
          <path d="M508.7,692.5c24.2,0,43.8,19.6,43.8,43.7S532.9,780,508.7,780c-24.2,0-43.7-19.6-43.7-43.8S484.6,692.5,508.7,692.5z M456.4,628l-42.2-382.6c-0.9-13.9,5.8-25.4,15.1-25.4H563c9.2,0,16,11.5,15.1,25.4L535.8,628c-0.4,6.6-5.8,12-12.1,12h-55.3C462.2,640,456.8,634.7,456.4,628z M500,990C229.4,990,10,770.6,10,500C10,229.4,229.4,10,500,10c91.3,0,219.7,30.9,249.7,69c2.3,11.3,1.2,24.7-4.7,36c-13.7,26-34.1,29-48.9,33.7C638.1,116.2,571.3,97.5,500,97.5C277.7,97.5,97.5,277.7,97.5,500c0,222.3,180.2,402.5,402.5,402.5c222.3,0,402.5-180.2,402.5-402.5c0-112.3-28.6-196.3-102.8-269.3c-9.3-19.9,7.8-43.8,28-50.9c19.3-6.7,31.4-6.2,46.2,2.2c82.6,87.7,116,188,116,318C990,770.6,770.6,990,500,990z" />
        </g>
      </svg>
      <h1>{t('dialogs.noData')}</h1>
    </div>
  );
};

NoData.propTypes = {
  t: PropTypes.func,
  customClass: PropTypes.string,
};

export default withTranslation('common')(NoData);
