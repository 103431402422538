const getCurrencySymbol = (currencyName = 'PLN') => {
  let currencySymbol = '';

  switch (currencyName) {
    case 'PLN':
      currencySymbol = 'zł';
      break;
    case 'EUR':
      currencySymbol = '€';
      break;
    default:
      currencySymbol = 'zł';
  }

  return currencySymbol;
};

export default getCurrencySymbol;
