import Cookies from 'js-cookie';
import axiosInstance from '../utils/axios';

const prepareAuthCookie = (prevData, data) => ({ ...JSON.parse(Cookies.get('auth') || {}), profile: { ...prevData, ...data } });

const profileReducer = (state, action) => {
  let newUserList = {};
  switch (action.type) {
    case 'PROFILE_INIT':
      return state;
    case 'PROFILE_USERLIST_INIT':
      return {
        ...state,
        userList: action.data,
      };
    // case 'PROFILE_INVOICES_INIT':
    //   return {
    //     ...state,
    //     invoiceList: action.data,
    //   };
    case 'PROFILE_USERLIST_ADD':
      newUserList = {
        userList: [
          ...(state || []).userList,
          {
            firstName: action.firstName,
            lastName: action.lastName,
            email: action.email,
          },
        ],
      };
      Cookies.set('auth', prepareAuthCookie(state, newUserList), { expires: 30 });

      // axiosInstance.post('users/access/grant', {
      //   firstName: action.firstName,
      //   lastName: action.lastName,
      //   email: action.email,
      // });

      return {
        ...state,
        ...newUserList,
      };
    case 'PROFILE_USERLIST_REMOVE':
      // ZASLEPKA AXIOS FOR REMOVE USER !!!
      axiosInstance.post('/users/access/revoke', { email: action.email });
      return { ...state, userList: (state || []).userList.filter(item => item.email !== action.email) };
    case 'PROFILE_EDIT':
      axiosInstance
        .post('/users/edit', action.data)
        .then(response => {
          Cookies.set('auth', prepareAuthCookie(state, response.data.profile), { expires: 30 });
          action.callback.onSuccessCallback();
        })
        .catch(err => {
          console.error('Profile edit ', err);
          action.callback.onError();
        });
      return { ...state, ...action.data };
    case 'LOGIN':
      return { ...state, ...action.data };
    case 'LOGOUT':
      window.location.href = '/log_in';
      Cookies.remove('auth');
      Cookies.remove('destination');
      Cookies.remove('departure');
      Cookies.remove('includeWeekends');
      Cookies.remove('fuelCountry');
      return {};
    default:
      return state;
  }
};

export default profileReducer;
