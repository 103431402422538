/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import Cookies from 'js-cookie';
import { withTranslation } from 'react-i18next';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import axios from '../../utils/axios';
import withNotificationHOC from '../../components/notification/NotificationsHOC';
import { BasicNotification } from '../../components/notification/Notifications';
import { ProfileContext } from '../../context/Profile';

const withAuthHOC = WrappedComponent => {
  class AuthHOC extends Component {
    static propTypes = {
      handleShowNotification: PropTypes.func,
      t: PropTypes.func,
    };

    logout = () => {
      const { dispatch } = this.context;
      dispatch({ type: 'LOGOUT' });
    };

    login = (email, password, redirect = '/pages/chart/price') => {
      const { handleShowNotification, t } = this.props;
      const { dispatch } = this.context;

      axios
        .post('/users/signin', {
          email,
          password,
        })
        .then(resp => {
          Cookies.set('auth', { ...resp.data }, { expires: 30 });
          dispatch({ type: 'LOGIN', data: resp.data.profile });

          window.location.href = `${redirect}`;
        })
        .catch(err => {
          handleShowNotification({
            notification: <BasicNotification type="error" title={`${get(err, 'response.status', '')}`} message={t('msg.incorrectEmailPassword')} />,
            position: 'left-up',
          });
        });
    };

    isAuth = () => !!Cookies.get('auth');

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          auth={{
            isAuth: () => this.isAuth(),
          }}
          logout={this.logout}
          login={this.login}
        />
      );
    }
  }

  AuthHOC.contextType = ProfileContext;

  return withTranslation('common')(withNotificationHOC(AuthHOC));
};

export default withAuthHOC;
