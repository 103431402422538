/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

const Button = ({
  type = 'submit',
  className,
  disabled = false,
  option = 'primary',
  text = 'save',
  testid = 'submit',
  t,
  isUpperCase = false,
  onClick,
  children = null,
  form,
}) => {
  const ButtonClass = classNames(className, `btn-${option}`, {
    'btn-inactive': disabled,
    'text-uppercase': isUpperCase,
  });
  return (
    <button type={type} className={ButtonClass} disabled={disabled} data-testid={`btn.${testid}`} onClick={onClick} form={form}>
      {children || (isUpperCase ? t(`button.${text}`).toUpperCase() : t(`button.${text}`))}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  testid: PropTypes.string,
  form: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  isUpperCase: PropTypes.bool,
  option: PropTypes.oneOf(['primary', 'secondary', 'link']),
  t: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.any]),
};

export default withTranslation('common')(Button);
