/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import Button from './Button';

const Input = ({
  label,
  name,
  placeholder,
  type = 'text',
  required,
  helper = '',
  error = false,
  showStatus = false,
  showHelper = false,
  disabled,
  className = '',
  value = '',
  tabIndex = 0,
  isButtonShow = true,
  onInputChange = () => {},
}) => {
  const InputClass = classNames(className, {
    input: true,
    'input-error': !disabled && (error || (showStatus && error)),
    'input-success': !disabled && showStatus && !error,
    'input-show-helper': !disabled && !showStatus && showHelper,
    disabled,
    required,
  });
  const [inputType, setType] = useState(type);
  const [inputValue, setValue] = useState(value);
  const inputTypeChange = () => {
    type === 'password' && inputType === 'password' ? setType('text') : setType(type);
  };

  return (
    <div data-testid={`input.all.${name}`} className={InputClass}>
      <span className="input-label" data-testid={`input.label.${name}`}>
        {label}
      </span>
      <div className="input-field-group">
        <input
          name={name}
          type={inputType}
          placeholder={placeholder}
          required={required}
          className="input-field"
          disabled={disabled}
          value={inputValue}
          data-testid={`input.${name}`}
          onChange={e => {
            setValue(e.target.value);
            onInputChange(e);
          }}
          tabIndex={tabIndex}
        />
        {type === 'password' && inputValue.length > 0 && isButtonShow && (
          <Button type="button" option="link" className="input-field-showPassword" onClick={() => inputTypeChange()} text="show" isUpperCase />
        )}
        {!disabled && showStatus ? (
          error ? (
            <CloseCircleIcon className="input-status__icon" data-testid={`icon.bad.${name}`} size={20} />
          ) : (
            <CheckCircleIcon className="input-status__icon" data-testid={`icon.good.${name}`} size={20} />
          )
        ) : null}
      </div>
      <span className="input-helper" data-testid={`input.helper.${name}`}>
        {helper}
      </span>
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  helper: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  showStatus: PropTypes.bool,
  showHelper: PropTypes.bool,
  isButtonShow: PropTypes.bool,
  onInputChange: PropTypes.func,
};

Input.defaultProps = {
  required: false,
};

export default Input;
