/* eslint-disable no-return-assign */
import React, { Component } from 'react';
// import { Col, Container, Row } from 'reactstrap';
import NotificationSystem from 'rc-notification';
import PropTypes from 'prop-types';
// import BasicNotifications from './BasicNotifications';
// import ImageNotifications from './ImageNotifications';
// import ColorStates from './ColorStates';
// import ColorStatesFullWidth from './ColorStatesFullWidth';

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

const withNotificationsHOC = WrappedComponent => {
  class NotificationsHOC extends Component {
    static propTypes = {
      t: PropTypes.func,
    };

    componentDidMount() {
      NotificationSystem.newInstance({}, n => (notificationLU = n));
      NotificationSystem.newInstance({}, n => (notificationRU = n));
      NotificationSystem.newInstance({}, n => (notificationTC = n));
    }

    // componentWillUnmount() {
    //   notificationLU.destroy();
    //   notificationRU.destroy();
    //   notificationTC.destroy();
    // }

    showNotification = ({ notification, position = 'left-up' }) => {
      switch (position) {
        case 'left-up':
          notificationLU.notice({
            content: notification,
            duration: 5,
            closable: true,
            style: { top: 0, left: 0 },
            className: position,
          });
          break;
        case 'right-up':
          notificationRU.notice({
            content: notification,
            duration: 5,
            closable: true,
            style: { top: 0, left: 'calc(100vw - 100%)' },
            className: position,
          });
          break;
        default:
          notificationTC.notice({
            content: notification,
            duration: 5,
            closable: true,
            style: { top: 0, left: 0 },
            className: position,
          });
          break;
      }
    };

    render() {
      return <WrappedComponent {...this.props} {...this.state} handleShowNotification={obj => this.showNotification(obj)} />;
    }
  }
  return NotificationsHOC;
};

export default withNotificationsHOC;
