/* eslint-disable */
import React from 'react';
import PasswordChangeForm from './components/PasswordChangeForm';
import MainWrapper from './components/MainWrapper';
import { withTranslation } from 'react-i18next';
// import RegisterForm from './components/RegisterForm';

const PasswordChange = ({ t }) => (
  <MainWrapper title={t('label.passwordChange')} option="PasswordChange">
    <PasswordChangeForm />
  </MainWrapper>
);

export default withTranslation('common')(PasswordChange);
// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
