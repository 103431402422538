/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Select from 'react-dropdown-select';
import { fetchQuery, graphql } from 'react-relay';
import environment from 'relay-env';
import debounce from 'lodash.debounce';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import getCountryNameFromIso from '../../utils/getters';

const SearchRegionsQuery = graphql`
  query SearchRegionsQuery($query: String!, $sortLang: String) {
    getRegions(query: $query, sortLang: $sortLang) {
      nuts {
        nuts
        country
        latinName
        namePL
        nameEN
      }
      countries {
        iso2
        short
        nameEN
        namePL
      }
      nutsByPostal {
        postalCode
        nuts
      }
      countriesByPostal {
        postalCode
        country
      }
    }
  }
`;

const dropdownHandleRenderer = ({ state }) => (state.dropdown ? <span className="lnr lnr-chevron-up"></span> : <span className="lnr lnr-chevron-down"></span>);

const SearchRegions = ({ className = '', isSmall = false, placeholder = 'label.search', handleChange = () => {}, values }) => {
  const { t, i18n, lang } = useTranslation('common');
  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  // const [noDataStatus, setDataStatus] = useState(null);
  const selectorClass = classnames('dropdown-select', className, {
    small: isSmall,
  });

  useEffect(() => {
    regionSearch(searchValue);
  }, [searchValue]);

  const setRegion = selected => {
    setList([]);
    setSearchValue([]);
    handleChange(selected);
  };

  const contentHandleRenderer = prop => (
    <div className="content-wrapper">
      <span style={{ display: prop.state.dropdown ? 'none' : 'inline' }}>{get(values, 'name', '')}</span>
      <input className="content-input" type="text" value={prop.state.search} onChange={prop.methods.setSearch} />
    </div>
  );

  const mergePostalCodeCountryAndNuts = (countries = [], nuts = []) =>
    countries
      .reduce(
        (acc, item) =>
          acc.concat([
            {
              ...item,
              ...nuts.find(nutsElement => nutsElement.postalCode === item.postalCode),
            },
          ]),
        []
      )
      .map(({ ...rest }) => ({ ...rest, name: `${rest.postalCode} (${rest.country})`, search: `${rest.postalCode} ${rest.country}`, type: 'postalCode' }));

  const language = i18n.language.split('-')[0].toUpperCase();
  const regionSearch = query => {
    query.length === 0
      ? setList([])
      : fetchQuery(environment, SearchRegionsQuery, {
          query,
          sortLang: language,
        }).then(data => {
          const mergedPostalCode = mergePostalCodeCountryAndNuts(data.getRegions.countriesByPostal, data.getRegions.nutsByPostal) || [];
          const renamedCountries =
            data.getRegions.countries.map(({ ...rest }) => ({
              ...rest,
              name: rest[`name${language}`],
              search: rest[`name${language}`],
              type: 'country',
            })) || [];
          const renamedNuts =
            data.getRegions.nuts.map(({ ...rest }) => ({
              ...rest,
              name: `${rest[language === 'PL' ? 'namePL' : 'nameEN']} [${rest.country}]`,
              search: `${rest.latinName} ${getCountryNameFromIso(rest.country)} ${getCountryNameFromIso(rest.country, 'EN')} ${rest.namePL} ${rest.nameEN}`,
              type: 'nuts',
            })) || [];
          const newList = [...mergedPostalCode, ...renamedCountries, ...renamedNuts];
          setList(newList);
          // JSON.stringify(newList) !== JSON.stringify(list) && setList(newList)
        });
  };
  // console.warn('SEARCH INPUT : ', values);

  return (
    <Select
      className={selectorClass}
      dropdownGap={5}
      loading={searchValue.length > 1 && list.length === 0}
      options={list}
      searchable
      placeholder={t(placeholder)}
      values={[values]}
      addPlaceholder=""
      labelField="name"
      valueField="name"
      searchBy="search"
      noDataLabel={searchValue.length > 1 ? t('dialogs.noData') : t('label.writeSearchSentence')}
      onChange={selected => setRegion(selected)}
      dropdownHandleRenderer={dropdownHandleRenderer}
      contentRenderer={contentHandleRenderer}
      searchFn={debounce(e => setSearchValue(e.state.search), 400)}
    />
  );
};

export default SearchRegions;
