/* eslint-disable */
import React, { useReducer } from 'react';
import Cookies from 'js-cookie';
import get from 'lodash.get';
import { withTranslation } from 'react-i18next';
import profileReducer from '../reducers/Profile';
import withNotificationHOC from '../components/notification/NotificationsHOC';
import { BasicNotification } from '../components/notification/Notifications';

export const ProfileContext = React.createContext();

const ProfileStore = props => {
  const prepareProfile = () => {
    const data = !!Cookies.get('auth') ? get(JSON.parse(Cookies.get('auth')), 'profile', {}) : {};
    const isBlocked = data.role === 'FREE';
    return { isBlocked, ...data };
  };
  // const { t } = useTranslation('common');
  const [profile, dispatch] = useReducer(profileReducer, prepareProfile());

  const onSuccess = () => {
    const { handleShowNotification, t } = props;
    handleShowNotification({
      notification: <BasicNotification title={t('button.companyData')} message={t('msg.dataUpdate')} type="success" />,
      position: 'left-up',
    });
  };

  const onError = () => {
    const { handleShowNotification, t } = props;
    handleShowNotification({
      notification: <BasicNotification title={t('button.companyData')} message={t('msg.failed')} />,
      position: 'left-up',
    });
  };

  const profileUpdate = (data, onSuccessCallback = onSuccess) => {
    dispatch({
      type: 'PROFILE_EDIT',
      data: { ...data },
      callback: { onSuccessCallback, onError },
    });
  };

  return (
    <ProfileContext.Provider
      value={{
        profile,
        dispatch,
        profileUpdate: (data, callBack) => profileUpdate(data, callBack),
      }}
    >
      {props.children}
    </ProfileContext.Provider>
  );
};

export default withNotificationHOC(withTranslation('common')(ProfileStore));
