import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const ChartSidebar = ({ direction = 'right', handleToggle, name = '', isOpen = false, children, subtitle = '' }) => {
  const SidebarClass = classNames('charts-sidebar', direction, {
    open: isOpen,
  });

  return (
    <div className={SidebarClass}>
      <button className="lnr lnr-cross modal__close-btn" type="button" onClick={() => handleToggle()} />
      {name && <h2>{name}</h2>}
      {subtitle !== '' && <span className="text-nowrap">{subtitle}</span>}
      {children}
    </div>
  );
};

ChartSidebar.propTypes = {
  direction: PropTypes.oneOf(['top', 'right', 'left', 'bottom']),
  handleToggle: PropTypes.func,
  isOpen: PropTypes.bool,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default ChartSidebar;
